import { doRequest } from "../../Helper/network";
import { Branches } from "../../Helper/urls";
import { REQUEST_TYPE} from "../../constant";

const FETCHING_BRANCHES = "FETCHING_BRANCHES";
export const FETCHING_BRANCHES_COMPLETED = "FETCHING_BRANCHES_COMPLETED";
export const FETCHING_BRANCHES_COMPLETED_WITH_ERROR = "FETCHING_BRANCHES_COMPLETED_WITH_ERROR";


const FETCHING_BRANCH_PROFILE = "FETCHING_BRANCH_PROFILE";
export const FETCHING_BRANCH_PROFILE_COMPLETED = "FETCHING_BRANCH_PROFILE_COMPLETED";
export const FETCHING_BRANCH_PROFILE_COMPLETED_WITH_ERROR = "FETCHING_BRANCH_PROFILE_COMPLETED_WITH_ERROR";


const EDITING_BRANCH = "EDITING_BRANCH";
export const EDITING_BRANCH_COMPLETED = "EDITING_BRANCH_COMPLETED";
export const EDITING_BRANCH_COMPLETED_WITH_ERROR = "EDITING_BRANCH_COMPLETED_WITH_ERROR";


const ADDING_BRANCH = "ADDING_BRANCH";
export const ADDING_BRANCH_COMPLETED = "ADDING_BRANCH_COMPLETED";
export const ADDING_BRANCH_COMPLETED_WITH_ERROR = "ADDING_BRANCH_COMPLETED_WITH_ERROR";


const ACTIVATING_BRANCH = "ACTIVATING_BRANCH";
export const ACTIVATING_BRANCH_COMPLETED = "ACTIVATING_BRANCH_COMPLETED";
export const ACTIVATING_BRANCH_COMPLETED_WITH_ERROR = "ACTIVATING_BRANCH_COMPLETED_WITH_ERROR";


const initial_state = {};


function setBranches(state, data={}){
    const {distributorBranchData={}, dataOrder=[], total} = data;
    if(distributorBranchData){
	return {branches:distributorBranchData, dataOrder:dataOrder, loading:false, totalRecords:total};
    }
    else  return state;
}



function setBranchProfile(state, data={}){
    const {distributorBranchData} = data;
    if(distributorBranchData){
	return {branchDetails:distributorBranchData};
    }
    else  return state;
}




export const fetchBranches = (data={}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FETCHING_BRANCHES });
      let response = await doRequest({
          method: REQUEST_TYPE.GET,
          url: Branches.getBranchURL(),
	  params:data
	  
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: FETCHING_BRANCHES_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: FETCHING_BRANCHES_COMPLETED,
          payload: { ...payload.data }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};



export const createBranch = (data={}) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADDING_BRANCH });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Branches.getCreateBranchURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADDING_BRANCH_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADDING_BRANCH_COMPLETED,
		    payload: { message: payload.message }
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
    };
};



export const fetchBranchProfile = (branchId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: FETCHING_BRANCH_PROFILE });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Branches.getBranchProfileURL(branchId)
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: FETCHING_BRANCH_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: FETCHING_BRANCH_PROFILE_COMPLETED,
		    payload: { ...payload.data }
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
    };
};



export const updateBranchStatus = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ACTIVATING_BRANCH });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Branches.getUpdateBranchStatusURL(),
		data: data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ACTIVATING_BRANCH_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ACTIVATING_BRANCH_COMPLETED,
		    payload: { message: payload.message }
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
    };
};


export const editBranch = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: EDITING_BRANCH });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Branches.getUpdateBranchURL(),
		data: data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: EDITING_BRANCH_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: EDITING_BRANCH_COMPLETED,
		    payload: { message: payload.message }
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
    };
};



export default (state = initial_state, action = {}) => {
  const { type, payload } = action;

    switch (type) {
    case FETCHING_BRANCHES:
	return {loading:true};
    case FETCHING_BRANCHES_COMPLETED:
	return setBranches(state, payload);
    case FETCHING_BRANCHES_COMPLETED_WITH_ERROR:
	return {loading:false};
    case FETCHING_BRANCH_PROFILE_COMPLETED:
	return setBranchProfile(state, payload);
    default:
      return state;
  }
};
