import { combineReducers } from 'redux';
import auth from './auth';
import pharmacies from './pharmacies';
import users from './users';
import modal from './modals';
import successMsg from "./successMsg";
import errorMsg from "./errorMsg";
import lang from "./lang";
import cities from "./city";
import branch from "./branch";
import reports from "./reports";
import members from "./members";
import roles from "./roles";
import dispensationSheets from "./dispensationSheets";
import notification from './notification';
import DistributorLawsRegulation from "./lawsRegulations";
import OrderData from "./orderData";
import branches from "./branches";
import EgDrugPharmacies from "./egDrugPharmacies";

import InspectorAuth from "./Inspector/auth";
import InspectorPharmacies from "./Inspector/pharmacies";
import InspectorAudit from "./Inspector/audit";
import InspectorMembers from "./Inspector/members";
import InspectorBindingPharmacies from "./Inspector/bindingPharmacies";
import AdminProfileManager from "./Inspector/adminProfileManager";
import Inspectors from "./Inspector/inspectors";
import status from "./Inspector/status";
import distributorBranches from "./Inspector/branches";
import InspectorManagerPharmacies from "./Inspector/managerRequest";
import InspectorDispensationPharmacies from "./Inspector/dispensationForms";
import InspectorAllReports from "./Inspector/allReports";
import InspectorPharmacyTypes from "./Inspector/pharmacyTypes";
import InspectorExpiredNightPharmacy from "./Inspector/expiredNightPharmacy";
import InspectorGovEntities from "./Inspector/govEntities";
import InspectorNotitifcation from "./Inspector/notification";
import InspectorLawsRegulation from "./Inspector/lawsRegulations";
import InspectorTasksCounts from "./Inspector/tasksCounts";

import bindingBasicInfo from "./BindingPharmacy/basicInfo";
import bindingRequestNumber from "./BindingPharmacy/requestNumber";
import bindingPharmacyInfo from "./BindingPharmacy/pharmacyDetails";
import bindingPharmacyStatus from "./BindingPharmacy/trackRequest";

import AdminAuth from "./AdminConsole/auth";
import AdminProducts from "./AdminConsole/products";
import AdminPharmacies from "./AdminConsole/pharmacies";
import AdminUsers from "./AdminConsole/users";
import AdminDistributors from "./AdminConsole/distributors";
import PublicAdmin from "./AdminConsole/publicAdmin";
import LawsRegulation from "./AdminConsole/lawsRegulations";
import CitizensResources from "./AdminConsole/citizensResources";
import CitizensVideos from "./AdminConsole/citizensVideos";
import GeographicalStructure from "./AdminConsole/geographical";
import EditAudit from "./AdminConsole/editAudit";
import EdaDept from "./AdminConsole/edaDept";
import AdminDropDownData from "./AdminConsole/adminDropDownData";
import AdminAuditReport from "./AdminConsole/auditReport";
import AdminNotification from "./AdminConsole/notification";
import AdminEgDrugPharmacies from "./AdminConsole/egDrugPharmacies";

const rootReducer = combineReducers({
  auth,
  pharmacies,
  users,
  modal,
  successMsg,
  errorMsg,
  lang,
  cities,
  branch,
    reports,
    branches,
  members,
  roles,
  dispensationSheets,
  InspectorAuth,
  InspectorPharmacies,
  InspectorAudit,
  InspectorMembers,
  InspectorBindingPharmacies,
  InspectorManagerPharmacies,
  InspectorDispensationPharmacies,
  InspectorAllReports,
  AdminProfileManager,
  Inspectors,
  status,
  distributorBranches,
  bindingBasicInfo,
  bindingRequestNumber,
  bindingPharmacyInfo,
  bindingPharmacyStatus,
  AdminAuth,
  AdminProducts,
  AdminPharmacies,
  AdminUsers,
  AdminDistributors,
  PublicAdmin,
  LawsRegulation,
  CitizensResources,
  CitizensVideos,
  GeographicalStructure,
  EditAudit,
  InspectorPharmacyTypes,
  EdaDept,
  AdminDropDownData,
  InspectorExpiredNightPharmacy,
  InspectorGovEntities,
  AdminAuditReport,
  OrderData,
  notification,
  InspectorNotitifcation,
  AdminNotification,
  InspectorLawsRegulation,
  DistributorLawsRegulation,
  InspectorTasksCounts,
  AdminEgDrugPharmacies,
  EgDrugPharmacies,
});

export default (state, action) => {
  const { type } = action;
  if (type === 'SIGNING_OUT_COMPLETED' || type === 'INSPECTOR_SIGNING_OUT_COMPLETED' || type === "ADMIN_CONSOLE_SIGNING_OUT_COMPLETED") {
    Object.keys(state).forEach((keys) => {
      if (keys !== 'auth' && keys !== "lang") {
        state[keys] = {};
      }
    });
  }
  return rootReducer(state, action);
};
