import { doRequest } from "../../Helper/network";
import { Reports } from "../../Helper/urls";
import { REQUEST_TYPE} from "../../constant";

export const FETCHING_DISPENSATION_REPORT = "FETCHING_DISPENSATION_REPORT";
export const FETCHING_DISPENSATION_REPORT_COMPLETED = "FETCHING_DISPENSATION_REPORT_COMPLETED";
export const FETCHING_DISPENSATION_REPORT_COMPLETED_WITH_ERROR = "FETCHING_DISPENSATION_REPORT_COMPLETED_WITH_ERROR";


export const FETCHING_FORECAST_CONSUMPTION_REPORT="FETCHING_FORECAST_CONSUMPTION_REPORT";
export const FETCHING_FORECAST_CONSUMPTION_REPORT_COMPLETED="FETCHING_FORECAST_CONSUMPTION_REPORT_COMPLETED";
export const FETCHING_FORECAST_CONSUMPTION_REPORT_COMPLETED_WITH_ERROR="FETCHING_FORECAST_CONSUMPTION_REPORT_COMPLETED_WITH_ERROR";


export const FETCHING_QUANTITY_CONTRIBUTION_REPORT = "FETCHING_QUANTITY_CONTRIBUTION_REPORT";
export const FETCHING_QUANTITY_CONTRIBUTION_REPORT_COMPLETED = "FETCHING_QUANTITY_CONTRIBUTION_REPORT_COMPLETED";
export const FETCHING_QUANTITY_CONTRIBUTION_REPORT_COMPLETED_WITH_ERROR =" FETCHING_QUANTITY_CONTRIBUTION_REPORT_COMPLETED_WITH_ERROR";



export const FETCHING_LOGIN_ACTIVITIES_REPORT = "FETCHING_LOGIN_ACTIVITIES_REPORT";
export const FETCHING_LOGIN_ACTIVITIES_REPORT_COMPLETED = "FETCHING_LOGIN_ACTIVITIES_REPORT_COMPLETED";
export const FETCHING_LOGIN_ACTIVITIES_REPORT_COMPLETED_WITH_ERROR = "FETCHING_LOGIN_ACTIVITIES_REPORT_COMPLETED_WITH_ERROR";


export const FETCHING_DISPENSATION_VIOLATION_REPORT = "FETCHING_DISPENSATION_VIOLATION_REPORT";
export const FETCHING_DISPENSATION_VIOLATION_REPORT_COMPLETED = "FETCHING_DISPENSATION_VIOLATION_REPORT_COMPLETED";
export const FETCHING_DISPENSATION_VIOLATION_REPORT_COMPLETED_WITH_ERROR = "FETCHING_DISPENSATION_VIOLATION_REPORT_COMPLETED_WITH_ERROR";


const initial_state = {};


function setDispensationReport(state, data={}) {
    const {report={}, total=0} = data;
    if(report){
	return {dispensationReport:report, totalRecords:total, loading:false};
    }
    else return state;
}


function setForecastConsumptionReport(state, data={}){
    const {generated_report={}, total=0} = data;
    if(generated_report){
	return {forecastConsumptionReport:generated_report, totalRecords:total, loading:false};
    }
    else return state;
}


function setLoginActivitiesReport(state, data={}){
    const {responseData={}, total=0} = data;
    if(responseData){
	return {loginActivitiesReport:responseData, totalRecords:total, loading:false};
    }
    else return state;
}


function setDispensationViolationReport(state, data={}){
    const {responseData={}, total=0} = data;
    if(responseData){
	return {dispensationViolationReport:responseData, totalRecords:total, loading:false};
    }
    else return state;
}


function setQuantityContribReport(state, data={}){
    const {report={}, total=0} = data;
    if(report){
	return {quantityContributionReport:report, totalRecords:total, loading:false};
    }
    else return state;
}


export const fetchDispensationReport = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FETCHING_DISPENSATION_REPORT });
      let response = await doRequest({
          method: REQUEST_TYPE.GET,
          url: Reports.fetchDispensationReportURL(),
	  params:data
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: FETCHING_DISPENSATION_REPORT_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: FETCHING_DISPENSATION_REPORT_COMPLETED,
          payload: { ...payload.data }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};


export const fetchForecastConsumptionReport = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FETCHING_FORECAST_CONSUMPTION_REPORT });
      let response = await doRequest({
          method: REQUEST_TYPE.GET,
          url: Reports.fetchForecastConsumptionReportURL(),
	  params:data
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: FETCHING_FORECAST_CONSUMPTION_REPORT_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: FETCHING_FORECAST_CONSUMPTION_REPORT_COMPLETED,
          payload: { ...payload.data }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};



export const fetchQuantityContributionReport = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FETCHING_QUANTITY_CONTRIBUTION_REPORT });
      let response = await doRequest({
          method: REQUEST_TYPE.GET,
          url: Reports.fetchQuantityContributionReportURL(),
	  params:data
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: FETCHING_QUANTITY_CONTRIBUTION_REPORT_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: FETCHING_QUANTITY_CONTRIBUTION_REPORT_COMPLETED,
          payload: { ...payload.data }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};



export const fetchLoginActivitiesReport = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FETCHING_LOGIN_ACTIVITIES_REPORT });
      let response = await doRequest({
          method: REQUEST_TYPE.GET,
          url: Reports.fetchLoginActivitiesReportURL(),
	  params:data
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: FETCHING_LOGIN_ACTIVITIES_REPORT_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: FETCHING_LOGIN_ACTIVITIES_REPORT_COMPLETED,
          payload: { ...payload.data }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};



export const fetchDispensationViolationReport = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FETCHING_DISPENSATION_VIOLATION_REPORT });
      let response = await doRequest({
          method: REQUEST_TYPE.GET,
          url: Reports.fetchDispensationViolationReportURL(),
	  params:data
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: FETCHING_DISPENSATION_VIOLATION_REPORT_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: FETCHING_DISPENSATION_VIOLATION_REPORT_COMPLETED,
          payload: { ...payload.data }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};


export default (state = initial_state, action = {}) => {
  const { type, payload } = action;

    switch (type) {
    case FETCHING_DISPENSATION_REPORT:
	return {loading:true};
    case FETCHING_DISPENSATION_REPORT_COMPLETED: {
      return setDispensationReport(state, payload);
    }
    case FETCHING_DISPENSATION_REPORT_COMPLETED_WITH_ERROR:
	return {loading:false};
    case FETCHING_FORECAST_CONSUMPTION_REPORT:
	return {loading:true};
    case FETCHING_FORECAST_CONSUMPTION_REPORT_COMPLETED:{
	return setForecastConsumptionReport(state, payload);
    }
    case FETCHING_FORECAST_CONSUMPTION_REPORT_COMPLETED_WITH_ERROR:
	return {loading:false};

    case FETCHING_LOGIN_ACTIVITIES_REPORT:
	return {loading:true};
    case FETCHING_LOGIN_ACTIVITIES_REPORT_COMPLETED:
	return setLoginActivitiesReport(state, payload);
    case FETCHING_LOGIN_ACTIVITIES_REPORT_COMPLETED_WITH_ERROR:
	return {loading:false};

    case FETCHING_QUANTITY_CONTRIBUTION_REPORT:
	return {loading:true};
    case FETCHING_QUANTITY_CONTRIBUTION_REPORT:
	return {loading:false};
    case FETCHING_QUANTITY_CONTRIBUTION_REPORT_COMPLETED:
	return setQuantityContribReport(state, payload);

    case FETCHING_DISPENSATION_VIOLATION_REPORT:
	return {loading: true};
    case FETCHING_DISPENSATION_VIOLATION_REPORT_COMPLETED:
	return setDispensationViolationReport(state, payload);
    case FETCHING_DISPENSATION_VIOLATION_REPORT_COMPLETED_WITH_ERROR:
	return {loading: false};
      
    default:
      return state;
  }
};
