import { doRequest } from "../../../Helper/network";
import { InspectorTasksCounts } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const UPDATE_INSPECTOR_COUNTS = "UPDATE_INSPECTOR_COUNTS";


const initial_state={
	bindingPharmacies:0,
	auditReports:0,
	changesRequests:0,
	permissionForms:0
};

export const fetchInspectorTasksCounts = (data) => {
    return async (dispatch, getState) => {
	try {
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: InspectorTasksCounts.getTasksCounts(),
		params: data
	    });
	   	if (response && response.status === true) {
			dispatch({
				type: UPDATE_INSPECTOR_COUNTS,
				payload: response.payload.data
			});
	    }
	} catch (err) {
	    throw err;
	}
    };
    
};






export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case UPDATE_INSPECTOR_COUNTS:
	return {...state,...payload.tasksCounts};
    default:
	return state;
	
    }
}
