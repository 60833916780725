import { doRequest } from "../../Helper/network";
import { REQUEST_TYPE } from "../../constant";


export const GETTING_EGDRUG_PHARMACIES = "GETTING_EGDRUG_PHARMACIES";
export const GETTING_EGDRUG_PHARMACIES_COMPLETED = "GETTING_EGDRUG_PHARMACIES_COMPLETED";
export const GETTING_EGDRUG_PHARMACIES_COMPLETED_WITH_ERROR = "GETTING_EGDRUG_PHARMACIES_COMPLETED_WITH_ERROR";

export const GETTING_EGDRUG_USERS = "GETTING_EGDRUG_USERS";
export const GETTING_EGDRUG_USERS_COMPLETED = "GETTING_EGDRUG_USERS_COMPLETED";
export const GETTING_EGDRUG_USERS_COMPLETED_WITH_ERROR = "GETTING_EGDRUG_USERS_COMPLETED_WITH_ERROR";

export const ASSIGNING_OWNER_EGDRUG_PHARMACY = "ASSIGNING_OWNER_EGDRUG_PHARMACY";
export const ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED = "ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED";
export const ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED_WITH_ERROR = "ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED_WITH_ERROR";


const initial_state={};


function setPharmaciesData(state, data = {}) {
    const { responseData, dataOrder=[], total=0 } = data;
    if (responseData) {
	return {...state, responseData, dataOrder, total, loading: false } ;
    } else return state;
}


export const getPharmacies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GETTING_EGDRUG_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: `/distributors/egDrugPharmacies`,
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_EGDRUG_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_EGDRUG_PHARMACIES_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};

export const getEgDrugDistributors = (data={}) => {
    //console.log("data ===== ", data);
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GETTING_EGDRUG_USERS });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: `/distributors/distributorsEgDrugUsers`,
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_EGDRUG_USERS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_EGDRUG_USERS_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};

export const assignOwnerToEgDrug = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ASSIGNING_OWNER_EGDRUG_PHARMACY});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: `/distributors/egDrugPharmacies/assign`,
		data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };   
};

export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case GETTING_EGDRUG_PHARMACIES:
	return {...state, responseData: {}, dataOrder: [], total: 0, loading:true};
    case GETTING_EGDRUG_PHARMACIES_COMPLETED:
	return setPharmaciesData(state, payload);
    case GETTING_EGDRUG_PHARMACIES_COMPLETED_WITH_ERROR:
	return {...state, loading:false};
	case GETTING_EGDRUG_USERS_COMPLETED:
	return {...state, ...payload};
    default:
	return state;
	
    }
}
