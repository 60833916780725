export const HOST = "/api";

export const INSPECTION_DEPARTMENT_PATH = "/inspection-department";

export const BINDING_PHARMACY = "/binding-pharmacy";

export const ADMIN_CONSOLE_PATH = "/admin-console";

export const path = {
  LANDING_PAGE: "/",
  MOBILE_LAUNCH: "/mobile-launch",
  DISRIBUTOR_LANDING_PAGE: "/disributor-landing",
  SIGN_IN: "/sign-in",
  FORGOT_PASSWORD: "/forgot-password",
  OTP: "/otp",
  CREATE_PASSWORD: "/create-password",
  OTP_RESET_PASSWORD: "/reset-password/otp",
  PHARMACY_LIST: "/pharmacy-list",
  PHARMACY_MAPPING: "/pharmacy-mapping",
  UPDATE_PHARMACY_MAPPING: "/update-pharmacy-mapping",
  PHARMACY_PROFILE: "/pharmacy-profile/:pharmacyId",
  UPLOAD_DISPENSATION: "/upload-dispensation",
  SHOW_DISPENSATION_SHEETS: "/dispensation-sheets",
  SHOW_REPORTS: "/reports",
  DISPENSATION_REPORT: "/dispensation-report",
  SHOW_MEMBERS: "/members",
  EDIT_MEMBER_DETAILS: "/edit-member-details/:memberId",
  CREATE_MEMBER: "/create-member",
  FOREST_CONSUMPTION_REPORT: "/forest-consumption-report",
  LOGIN_ACTIVITIES_REPORT: "/login-activities-report",
  DISPENSATION_VIOLATION_REPORT: "/dispensation-violation-report",
  QUANTITY_CONTRIBUTION_REPORT: "/quantity-contribution-report",
  MEMBER_DETAILS: "/member-details/:memberId",
  RESET_PASSWORD: `/reset-password`,
  NOTIFICATIONS: `/notifications`,
    SHOW_ORDER_DATA: `/order-data`,
    UPLOAD_DISPENSATION_FIRST_TIME: "/upload-dispensation-first-time",
    LAWS_REGULATION: `/laws-regulation`,
    SHOW_BRANCHES: `/branches`,
    ADD_BRANCH:`/add-branch`,
    SHOW_BRANCH_PROFILE : "/branch-profile/:branchId",
    EDIT_BRANCH: `/edit-branch-details/:branchId`,
    SHOW_EGDRUG_PHARMACIES: `/egdrug-pharmacies`,

  INSPECTOR: {
    LANDING_PAGE: `${INSPECTION_DEPARTMENT_PATH}/`,
    SIGN_IN: `${INSPECTION_DEPARTMENT_PATH}/sign-in`,
    FORGOT_PASSWORD: `${INSPECTION_DEPARTMENT_PATH}/forgot-password`,
    OTP: `${INSPECTION_DEPARTMENT_PATH}/otp`,
    CREATE_PASSWORD: `${INSPECTION_DEPARTMENT_PATH}/create-password`,
    PHARMACY_LIST: `${INSPECTION_DEPARTMENT_PATH}/pharmacy-list`,
    PHARMACY_PROFILE: `${INSPECTION_DEPARTMENT_PATH}/pharmacy-profile/:pharmacyId`,
    SHOW_MEMBERS: `${INSPECTION_DEPARTMENT_PATH}/members`,
    MEMBER_DETAILS: `${INSPECTION_DEPARTMENT_PATH}/member-details/:memberId`,
    CREATE_MEMBER: `${INSPECTION_DEPARTMENT_PATH}/create-member`,
    EDIT_MEMBER_DETAILS: `${INSPECTION_DEPARTMENT_PATH}/edit-member-details/:memberId`,
    SHOW_AUDIT_REPORT: `${INSPECTION_DEPARTMENT_PATH}/audit-report`,
    SHOW_AUDIT_PHARMACY_DETAIL: `${INSPECTION_DEPARTMENT_PATH}/audit-pharmacy-profile/:auditId/:auditStatus`,
    SHOW_BINDING_PHARMACIES: `${INSPECTION_DEPARTMENT_PATH}/binding-pharmacies`,
    SHOW_BINDING_PHARMACY_PROFILE: `${INSPECTION_DEPARTMENT_PATH}/binding-pharmacy-profile/:bindingFormId/:bindingFormType`,
    SHOW_MANAGER_REQUESTS: `${INSPECTION_DEPARTMENT_PATH}/manager-requests`,
    SHOW_MANAGER_REQUESTS_TYPE: `${INSPECTION_DEPARTMENT_PATH}/manager-requests/:type`,
    SHOW_MANAGER_PROFILE: `${INSPECTION_DEPARTMENT_PATH}/manager-profile/:managerId`,
    SHOW_REPORTS: `${INSPECTION_DEPARTMENT_PATH}/reports`,
    SHOW_INSPECTOR_PHARMACIES_REPORT: `${INSPECTION_DEPARTMENT_PATH}/inspector-pharmacies`,
    SHOW_PHARMACIES_TRANSACTION_REPORT: `${INSPECTION_DEPARTMENT_PATH}/pharmacies-transaction`,
    SHOW_DISTRIBUTOR_BRANCHES_REPORT: `${INSPECTION_DEPARTMENT_PATH}/distributor-branches`,
    SHOW_AUDIT_VISIT_REPORT: `${INSPECTION_DEPARTMENT_PATH}/audit-visit`,
    SHOW_EXPIRE_PRODUCTS_REPORT: `${INSPECTION_DEPARTMENT_PATH}/expire-products`,
    SHOW_DISTRIBUTOR_PHARMACIES_REPORT: `${INSPECTION_DEPARTMENT_PATH}/distributor-pharmacies`,
    SHOW_DISPENSATION_FORMS: `${INSPECTION_DEPARTMENT_PATH}/dispensation-forms`,
    DISPENSATION_PHARMACY_PROFILE: `${INSPECTION_DEPARTMENT_PATH}/dispensation-pharmacy-profile/:pharmacyId/:formId/:circulationType/:dispensationType`,
    SHOW_PHARMACY_TYPE: `${INSPECTION_DEPARTMENT_PATH}/pharmacy-type`,
    SHOW_PHARMACY_TYPE_PROFILE: `${INSPECTION_DEPARTMENT_PATH}/pharmacy-type-profile/:pharmacyId/:pharmacyChangeType/:formId`,
    SHOW_ALL_CHANGE_REQUEST: `${INSPECTION_DEPARTMENT_PATH}/all-change-request`,
    SHOW_EXPIRED_NIGHT_PHARMACY: `${INSPECTION_DEPARTMENT_PATH}/expired-night-pharmacies`,
    SHOW_EXPIRED_NIGHT_PHARMACY_PROFILE: `${INSPECTION_DEPARTMENT_PATH}/expired-night-pharmacy-profile/:pharmacyId/`,
    SHOW_GOV_ENTITIES: `${INSPECTION_DEPARTMENT_PATH}/governorate-entities`,
    RESET_PASSWORD: `${INSPECTION_DEPARTMENT_PATH}/reset-password`,
      NOTIFICATIONS: `${INSPECTION_DEPARTMENT_PATH}/notifications`,
      LAWS_REGULATION: `${INSPECTION_DEPARTMENT_PATH}/laws-regulation`,
      ASSIGN_OWNER: `${INSPECTION_DEPARTMENT_PATH}/assign-owner`,
  },

  ADMIN_CONSOLE: {
    LANDING_PAGE: `${ADMIN_CONSOLE_PATH}/`,
    SIGN_IN: `${ADMIN_CONSOLE_PATH}/sign-in`,
    FORGOT_PASSWORD: `${ADMIN_CONSOLE_PATH}/forgot-password`,
    OTP: `${ADMIN_CONSOLE_PATH}/otp`,
    CREATE_PASSWORD: `${ADMIN_CONSOLE_PATH}/create-password`,
    PHARMACY_LIST: `${ADMIN_CONSOLE_PATH}/pharmacy-list`,
    USERS: `${ADMIN_CONSOLE_PATH}/users`,
    ADD_USER: `${ADMIN_CONSOLE_PATH}/create-user/:userType`,
    PRODUCTS: `${ADMIN_CONSOLE_PATH}/products`,
    PRODUCT_PROFILE: `${ADMIN_CONSOLE_PATH}/product-profile/:productId`,
    PHARMACY_PROFILE: `${ADMIN_CONSOLE_PATH}/pharmacy-profile/:pharmacyId`,
    USER_DETAIL: `${ADMIN_CONSOLE_PATH}/users/:userType/:userId`,
    CREATE_PRODUCT: `${ADMIN_CONSOLE_PATH}/create-product`,
    DISTRIBUTORS: `${ADMIN_CONSOLE_PATH}/distributors`,
    CREATE_DISTRIBUTOR: `${ADMIN_CONSOLE_PATH}/create-distributor`,
    CREATE_BRANCH: `${ADMIN_CONSOLE_PATH}/add-branch/:distributorId`,
    DISTRIBUTOR_PROFILE: `${ADMIN_CONSOLE_PATH}/distributor-profile/:distributorId`,
    DISTRIBUTOR_BRANCHES: `${ADMIN_CONSOLE_PATH}/branches/:distributorId`,
    EDIT_USER: `${ADMIN_CONSOLE_PATH}/edit/:userType/:userId`,
    EDIT_PRODUCT: `${ADMIN_CONSOLE_PATH}/product/:productId`,
    EDIT_BRANCH: `${ADMIN_CONSOLE_PATH}/edit-branch/:distributorId/:branchId`,
    EDIT_DISTRIBUTOR: `${ADMIN_CONSOLE_PATH}/edit-distributor/:distributorId`,
    POWER_BI_REPORT: `${ADMIN_CONSOLE_PATH}/report`,
    PUBLIC_ADMIN: `${ADMIN_CONSOLE_PATH}/public-admin`,
    CREATE_PUBLIC_ADMIN: `${ADMIN_CONSOLE_PATH}/create-admin`,
    PUBLIC_ADMIN_PROFILE: `${ADMIN_CONSOLE_PATH}/public-admin/:adminId`,
    EDIT_PUBLIC_ADMIN: `${ADMIN_CONSOLE_PATH}/edit-public-admin/:adminId`,
    LAWS_REGULATION: `${ADMIN_CONSOLE_PATH}/laws-regulation`,
    CITIZEN_RESOURCES: `${ADMIN_CONSOLE_PATH}/citizen-resources`,
    UPLOAD_LAWS: `${ADMIN_CONSOLE_PATH}/upload-laws`,
    UPLOAD_CITIZEN_RESOURCES: `${ADMIN_CONSOLE_PATH}/upload-resources`,
    GEOGRAPHICAL_STRUCTURE: `${ADMIN_CONSOLE_PATH}/geographical`,
    ADD_GEOGRAPHY: `${ADMIN_CONSOLE_PATH}/add-geography`,
    GEOGRAPHICAL_PROFILE: `${ADMIN_CONSOLE_PATH}/geographical-profile/:geographicalId`,
    EDIT_GEOGRAPHY: `${ADMIN_CONSOLE_PATH}/edit-geography/:geographicalId`,
    EDIT_AUDIT_REPORT: `${ADMIN_CONSOLE_PATH}/edit-audit-report`,
    EDA_DEPARTMENT: `${ADMIN_CONSOLE_PATH}/eda-dept`,
    ADD_EDA_DEPARTMENT: `${ADMIN_CONSOLE_PATH}/add-eda-dept`,
    EDA_DEPARTMENT_PROFILE: `${ADMIN_CONSOLE_PATH}/eda-dept-profile/:profileId`,
    EDIT_EDA_DEPARTMENT: `${ADMIN_CONSOLE_PATH}/edit-dept-profile/:profileId`,
    PRODUCT_BATCH_DETAILS: `${ADMIN_CONSOLE_PATH}/batch-details/:productId/:batchId`,
    RESET_PASSWORD: `${ADMIN_CONSOLE_PATH}/reset-password`,
    NOTIFICATIONS: `${ADMIN_CONSOLE_PATH}/notifications`,
    CREATE_HOME_OFFICE_MESSAGES: `${ADMIN_CONSOLE_PATH}/create-home-office-message`,
    CREATE_CITIZENS_VIDEOS: `${ADMIN_CONSOLE_PATH}/create-citizens-videos`,
    SHOW_AUDIT_REPORT: `${ADMIN_CONSOLE_PATH}/aduit-report`,
    SHOW_AUDIT_PHARMACY_DETAIL: `${ADMIN_CONSOLE_PATH}/audit-pharmacy-profile/:auditId/:auditStatus`,
    SHOW_NOTIFICATION: `${ADMIN_CONSOLE_PATH}/notifications`,
    SHOW_GOV_ENTITIES: `${ADMIN_CONSOLE_PATH}/governorate-entities`,
    SHOW_EGDRUG_PHARMACIES: `${ADMIN_CONSOLE_PATH}/egdrug-pharmacies`,
    EDIT_PHARMACY_USER: `${ADMIN_CONSOLE_PATH}/edit-pharmacy/user`,
    EDIT_PHARMACY_DETAILS: `${ADMIN_CONSOLE_PATH}/edit-pharmacy/details`,
    ASSIGN_OWNER: `${ADMIN_CONSOLE_PATH}/assign-owner`,
  },

  BINDING_PHARMACY: {
    LANDING_PAGE: `${BINDING_PHARMACY}/`,
    GET_STARTED: `${BINDING_PHARMACY}/get-started`,
    PHARMACY_DETAILS: `${BINDING_PHARMACY}/pharmacy-details/:pharmacyType`,
    SUBMITTED: `${BINDING_PHARMACY}/submitted`,
    ADD_PHARMACY: `${BINDING_PHARMACY}/add-pharmacy`,
    EXISTING_PHARMACY: `${BINDING_PHARMACY}/existing-pharmacy`,
    TRACK_REQUEST: `${BINDING_PHARMACY}/track-request/:requestNumber`,
    REVIEWER_PHARMACY_DETAILS: `${BINDING_PHARMACY}/pharmacy-details/:pharmacyType/:requestNumber/:userRole`
  },
};

//request type
export const REQUEST_TYPE = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};

export const REPORT_NAME = {
  DISPENSATION: "dispensation",
  FOREST_CONSUMPTION: "forestConsumption",
  LOGIN_ACTIVITIES: "loginActivities",
  DISPENSATION_VIOLATION: "dispensationViolationReport",
  QUANTITY_CONTRIBUTION: "quantityContributionReport",

  INSPECTOR: {
    INSPECTOR_PHARMACIES: "inspector-pharmacies",
    PHARMACIES_TRANSACTION: "pharmacies-transaction",
    DISTRIBUTOR_BRANCHES: "distributor-branches",
    AUDIT_VISIT: "audit-visit",
    EXPIRE_PRODUCTS: "expire-products",
    DISTRIBUTOR_PHARMACIES: "distributor-pharmacies",
  },
};

export const GLOBAL_MODALS = {
  FAIL_UPLOAD_DISPENSATION: "FAIL_UPLOAD_DISPENSATION",
  SUCCESSFUL_UPLOAD_DISPENSATION: "SUCCESSFUL_UPLOAD_DISPENSATION",
  REASSIGN_PHARMACY_CONFIRMATION: "REASSIGN_PHARMACY_CONFIRMATION",
  AUDIT_APPROVE_CONFIRMATION: "AUDIT_APPROVE_CONFIRMATION",
  AUDIT_REJECT_CONFIRMATION: "AUDIT_REJECT_CONFIRMATION",
  REQUEST_NUMBER: "REQUEST_NUMBER",
  BINDING_PHARMACY_REJECTION: "BINDING_PHARMACY_REJECTION",
  NATIONAL_ID_WARNING: "NATIONAL_ID_WARNING",
  DISPENSATION_PHARMACY_REJECTION: "DISPENSATION_PHARMACY_REJECTION",
  SUSPEND_BATCH_CONFIRMATION: "SUSPEND_BATCH_CONFIRMATION",
  STATUS_UPDATE_MODAL: "STATUS_UPDATE_MODAL",
  STATUS_UPDATE_CONFIRMATION_MODAL: "STATUS_UPDATE_CONFIRMATION_MODAL",
  UPDATE_PHARMACY_STATUS: "UPDATE_PHARMACY_STATUS",
  ADD_HEALTH_DISTRICT_ADMIN: "ADD_HEALTH_DISTRICT_ADMIN",
  DELETE_CONFIRMATION_MODAL: "DELETE_CONFIRMATION_MODAL",
  EDIT_QUOTA: "EDIT_QUOTA",
  CHANGE_PHARMACY_MODAL: "CHANGE_PHARMACY_MODAL",
  NOTIFY_OWNER_MODAL: "NOTIFY_OWNER_MODAL",
  OWNER_NOTIFYING_ACKNOWLEDGEMENT: "OWNER_NOTIFYING_ACKNOWLEDGEMENT",
  PHARMACY_TYPE_CHANGE_REJECTION: "PHARMACY_TYPE_CHANGE_REJECTION",
  UPDATE_REVIEWER_REASON: "UPDATE_REVIEWER_REASON",
  DIRECTOR_APPROVAL_MODAL: "DIRECTOR_APPROVAL_MODAL",
  DIRECTOR_RENEWAL_MODAL: "DIRECTOR_RENEWAL_MODAL",
  SHOW_DOCS_MODAL: "SHOW_DOCS_MODAL",
  NOTIFY_OWNER_FROM_REVIEWER_MODAL: "NOTIFY_OWNER_FROM_REVIEWER_MODAL",
  REASSIGN_PHARMACY_UPLOAD_CONFIRM: "REASSIGN_PHARMACY_UPLOAD_CONFIRM",
  EDA_AUDIT_APPROVE_CONFIRMATION: "EDA_AUDIT_APPROVE_CONFIRMATION",
  EDA_AUDIT_REJECT_CONFIRMATION: "EDA_AUDIT_REJECT_CONFIRMATION",
    REASSIGN_GOV_MODAL: "REASSIGN_GOV_MODAL",
    FIRST_TIME_DISPENSATION: "FIRST_TIME_DISPENSATION",
    DEACTIVATE_OR_ACTIVATE_PUBLIC_ADMIN: "DEACTIVATE_OR_ACTIVATE_PUBLIC_ADMIN",
    PRODUCT_MAPPINGS_FAILURE: "PRODUCT_MAPPINGS_FAILURE",
    EDIT_DISTRIBUTOR_PRODUCT_CODE: "EDIT_DISTRIBUTOR_PRODUCT_CODE",
    ACTIVATION_USER_UPDATE_MODAL: "ACTIVATION_USER_UPDATE_MODAL",

};

export const LANGUAGE = {
  ARAB: "ar",
  ENGLISH: "en",
};

export const USER_ROLES = {
  PUBLIC_ADMINISTRATION_INSPECTOR: "Branch inspector",
  HEALTH_ADMINISTRATION_INSPECTOR: "Sub-Branch inspector",
  DISTRICT_INSPECTOR: "District inspector",
  GENERAL_MANAGER_PHARMACEUTAL_AFFAIRS: "GM of EDA Branch",
  INSPECTION_DIRECTOR: "Inspection Director",
  DISTRIBUTOR_HO_ADMIN: "distributor_ho_admin",
  BRANCH_MANAGER: "branch_manager",
  BRANCH_DEPUTY_MANAGER: "branch_deputy_manager",
  INSPECTION_BRANCH_GENERAL_MANAGER: "GENERAL MANAGER",
  INSPECTION_BRANCH_MANAGER: "MANAGER",
  INSPECTION_BRANCH_DIRECTOR: "INSPECTION DIRECTOR",
  INSPECTION_BRANCH_HEAD_OF_SECOTR: "HEAD OF SECTOR",
  INSPECTOR_USER: "INSPECTOR",
  REVIEWER: "Reviewer",
  SUPER_ADMIN: "Super Admin"
};


export const USER_PROFILES = {
  DISTRIBUTOR: {
    ADMIN: "ADMIN",
    MANAGER: "MANAGER"
  },
  PHARMACY: {
    OWNER: "OWNER",
    MANAGER: "MANAGER",
    PHARMACIST: "PHARMACIST"
  },
  INSPECTION_AUTHORITY: {
    GENERAL_MANAGER: "GENERAL MANAGER",
    MANAGER: "MANAGER",
    INSPECTOR: "INSPECTOR"
  },
  EDA: {
    ADMIN: "ADMIN",
    MANAGER: "MANAGER"
  }
};


export const NEW_MANAGER_REQUEST_STATUS = {
  PENDING: "pending",
  REVIEWER_APPROVED: "reviewer_approved",
  APPROVED: "approved",
  REJECTED: "rejected",
  REVIEWER_REJECTED: "reviewer_rejected",
  ONBOARDED: "onboarded",
  ONBOARDING_REJECTED: "onboarding_rejected",
};


export const PHARMACY_TYPE_REQUEST_STATUS = {
  SUBMITTED: "submitted",
  PENDING: "pending",
  REVIEWER_APPROVED: "reviewer_approved",
  APPROVED: "approved",
  REJECTED: "rejected",
  REVIEWER_REJECTED: "reviewer_rejected",
};


export const DISPENSATION_PERMISSION_FORM_STATUS = {
  PENDING: "pending",
  ALL: "all",
  SUBMITTED: "submitted",
  REVIEWER_APPROVED: "reviewer_approved",
  APPROVED: "approved",
  REJECTED: "rejected",
  REVIEWER_REJECTED: "reviewer_rejected",
  UPDATED: "updated",
  UPDATING_REJECTED: "updating_rejected",
};


export const AUDIT_REQUEST_TYPE = {
  AUDIT: "audit",
  STATUS_CHANGE: "status_change"
};


export const PHARMACY_TYPE_CHANGE_REQUEST_TYPE = {
  "NIGHT_TO_GENERAL": "Night Pharmacy To General Pharmacy",
  "GENERAL_TO_NIGHT": "General Pharmacy To Night Pharmacy",
  "RENEW": "Renew"
};



export const PERSONA = {
  DISTRIBUTOR: "DISTRIBUTOR",
  PHARMACY: "PHARMACY",
  INSPECTION_AUHTORITY: "INSPECTION AUTHORITY",
  EDA: "EDA",
};

export const DEPARTMENT = {
  DISTRIBUTOR: "DISTRIBUTOR",
  PHARMACY: "PHARMACY",
  INSPECTION_AUHTORITY: "INSPECTION AUTHORITY",
  EDA: "EDA",
  DEPARTMET_OF_GENERAL_PHARMACIES_AND_WAREHOUSE:
    "Department of General Pharmacies and Warehouse",
  DEPARTMET_OF_INSPECTION_FOLLOW_UP: "Department of Inspection follow up",
  DEPARTMET_OF_GOVERNMENTAL_INSPECTION: "Department of Govenmental Inspection",
};

export const PHARMACY_USER_ROLE = {
  OWNER: "OWNER",
  MANAGER: "MANAGER",
  PHARMACIST: "PHARMACIST",
};

export const PHARMACY_USER_PROFILE = {
  OWNER: "OWNER",
  MANAGER: "MANAGER",
  PHARMACIST: "PHARMACIST",
};

// export const DISTRIBUTOR_USER_ROLE = {
//   OWNER: "OWNER",
//   VP: "VP",
//   GM: "GM",
//   BRANCH_MANAGER: "BRANCH MANAGER",
//   BRANCH_DEPUTY_MANAGER: "BRANCH DEPUTY MANAGER",
// };

export const DISTRIBUTOR_USER_PROFILE = {
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
};

export const ROLE_PERMISSIONS = {
  VIEW_REPORTS: "VIEW_REPORTS",

  VIEW_AUDIT_REPORTS: "VIEW_AUDIT_REPORTS",
  CREATE_AUDIT_REPORTS: "CREATE_AUDIT_REPORTS",

  VIEW_MEMO: "VIEW_MEMO",
  EDIT_MEMO: "EDIT_MEMO",
  CREATE_MEMO: "CREATE_MEMO",

  VIEW_MAP: "VIEW_MAP",

  VIEW_EDA_USERS: "VIEW_EDA_USERS",
  CREATE_EDA_USERS: "CREATE_EDA_USERS",
  EDIT_EDA_USERS: "EDIT_EDA_USERS",
  DEACTIVATE_EDA_USERS: "DEACTIVATE_EDA_USERS",

  VIEW_EDA_DEPARTMENTS: "VIEW_EDA_DEPARTMENTS",
  CREATE_EDA_DEPARTMENTS: "CREATE_EDA_DEPARTMENTS",
  EDIT_EDA_DEPARTMENTS: "EDIT_EDA_DEPARTMENTS",
  DEACTIVATE_EDA_DEPARTMENTS: "DEACTIVATE_EDA_DEPARTMENTS",

  VIEW_GEOGRAPHICAL_STRUCTURE: "VIEW_GEOGRAPHICAL_STRUCTURE",
  CREATE_GEOGRAPHICAL_STRUCTURE: "CREATE_GEOGRAPHICAL_STRUCTURE",
  EDIT_GEOGRAPHICAL_STRUCTURE: "EDIT_GEOGRAPHICAL_STRUCTURE",
  DEACTIVATE_GEOGRAPHICAL_STRUCTURE: "DEACTIVATE_GEOGRAPHICAL_STRUCTURE",

  VIEW_HEALTH_DISTRICT_OR_ADMINISTRATION: "VIEW_HEALTH_DISTRICT_OR_ADMINISTRATION",
  CREATE_HEALTH_DISTRICT_OR_ADMINISTRATION: "CREATE_HEALTH_DISTRICT_OR_ADMINISTRATION",
  EDIT_HEALTH_DISTRICT_OR_ADMINISTRATION: "EDIT_HEALTH_DISTRICT_OR_ADMINISTRATION",
  DEACTIVATE_HEALTH_DISTRICT_OR_ADMINISTRATION: "DEACTIVATE_HEALTH_DISTRICT_OR_ADMINISTRATION",

  VIEW_INSPECTION_AUTHURITY_USER: "VIEW_INSPECTION_AUTHURITY_USER",
  CREATE_INSPECTION_AUTHURITY_USER: "CREATE_INSPECTION_AUTHURITY_USER",
  EDIT_INSPECTION_AUTHURITY_USER: "EDIT_INSPECTION_AUTHURITY_USER",
  DEACTIVATE_INSPECTION_AUTHURITY_USER: "DEACTIVATE_INSPECTION_AUTHURITY_USER",

  VIEW_PHARMACY_LIST: "VIEW_PHARMACY_LIST",

  VIEW_PHARMACY_PROFILE: "VIEW_PHARMACY_PROFILE",

  VIEW_AUDIT_OFFENSES: "VIEW_AUDIT_OFFENSES",
  CREATE_AUDIT_OFFENSES: "CREATE_AUDIT_OFFENSES",
  EDIT_AUDIT_OFFENSES: "EDIT_AUDIT_OFFENSES",
  DELETE_AUDIT_OFFENSES: "DELETE_AUDIT_OFFENSES",

  VIEW_DISTRIBUTORS: "VIEW_DISTRIBUTORS",
  CREATE_DISTRIBUTORS: "CREATE_DISTRIBUTORS",
  EDIT_DISTRIBUTORS: "EDIT_DISTRIBUTORS",
  DEACTIVATE_DISTRIBUTORS: "DEACTIVATE_DISTRIBUTORS",

  VIEW_DISTRIBUTORS_BRANCHES: "VIEW_DISTRIBUTORS_BRANCHES",
  CREATE_DISTRIBUTORS_BRANCHES: "CREATE_DISTRIBUTORS_BRANCHES",
  EDIT_DISTRIBUTORS_BRANCHES: "EDIT_DISTRIBUTORS_BRANCHES",
  DEACTIVATE_DISTRIBUTORS_BRANCHES: "DEACTIVATE_DISTRIBUTORS_BRANCHES",

  VIEW_KPI: "VIEW_KPI",
  CREATE_KPI: "CREATE_KPI",
  EDIT_KPI: "EDIT_KPI",

  VIEW_HOME_OFFICE_MESSAGES: "VIEW_HOME_OFFICE_MESSAGES",
  CREATE_HOME_OFFICE_MESSAGES: "CREATE_HOME_OFFICE_MESSAGES",
  EDIT_HOME_OFFICE_MESSAGES: "EDIT_HOME_OFFICE_MESSAGES",
  DEACTIVATE_HOME_OFFICE_MESSAGES: "DEACTIVATE_HOME_OFFICE_MESSAGES",

  CHANGE_PHARMACY_STATUS: "CHANGE_PHARMACY_STATUS",

  VIEW_LAWS_AND_REGULATIONS: "VIEW_LAWS_AND_REGULATIONS",
  CREATE_LAWS_AND_REGULATIONS: "CREATE_LAWS_AND_REGULATIONS",
  EDIT_LAWS_AND_REGULATIONS: "EDIT_LAWS_AND_REGULATIONS",
  DEACTIVATE_LAWS_AND_REGULATIONS: "DEACTIVATE_LAWS_AND_REGULATIONS",

  ADDING_NEW_PRODUCT_TYPES: "ADDING_NEW_PRODUCT_TYPES",

  ADDING_OR_REMOVING_PRESCPTION_REQUIREMENT: "ADDING_OR_REMOVING_PRESCPTION_REQUIREMENT",

  RECALLING_PRODUCT: "RECALLING_PRODUCT",

  VIEW_PRODUCTS: "VIEW_PRODUCTS",
  CREATE_PRODUCTS: "CREATE_PRODUCTS",
  EDIT_PRODUCTS: "EDIT_PRODUCTS",
  DEACTIVATE_PRODUCTS: "DEACTIVATE_PRODUCTS",

  DISPENSATION_QUOTA_CHANGES: "DISPENSATION_QUOTA_CHANGES",

  ASSIGN_OR_RESIGN_PHARMACY: "ASSIGN_OR_RESIGN_PHARMACY",

  ASSIGN_OR_RESIGN_BINDING_FORM: "ASSIGN_OR_RESIGN_BINDING_FORM",


  VIEW_BINDING_FORM: "VIEW_BINDING_FORM",
  EDIT_BINDING_FORM: "EDIT_BINDING_FORM",

  VIEW_DISPENSATION_PERMISSION_REQUEST: "VIEW_DISPENSATION_PERMISSION_REQUEST",
  EDIT_DISPENSATION_PERMISSION_REQUEST: "EDIT_DISPENSATION_PERMISSION_REQUEST",

  VIEW_MANAGER_CHANGES_REQUEST: "VIEW_MANAGER_CHANGES_REQUEST",

  VIEW_AUDIT_REPORT: "VIEW_AUDIT_REPORT",
  CREATE_AUDIT_REPORT: "CREATE_AUDIT_REPORT",
  EDIT_AUDIT_REPORT: "EDIT_AUDIT_REPORT",

  VIEW_AUDIT_REPORT_MEMO: "VIEW_AUDIT_REPORT_MEMO",
  CREATE_AUDIT_REPORT_MEMO: "CREATE_AUDIT_REPORT_MEMO",
  EDIT_AUDIT_REPORT_MEMO: "EDIT_AUDIT_REPORT_MEMO",

  VIEW_OWNERSHIP_CHANGE_REQUEST: "VIEW_OWNERSHIP_CHANGE_REQUEST",
  EDIT_OWNERSHIP_CHANGE_REQUEST: "EDIT_OWNERSHIP_CHANGE_REQUEST",

  VIEW_PHARMACY_NAME_REQUEST: "VIEW_PHARMACY_NAME_REQUEST",
  EDIT_PHARMACY_NAME_REQUEST: "EDIT_PHARMACY_NAME_REQUEST",

  VIEW_PHARMACY_TYPE_REQUEST: "VIEW_PHARMACY_TYPE_REQUEST",
  EDIT_PHARMACY_TYPE_REQUEST: "EDIT_PHARMACY_TYPE_REQUEST",

  VIEW_ASSIGN_OWNER_TO_GOVERNMENTAL_PHARMACY: "VIEW_ASSIGN_OWNER_TO_GOVERNMENTAL_PHARMACY",
  EDIT_ASSIGN_OWNER_TO_GOVERNMENTAL_PHARMACY: "EDIT_ASSIGN_OWNER_TO_GOVERNMENTAL_PHARMACY",

};

export const NOTIFICATION_VERB = {
  GENERAL_NOTIFIACTION: "general_notification",
  MANAGER_RESIGN: "MANAGER_RESIGN",
  NEW_MANAGER_REQUEST: "NEW_MANAGER_REQUEST",
  PHARMACY_SUPENSION: "PHARMACY_SUPENSION",
  NOTIFY_OWNER_PHARMACY_TYPE: "NOTIFY_OWNER_PHARMACY_TYPE",
  PHARMACY_TYPE_CHANGE_REQ: "PHARMACY_TYPE_CHANGE_REQ",
  DISPENSATION_PERMISSION_FORM_REQ: "DISPENSATION_PERMISSION_FORM_REQ",
  PHARMACY_BRANCH_CHANGE: "PHARMACY_BRANCH_CHANGE",
  BINDING_PHARMACY_REGISTERED: "BINDING_PHARMACY_REGISTERED",
  LAWS_AND_REGULATION: "LAWS_AND_REGULATION",
  HOME_OFFICE_MESSAGE: "HOME_OFFICE_MESSAGE",
  AUDIT_REPORT_CREATE: "AUDIT_REPORT_CREATE",
  PHARMACY_CREATED: "pharmacy_created",
};



export const TRADING_REGULATIONS = {
  SCHEDULE1_CIRCULATION: "Shedule 1 - Circulation",
  SCHEDULE2_CIRCULATION: "Shedule 2 - Circulation",
  SCHEDULE1_NARCOTICS: "Schedule 1 - Narcotics",
};


export const OFFENSE_PRODUCT_TEMPLATE = [{"title":"Add More Products","title_arabic":"إضافة المزيد من المنتجات","type":"add_products","template":[{"title":"Trade Name","title_arabic":"الاسم التجاري","fieldId":"trade_name","type":"trade_name"},{"title":"Batch Number","title_arabic":"رقم التشغيله","fieldId":"batch_number","type":"batch_number"},{"title":"Expiry Date","title_arabic":"تاريخ الانتهاء","fieldId":"expiry","type":"date"}]}]

export const PHARMACY_TYPE_CHANGE_REQUEST_STATUS = {
  SUBMITTED: "submitted",
  REVIEWER_APPROVED: "reviewer_approved",
  REVIEWER_REJECTED: "reviewer_rejected",
  APPROVED: "approved",
  REJECTED: "rejected",
  ONBOARDED: "onboarding_approved",
  ONBOARDING_REJECTED: "onboarding_rejected",
};
