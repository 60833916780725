import {
    SIGNING_COMPLETED_WITH_ERROR,
    FORGET_PASSWORD_COMPLETED_WITH_ERROR,
    CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR,
    VERIFYING_OTP_COMPLETED_WITH_ERROR,
    VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR,
    RESENDING_RESET_OTP_COMPLETED_WITH_ERROR,
    RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR,
    RESENDING_OTP_COMPLETED_WITH_ERROR
} from "../auth";

import {
    INSPECTOR_SIGNING_COMPLETED_WITH_ERROR,
    INSPECTOR_FORGET_PASSWORD_COMPLETED_WITH_ERROR,
    INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR,
    INSPECTOR_VERIFYING_OTP_COMPLETED_WITH_ERROR,
    INSPECTOR_VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR,
    INSPECTOR_RESENDING_RESET_OTP_COMPLETED_WITH_ERROR,
    INSPECTOR_RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR,
    INSPECTOR_RESENDING_OTP_COMPLETED_WITH_ERROR
} from "../Inspector/auth";


import {
    ADMIN_CONSOLE_SIGNING_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_RESENDING_RESET_OTP_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_RESENDING_OTP_COMPLETED_WITH_ERROR
} from "../AdminConsole/auth";



import {
    ADMIN_CONSOLE_CREATING_HOME_OFFICE_MESSAGE_COMPLETED_WITH_ERROR
} from "../AdminConsole/homeOfficeMessage";


import {
    REASSIGNING_PHARMACY_COMPLETED_WITH_ERROR
} from "../pharmacies";


import {
    UPLOADING_ATTACHMENTS_COMPLETED_WITH_ERROR
} from "../users";


import {
    ADDING_BRANCH_COMPLETED_WITH_ERROR,
    ACTIVATING_BRANCH_COMPLETED_WITH_ERROR,
    EDITING_BRANCH_COMPLETED_WITH_ERROR
} from "../branches";


import {
    INSPECTOR_REASSIGNING_PHARMACY_COMPLETED_WITH_ERROR,
    INSPECTOR_ENABLE_UPDATE_PHARMACY_INVENTORY_COMPLETED_WITH_ERROR,
} from "../Inspector/pharmacies";


import {
    INSPECTOR_ASSIGNING_BINDING_PHARMACIES_COMPLETED_WITH_ERROR,
    INSPECTOR_UPDATING_STATUS_BINDING_PHARMACIES_COMPLETED_WITH_ERROR,
    INSPECTOR_EDIT_QUOTA_COMPLETED_WITH_ERROR,
    INSPECTOR_NOTIFYING_OWNER_COMPLETED_WITH_ERROR,
    INSPECTOR_ADDING_NARCOTIC_DATA_COMPLETED_WITH_ERROR,
    INSPECTOR_UPDATING_NARCOTIC_COMPLETED_WITH_ERROR
} from "../Inspector/bindingPharmacies";


import {
    INSPECTOR_UPDATING_STATUS_DISPENSATION_PHARMACIES_COMPLETED_WITH_ERROR,
    INSPECTOR_ASSIGNING_DISPENSATION_PHARMACIES_COMPLETED_WITH_ERROR
} from "../Inspector/dispensationForms";


import {
    INSPECTOR_UPDATE_MANAGER_PHARMACIES_STATUS_COMPLETED_WITH_ERROR
} from "../Inspector/managerRequest";


import {
    INSPECTOR_CHANGE_PHARMACY_STATUS_COMPLETED_WITH_ERROR
} from "../Inspector/audit";


import {
    INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS_COMPLETED_WITH_ERROR,
    UPDATE_REVIEWER_REASON_COMPLETED_WITH_ERROR
} from "../Inspector/pharmacyTypes";


import {
    INSPECTOR_EDITING_MEMBER_COMPLETED_WITH_ERROR,
    INSPECTOR_ADDING_MEMBER_COMPLETED_WITH_ERROR
} from "../Inspector/members";


import {
    CHANGE_PHARMACY_TYPE_COMPLETED_WITH_ERROR,
    APPROVE_CHANGE_PHARMACY_TYPE_COMPLETED_WITH_ERROR,
    NOTIFY_OWNER_COMPLETED_WITH_ERROR,
    RENEW_NIGHT_PHARMACY_COMPLETED_WITH_ERROR
} from "../Inspector/expiredNightPharmacy";



import {
    ASSIGNING_INSPECTOR_OWNER_PHARMACY_COMPLETED_WITH_ERROR
} from "../Inspector/govEntities";

import {
    ADMIN_CONSOLE_ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED_WITH_ERROR
} from "../AdminConsole/egDrugPharmacies";

import {
    ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED_WITH_ERROR,
} from "../egDrugPharmacies";



import {SHOW_NEXT_BUTTON_ERROR, SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
	SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
	SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
	SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED_WITH_ERROR,
	SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
	SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
	SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
	SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
	SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
	SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
	GET_BINDING_PHARMACY_INFO_COMPLETED_WITH_ERROR
       } from "../BindingPharmacy/pharmacyDetails";


import {
    GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR
} from "../BindingPharmacy/basicInfo";

import {EDITING_MEMBER_COMPLETED_WITH_ERROR, ADDING_MEMBER_COMPLETED_WITH_ERROR} from "../members";

import {GETTING_PHARMACY_DETAILS_COMPLETED_WITH_ERROR} from "../BindingPharmacy/basicInfo";

import {GETTING_BINDING_STATUS_COMPLETED_WITH_ERROR} from "../BindingPharmacy/trackRequest";


import {
    ADMIN_CONSOLE_REASSIGN_PHARMACY_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_UPDATE_PHARMACY_STATUS_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_UPDATE_QUOTA_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_UPDATE_PHARMACY_DETAILS_COMPLETED_WITH_ERROR,
} from "../AdminConsole/pharmacies";


import {
    ADMIN_CONSOLE_SUSPEND_BATCH_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET_COMPLETED_WITH_ERROR
} from "../AdminConsole/products";


import {
    ADMIN_CONSOLE_CREATING_DISTRIBUTOR_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_CREATING_BRANCH_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_UPDATING_BRANCH_STATUS_COMPLETED_WITH_ERROR,
    DISTRIBUTOR_STATUS_CHANGED_COMPLETED_WITH_ERROR
} from "../AdminConsole/distributors";


import {
    ADMIN_CONSOLE_CREATING_USER_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_SUSPEND_USER_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_ACTIVATE_USER_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_REASSIGN_DEPARTMENT_COMPLETED_WITH_ERROR
} from "../AdminConsole/users";


import {
    ADMIN_CONSOLE_CREATING_HEALTH_ADMIN_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_EDITING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_ACTIVATE_PUBLIC_ADMIN_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_DEACTIVATE_PUBLIC_ADMIN_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_CREATING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR
} from "../AdminConsole/publicAdmin";



import {
    ADMIN_CONSOLE_CREATING_GEOGRAPHICAL_STRUCTURE_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_EDITING_GEOGRAPHICAL_STRUCTURE_COMPLETED_WITH_ERROR,
    CHANGING_GEOGRAPHY_STATUS_COMPLETED_WITH_ERROR
} from "../AdminConsole/geographical";


import {
    UPDATING_AUDIT_FORM_COMPLETED_WITH_ERROR,
    UPDATING_AUDIT_STATUS_FORM_COMPLETED_WITH_ERROR
} from "../AdminConsole/editAudit";


import {
    ADMIN_CONSOLE_UPDATING_EDA_DEPT_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_ADDING_EDA_DEPT_COMPLETED_WITH_ERROR,
    ADMIN_CONSOLE_UPDATING_DEPT_STATUS_COMPLETED_WITH_ERROR
} from "../AdminConsole/edaDept";


import {
    EDA_CHANGE_AUDIT_STATUS_COMPLETED_WITH_ERROR
} from "../AdminConsole/auditReport";


import {
    ADMIN_CONSOLE_CREATING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR
} from "../AdminConsole/lawsRegulations";

import {
    ADMIN_CONSOLE_CHANGE_OWNERS_COMPLETED_WITH_ERROR
} from "../AdminConsole/pharmacies";


export const CLEAR_ERROR_MSG = "CLEAR_MSG";
const SHOW_MSG = "SHOW_MSG";

const initialState = {};

export const clearMsg = () => {
  return dispatch => {
    dispatch({ type: CLEAR_ERROR_MSG });
  };
};

export const showMsg = msg => {
  return dispatch => {
    dispatch({ type: SHOW_MSG, payload: { message: msg } });
  };
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
  case SHOW_MSG:
  case SIGNING_COMPLETED_WITH_ERROR:
  case FORGET_PASSWORD_COMPLETED_WITH_ERROR:
  case CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR:
  case VERIFYING_OTP_COMPLETED_WITH_ERROR:
  case REASSIGNING_PHARMACY_COMPLETED_WITH_ERROR:
  case INSPECTOR_VERIFYING_OTP_COMPLETED_WITH_ERROR:
  case INSPECTOR_SIGNING_COMPLETED_WITH_ERROR:
  case INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR:
  case INSPECTOR_FORGET_PASSWORD_COMPLETED_WITH_ERROR:
  case INSPECTOR_REASSIGNING_PHARMACY_COMPLETED_WITH_ERROR:
  case INSPECTOR_ASSIGNING_BINDING_PHARMACIES_COMPLETED_WITH_ERROR:
  case INSPECTOR_UPDATING_STATUS_BINDING_PHARMACIES_COMPLETED_WITH_ERROR:
  case INSPECTOR_EDIT_QUOTA_COMPLETED_WITH_ERROR:
  case INSPECTOR_UPDATING_STATUS_DISPENSATION_PHARMACIES_COMPLETED_WITH_ERROR:
  case INSPECTOR_ASSIGNING_DISPENSATION_PHARMACIES_COMPLETED_WITH_ERROR:
  case INSPECTOR_UPDATE_MANAGER_PHARMACIES_STATUS_COMPLETED_WITH_ERROR:
  case INSPECTOR_CHANGE_PHARMACY_STATUS_COMPLETED_WITH_ERROR:
  case INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS_COMPLETED_WITH_ERROR:
  case INSPECTOR_ADDING_MEMBER_COMPLETED_WITH_ERROR:
  case INSPECTOR_EDITING_MEMBER_COMPLETED_WITH_ERROR:
  case UPLOADING_ATTACHMENTS_COMPLETED_WITH_ERROR:
  case EDITING_MEMBER_COMPLETED_WITH_ERROR:
  case ADDING_MEMBER_COMPLETED_WITH_ERROR:
  case GETTING_PHARMACY_DETAILS_COMPLETED_WITH_ERROR:
  case GETTING_BINDING_STATUS_COMPLETED_WITH_ERROR:
  case SHOW_NEXT_BUTTON_ERROR:
  case SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR:
  case SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR:
  case SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR:
  case SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED_WITH_ERROR:
  case SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR:
  case SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR:
  case SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR:
  case SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR:
  case SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR:
  case SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR:
  case GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_SIGNING_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED_WITH_ERROR:
  case  ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_REASSIGN_PHARMACY_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_UPDATE_PHARMACY_STATUS_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_UPDATE_QUOTA_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_SUSPEND_BATCH_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_CREATING_DISTRIBUTOR_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_CREATING_BRANCH_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_UPDATING_BRANCH_STATUS_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_CREATING_USER_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_SUSPEND_USER_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_ACTIVATE_USER_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_CREATING_HEALTH_ADMIN_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_EDITING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_ACTIVATE_PUBLIC_ADMIN_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_DEACTIVATE_PUBLIC_ADMIN_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_CREATING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_CREATING_GEOGRAPHICAL_STRUCTURE_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_EDITING_GEOGRAPHICAL_STRUCTURE_COMPLETED_WITH_ERROR:
  case UPDATING_AUDIT_FORM_COMPLETED_WITH_ERROR:
  case UPDATING_AUDIT_STATUS_FORM_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_UPDATING_EDA_DEPT_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_ADDING_EDA_DEPT_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_UPDATING_DEPT_STATUS_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_CREATING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR:
  case CHANGE_PHARMACY_TYPE_COMPLETED_WITH_ERROR:
  case APPROVE_CHANGE_PHARMACY_TYPE_COMPLETED_WITH_ERROR:
  case NOTIFY_OWNER_COMPLETED_WITH_ERROR:
  case RENEW_NIGHT_PHARMACY_COMPLETED_WITH_ERROR:
  case UPDATE_REVIEWER_REASON_COMPLETED_WITH_ERROR:
  case CHANGING_GEOGRAPHY_STATUS_COMPLETED_WITH_ERROR:
  case DISTRIBUTOR_STATUS_CHANGED_COMPLETED_WITH_ERROR:
  case ASSIGNING_INSPECTOR_OWNER_PHARMACY_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED_WITH_ERROR:
  case ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED_WITH_ERROR:
  case INSPECTOR_NOTIFYING_OWNER_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_RESENDING_RESET_OTP_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR:
  case INSPECTOR_VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR:
  case INSPECTOR_RESENDING_RESET_OTP_COMPLETED_WITH_ERROR:
  case INSPECTOR_RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR:
  case VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR:
  case RESENDING_RESET_OTP_COMPLETED_WITH_ERROR:
  case RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR:
  case  ADMIN_CONSOLE_CREATING_HOME_OFFICE_MESSAGE_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_REASSIGN_DEPARTMENT_COMPLETED_WITH_ERROR:
  case INSPECTOR_ADDING_NARCOTIC_DATA_COMPLETED_WITH_ERROR:
  case INSPECTOR_UPDATING_NARCOTIC_COMPLETED_WITH_ERROR:
  case EDA_CHANGE_AUDIT_STATUS_COMPLETED_WITH_ERROR:
  case RESENDING_OTP_COMPLETED_WITH_ERROR:
  case INSPECTOR_RESENDING_OTP_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_RESENDING_OTP_COMPLETED_WITH_ERROR:
  case GET_BINDING_PHARMACY_INFO_COMPLETED_WITH_ERROR:
  case ADDING_BRANCH_COMPLETED_WITH_ERROR:
  case ACTIVATING_BRANCH_COMPLETED_WITH_ERROR:
  case EDITING_BRANCH_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_CHANGE_OWNERS_COMPLETED_WITH_ERROR:
  case ADMIN_CONSOLE_UPDATE_PHARMACY_DETAILS_COMPLETED_WITH_ERROR:
  case INSPECTOR_ENABLE_UPDATE_PHARMACY_INVENTORY_COMPLETED_WITH_ERROR:
      return {
          msg: payload.error.message
      };
  case CLEAR_ERROR_MSG:
      return {};
  default:
      return state;
  }
};
