import { doRequest } from "../../../Helper/network";
import { GeographicalStructure } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_REGIONS = "ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_REGIONS";
export const ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_REGIONS_COMPLETED = "ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_REGIONS_COMPLETED";
export const ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_REGIONS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_REGIONS_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_STRUCTURE_PROFILE = "ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_STRUCTURE_PROFILE";
export const ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_STRUCTURE_PROFILE_COMPLETED = "ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_STRUCTURE_PROFILE_COMPLETED";
export const ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_STRUCTURE_PROFILE_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_STRUCTURE_PROFILE_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_CREATING_GEOGRAPHICAL_STRUCTURE = "ADMIN_CONSOLE_CREATING_GEOGRAPHICAL_STRUCTURE";
export const ADMIN_CONSOLE_CREATING_GEOGRAPHICAL_STRUCTURE_COMPLETED = "ADMIN_CONSOLE_CREATING_GEOGRAPHICAL_STRUCTURE_COMPLETED";
export const ADMIN_CONSOLE_CREATING_GEOGRAPHICAL_STRUCTURE_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_CREATING_GEOGRAPHICAL_STRUCTURE_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_EDITING_GEOGRAPHICAL_STRUCTURE = "ADMIN_CONSOLE_EDITING_GEOGRAPHICAL_STRUCTURE";
export const ADMIN_CONSOLE_EDITING_GEOGRAPHICAL_STRUCTURE_COMPLETED = "ADMIN_CONSOLE_EDITING_GEOGRAPHICAL_STRUCTURE_COMPLETED";
export const ADMIN_CONSOLE_EDITING_GEOGRAPHICAL_STRUCTURE_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_EDITING_GEOGRAPHICAL_STRUCTURE_COMPLETED_WITH_ERROR";



const CHANGING_GEOGRAPHY_STATUS = "CHANGING_GEOGRAPHY_STATUS";
export const CHANGING_GEOGRAPHY_STATUS_COMPLETED = "CHANGING_GEOGRAPHY_STATUS_COMPLETED";
export const CHANGING_GEOGRAPHY_STATUS_COMPLETED_WITH_ERROR = "CHANGING_GEOGRAPHY_STATUS_COMPLETED_WITH_ERROR";


const initial_state={};


function setGeographicalList(state, data = {}) {
    const {dataOrder=[], responseData={}, total=0 } = data;
    return {dataOrder, responseData, total};
}


function setGeographicalProfile(state, data={}){
    return {geographicalProfile: {...data}};
}


export const fetchGeographicalStructure = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_REGIONS });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: GeographicalStructure.getAllGeographicalListURL(),
		params: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_REGIONS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_REGIONS_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const fetchGeographicalStructureProfile = (geographicalId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_STRUCTURE_PROFILE });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: GeographicalStructure.getGeographicalProfileURL(geographicalId),
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_STRUCTURE_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_STRUCTURE_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const addGeographicalStructure = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_GEOGRAPHICAL_STRUCTURE });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: GeographicalStructure.getAddGeographyURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_GEOGRAPHICAL_STRUCTURE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_GEOGRAPHICAL_STRUCTURE_COMPLETED,
		    payload: {message: payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export const editGeographicalStructure = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_EDITING_GEOGRAPHICAL_STRUCTURE });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: GeographicalStructure.getEditGeographyURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_EDITING_GEOGRAPHICAL_STRUCTURE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_EDITING_GEOGRAPHICAL_STRUCTURE_COMPLETED,
		    payload: {message: payload.message}
		});
	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const suspendGeography  = (geographicalId, data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: CHANGING_GEOGRAPHY_STATUS });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: GeographicalStructure.getSuspendGeographyURL(geographicalId),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: CHANGING_GEOGRAPHY_STATUS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: CHANGING_GEOGRAPHY_STATUS_COMPLETED,
		    payload: {message: payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const activateGeography  = (geographicalId, data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: CHANGING_GEOGRAPHY_STATUS });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: GeographicalStructure.getActivateGeographyURL(geographicalId),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: CHANGING_GEOGRAPHY_STATUS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: CHANGING_GEOGRAPHY_STATUS_COMPLETED,
		    payload: {message: payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_REGIONS:
	return {loading:true};
    case ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_REGIONS_COMPLETED:
	return setGeographicalList(state, payload);
    case ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_REGIONS_COMPLETED_WITH_ERROR:
	return {loading:false};
    case ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_STRUCTURE_PROFILE:
	return {loading: true};
    case ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_STRUCTURE_PROFILE_COMPLETED:
	return setGeographicalProfile(state, payload);
    case ADMIN_CONSOLE_GETTING_GEOGRAPHICAL_STRUCTURE_PROFILE_COMPLETED_WITH_ERROR:
	return {loading: false};
    default:
	return state;
	
    }
}
