import { doRequest } from "../../../Helper/network";
import { InspectorAllReports as Reports } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";

export const GETTING_INSPECTOR_PHARMACIES = "GETTING_INSPECTOR_PHARMACIES";
export const GETTING_INSPECTOR_PHARMACIES_COMPLETED = "GETTING_INSPECTOR_PHARMACIES_COMPLETED";
export const GETTING_INSPECTOR_PHARMACIES_COMPLETED_WITH_ERROR = "GETTING_INSPECTOR_PHARMACIES_COMPLETED_WITH_ERROR";


export const GETTING_INSPECTOR_PHARMACIES_TRANSACTION = "GETTING_INSPECTOR_PHARMACIES_TRANSACTION";
export const GETTING_INSPECTOR_PHARMACIES_TRANSACTION_COMPLETED = "GETTING_INSPECTOR_PHARMACIES_TRANSACTION_COMPLETED";
export const GETTING_INSPECTOR_PHARMACIES_TRANSACTION_COMPLETED_WITH_ERROR = "GETTING_INSPECTOR_PHARMACIES_TRANSACTION_COMPLETED_WITH_ERROR";


export const GETTING_INSPECTOR_DISTRIBUTOR_BRANCHES = "GETTING_INSPECTOR_DISTRIBUTOR_BRANCHES";
export const GETTING_INSPECTOR_DISTRIBUTOR_BRANCHES_COMPLETED = "GETTING_INSPECTOR_DISTRIBUTOR_BRANCHES_COMPLETED";
export const GETTING_INSPECTOR_DISTRIBUTOR_BRANCHES_COMPLETED_WITH_ERROR = "GETTING_INSPECTOR_DISTRIBUTOR_BRANCHES_COMPLETED_WITH_ERROR";


export const GETTING_INSPECTOR_AUDIT_VISIT = "GETTING_INSPECTOR_AUDIT_VISIT";
export const GETTING_INSPECTOR_AUDIT_VISIT_COMPLETED = "GETTING_INSPECTOR_AUDIT_VISIT_COMPLETED";
export const GETTING_INSPECTOR_AUDIT_VISIT_COMPLETED_WITH_ERROR = "GETTING_INSPECTOR_AUDIT_VISIT_COMPLETED_WITH_ERROR";

export const GETTING_INSPECTOR_EXPIRE_PRODUCTS = "GETTING_INSPECTOR_EXPIRE_PRODUCTS";
export const GETTING_INSPECTOR_EXPIRE_PRODUCTS_COMPLETED = "GETTING_INSPECTOR_EXPIRE_PRODUCTS_COMPLETED";
export const GETTING_INSPECTOR_EXPIRE_PRODUCTS_COMPLETED_WITH_ERROR = "GETTING_INSPECTOR_EXPIRE_PRODUCTS_COMPLETED_WITH_ERROR";


export const  GETTING_INSPECTOR_DISTRIBUTOR_PHARMACIES =  "GETTING_INSPECTOR_DISTRIBUTOR_PHARMACIES";
export const  GETTING_INSPECTOR_DISTRIBUTOR_PHARMACIES_COMPLETED =  "GETTING_INSPECTOR_DISTRIBUTOR_PHARMACIES_COMPLETED";
export const  GETTING_INSPECTOR_DISTRIBUTOR_PHARMACIES_COMPLETED_WITH_ERROR =  "GETTING_INSPECTOR_DISTRIBUTOR_PHARMACIES_COMPLETED_WITH_ERROR";


const initial_state={};


function setInspectorPharmaciesData(state, data={}){
    const {responseData={}, total=0} = data || {};
    return {...state, InspectorPharmaciesReport:responseData, loading:false, totalRecords: total};
};

function setInspectorPharmaciesTransactionData(state, data={}){
    const {responseData={},  total=0} = data || {};
    return {...state, InspectorPharmaciesTransaction: responseData, loading:false, totalRecords: total};
}

function setInspectorDistributorBranches(state, data={}){
    const {responseData={}, total=0} = data || {};
    return {...state, InspectorDistributorBranches: responseData, loading:false, totalRecords: total};
}

function setInspectorAuditVisit(state, data={}){
    const {responseData={}, total=0, violations={}, offenses={}} = data || {};
    return {...state, InspectorAuditVisit: responseData, loading:false, totalRecords: total, violations, offenses};
}


function setInspectorExpireProducts(state, data={}){
    const {responseData={}, total=0} = data || {};
    return {...state, InspectorExpireProducts: responseData, loading:false, totalRecords: total};
}


function setInspectorDistributorPharmacies(state, data={}){
    const {responseData={}, total=0} = data || {};
    return {...state, InspectorDistributorPharmacies: responseData, loading:false, totalRecords: total};
}


export const getInspectorPharmacies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GETTING_INSPECTOR_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Reports.getInspectorPharmaciesURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_INSPECTOR_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_INSPECTOR_PHARMACIES_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};


export const getInspectorPharmacyTransaction = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GETTING_INSPECTOR_PHARMACIES_TRANSACTION });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Reports.getInspectorPharmaciesTransactionURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_INSPECTOR_PHARMACIES_TRANSACTION_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_INSPECTOR_PHARMACIES_TRANSACTION_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};



export const getInspectorDistributorBranches = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GETTING_INSPECTOR_DISTRIBUTOR_BRANCHES });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Reports.getInspectorDistributorBranchesURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_INSPECTOR_DISTRIBUTOR_BRANCHES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_INSPECTOR_DISTRIBUTOR_BRANCHES_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};





export const getInspectorAuditVisit = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GETTING_INSPECTOR_AUDIT_VISIT });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Reports.getInspectorAuditVisitURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_INSPECTOR_AUDIT_VISIT_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_INSPECTOR_AUDIT_VISIT_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};




export const getInspectorExpireProducts = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GETTING_INSPECTOR_EXPIRE_PRODUCTS });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Reports.getInspectorExpireProductsURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_INSPECTOR_EXPIRE_PRODUCTS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_INSPECTOR_EXPIRE_PRODUCTS_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};




export const getInspectorDistributorPharmacies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GETTING_INSPECTOR_DISTRIBUTOR_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Reports.getInspectorDistributorPharmaciesURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_INSPECTOR_DISTRIBUTOR_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_INSPECTOR_DISTRIBUTOR_PHARMACIES_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};





export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){

    case GETTING_INSPECTOR_PHARMACIES:
	return {loading:true};
    case GETTING_INSPECTOR_PHARMACIES_COMPLETED:
	return setInspectorPharmaciesData(state, payload);
    case GETTING_INSPECTOR_PHARMACIES_COMPLETED_WITH_ERROR:
	return {loading:false};

    case GETTING_INSPECTOR_PHARMACIES_TRANSACTION:
	return {loading: true};
    case GETTING_INSPECTOR_PHARMACIES_TRANSACTION_COMPLETED:
	return setInspectorPharmaciesTransactionData(state, payload);
    case GETTING_INSPECTOR_PHARMACIES_TRANSACTION_COMPLETED_WITH_ERROR:
	return {loading: false};

    case GETTING_INSPECTOR_DISTRIBUTOR_BRANCHES:
	return {loading:true};
    case GETTING_INSPECTOR_DISTRIBUTOR_BRANCHES_COMPLETED:
	return setInspectorDistributorBranches(state, payload);
    case GETTING_INSPECTOR_DISTRIBUTOR_BRANCHES_COMPLETED_WITH_ERROR:
	return {loading:false};
	
    case GETTING_INSPECTOR_AUDIT_VISIT:
	return {loading:true};
    case GETTING_INSPECTOR_AUDIT_VISIT_COMPLETED:
	return setInspectorAuditVisit(state, payload);
    case GETTING_INSPECTOR_AUDIT_VISIT_COMPLETED_WITH_ERROR:
	return {loading:false};


    case GETTING_INSPECTOR_EXPIRE_PRODUCTS:
	return {loading:true};
    case GETTING_INSPECTOR_EXPIRE_PRODUCTS_COMPLETED:
	return setInspectorExpireProducts(state, payload);
    case GETTING_INSPECTOR_EXPIRE_PRODUCTS_COMPLETED_WITH_ERROR:
	return {loading:false};


    case GETTING_INSPECTOR_DISTRIBUTOR_PHARMACIES:
	return {loading:true};
    case GETTING_INSPECTOR_DISTRIBUTOR_PHARMACIES_COMPLETED:
	return setInspectorDistributorPharmacies(state, payload);
    case GETTING_INSPECTOR_DISTRIBUTOR_PHARMACIES_COMPLETED_WITH_ERROR:
	return {loading:false};
	
    default:
	return state;
	
    }
}

