import * as Auth from './auth';
import * as Pharmacies from "./pharmacies";
import * as Common from "./common";
import * as User from "./user";
import * as Reports from "./reports";
import * as Members from "./members";
import * as DistributorLawsRegulations from "./lawsRegulations";
import * as Notification from "./notification";
import * as Branches from "./branches";

import * as InspectorAuth from "./Inspector/auth";
import * as InspectorPharmacies from "./Inspector/pharmacies";
import * as InspectorAudit from "./Inspector/audit";
import * as InspectorMembers from "./Inspector/members";
import * as InspectorBindingPharmacies from "./Inspector/bindingPharmacies";
import * as InspectorManagerPharmacies from "./Inspector/managerRequest";
import * as InspectorDispensationPharmacies from "./Inspector/dispensationPermission";
import * as InspectorAllReports from "./Inspector/allReports";
import * as InspectorExpiredNightPharmacy from "./Inspector/expiredNightPharmacy";
import * as InspectorNotitifcation from "./Inspector/notification";
import * as InspectorLawsRegulations from "./Inspector/lawsRegulations";
import * as InspectorTasksCounts from "./Inspector/tasksCounts";

import * as BindingBasicInfo from "./BindingPharmacy/basicInfo";
import * as BindingPharmacyDetails from "./BindingPharmacy/pharmacyDetails";
import * as BindingTrackStatus from "./BindingPharmacy/trackRequest";

import * as AdminConsoleAuth from "./AdminConsole/auth";
import * as AdminConsoleProducts from "./AdminConsole/products";
import * as AdminConsolePharmacies from "./AdminConsole/pharmacies";
import * as AdminConsoleUsers from "./AdminConsole/users";
import * as AdminDistributors from "./AdminConsole/distributors";
import * as PublicAdmin from "./AdminConsole/publicAdmin";
import * as LawsRegulations from "./AdminConsole/lawsRegulations";
import * as CitizensResources from "./AdminConsole/citizensResources";
import * as CitizensVideos from "./AdminConsole/citizensVideos";
import * as GeographicalStructure from "./AdminConsole/geographical";
import * as EditAudit from "./AdminConsole/edaAudit";
import * as InspectorPharmacyTypeChange from "./Inspector/pharmacyTypes";
import * as EdaDept from "./AdminConsole/edaDept";
import * as HomeOfficeMessage from "./AdminConsole/homeOfficeMsg";
import * as AdminAuditReport from "./AdminConsole/auditReport";
import * as AdminNotitifcation from "./AdminConsole/notification";

import * as InspectorGovEntities from "./Inspector/govEntities";

export {
    Auth,
    Pharmacies,
    Common,
    User,
    Reports,
    Members,
    InspectorAuth,
    InspectorPharmacies,
    InspectorAudit,
    InspectorMembers,
    InspectorBindingPharmacies,
    InspectorManagerPharmacies,
    InspectorDispensationPharmacies,
    InspectorAllReports,
    BindingBasicInfo,
    BindingPharmacyDetails,
    BindingTrackStatus,
    AdminConsoleAuth,
    AdminConsoleProducts,
    AdminConsolePharmacies,
    AdminConsoleUsers,
    AdminDistributors,
    PublicAdmin,
    LawsRegulations,
    CitizensResources,
    CitizensVideos,
    GeographicalStructure,
    EditAudit,
    InspectorPharmacyTypeChange,
    EdaDept,
    InspectorExpiredNightPharmacy,
    InspectorGovEntities,
    HomeOfficeMessage,
    AdminAuditReport,
    Notification,
    InspectorNotitifcation,
    AdminNotitifcation,
    InspectorLawsRegulations,
    InspectorTasksCounts,
    DistributorLawsRegulations,
    Branches
};
