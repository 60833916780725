import { doRequest } from "../../../Helper/network";
import { InspectorPharmacies as Pharmacies } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const INSPECTOR_GETTING_PHARMACIES = "INSPECTOR_GETTING_PHARMACIES";
export const INSPECTOR_GETTING_PHARMACIES_COMPLETED = "INSPECTOR_GETTING_PHARMACIES_COMPLETED";
export const INSPECTOR_GETTING_PHARMACIES_COMPLETED_WITH_ERROR = "INSPECTOR_GETTING_PHARMACIES_COMPLETED_WITH_ERROR";

export const INSPECTOR_FETCHING_OPTIONS="INSPECTOR_FETHCING_OPTIONS";
export const INSPECTOR_FETCHING_OPTIONS_COMPLETED="INSPECTOR_FETCHING_OPTIONS_COMPLETED";
export const INSPECTOR_FETCHING_OPTIONS_COMPLETED_WITH_ERROR="INSPECTOR_FETCHING_OPTIONS_COMPLETED_WITH_ERROR";


export const INSPECTOR_GETTING_PHARMACY_PROFILE="INSPECTOR_GETTING_PHARMACY_PROFILE";
export const INSPECTOR_GETTING_PHARMACY_PROFILE_COMPLETED="INSPECTOR_GETTING_PHARMACY_PROFILE_COMPLETED";
export const INSPECTOR_GETTING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR="INSPECTOR_GETTING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR";


export const INSPECTOR_REASSIGNING_PHARMACY="INSPECTOR_REASSIGNING_PHARMACY";
export const INSPECTOR_REASSIGNING_PHARMACY_COMPLETED="INSPECTOR_REASSIGNING_PHARMACY_COMPLETED";
export const INSPECTOR_REASSIGNING_PHARMACY_COMPLETED_WITH_ERROR="INSPECTOR_REASSIGNING_PHARMACY_COMPLETED_WITH_ERROR";

export const INSPECTOR_ENABLE_UPDATE_PHARMACY_INVENTORY="INSPECTOR_ENABLE_UPDATE_PHARMACY_INVENTORY";
export const INSPECTOR_ENABLE_UPDATE_PHARMACY_INVENTORY_COMPLETED="INSPECTOR_ENABLE_UPDATE_PHARMACY_INVENTORY_COMPLETED";
export const INSPECTOR_ENABLE_UPDATE_PHARMACY_INVENTORY_COMPLETED_WITH_ERROR="INSPECTOR_ENABLE_UPDATE_PHARMACY_INVENTORY_COMPLETED_WITH_ERROR";


const initial_state={};


function setPharmaciesData(state, data = {}) {
    const { responseData, total=0, dataOrder } = data;
  if (responseData) {
    const userIds = Object.keys(responseData);
    const prevUsers = {};
    let newState = Object.assign({}, prevUsers);

    userIds.forEach(id => {
      newState = Object.assign(
        newState,
        setIndividualPharmacy(prevUsers, responseData, id)
      );
    });
    //
      return {pharmacies: newState, totalRecords: total, loading:false, dataOrder};
  } else return state;
}


function setIndividualPharmacy(state, pharmacies, id) {
  const pharmacy = Object.assign({}, state[id], pharmacies[id]);
    return { [id]: { ...pharmacy }}; 
}


function setPharmacyProfileData(state, payload={}){
    const {pharmacy={}, manager={}, owner={}, renewReq={}, products={}, dispensationQuotaDetails={}, pharmacists={}} = payload;
    return {pharmacy, manager, owner, renewReq, products, dispensationQuotaDetails, pharmacists};
}


export const getPharmacies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_GETTING_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Pharmacies.getAllPharmaciesURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GETTING_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GETTING_PHARMACIES_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};


export const fetchStatusOptions = () => {
    return async (dispatch, getState) => {
	try {
	    //const { auth = {} } = getState();
	    dispatch({ type: INSPECTOR_FETCHING_OPTIONS});
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Pharmacies.getSearchOptionsURL(),
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_FETCHING_OPTIONS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_FETCHING_OPTIONS_COMPLETED,
		    payload: payload.data
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const getPharmacyProfile = (pharmacyId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_GETTING_PHARMACY_PROFILE});
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Pharmacies.getPharmacyProfileURL(pharmacyId)
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GETTING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GETTING_PHARMACY_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const reassignPharmacy = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_REASSIGNING_PHARMACY});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Pharmacies.getReassignPharmacyURL(),
		data:data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_REASSIGNING_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_REASSIGNING_PHARMACY_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };   
};

export const reassignReviewerPharmacy = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_REASSIGNING_PHARMACY});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Pharmacies.getReviewerReassignPharmacyURL(),
		data:data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_REASSIGNING_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_REASSIGNING_PHARMACY_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };   
    
};



export const reassignGov = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_REASSIGNING_PHARMACY});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Pharmacies.getReassignGovURL(),
		data:data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_REASSIGNING_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_REASSIGNING_PHARMACY_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };   
};


export const enableUpdatePharmacyInventory = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_ENABLE_UPDATE_PHARMACY_INVENTORY});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Pharmacies.getEnableUpdatePharmacyInventoryURL(),
		data:data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_ENABLE_UPDATE_PHARMACY_INVENTORY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_ENABLE_UPDATE_PHARMACY_INVENTORY_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };   
};

export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case INSPECTOR_GETTING_PHARMACIES:
	return {loading:true};
    case INSPECTOR_GETTING_PHARMACIES_COMPLETED:
	return setPharmaciesData(state, payload);
    case INSPECTOR_GETTING_PHARMACIES_COMPLETED_WITH_ERROR:
	return {loading:false};
    case INSPECTOR_GETTING_PHARMACY_PROFILE:
	return {loading:true};
    case INSPECTOR_GETTING_PHARMACY_PROFILE_COMPLETED:
	return setPharmacyProfileData(state, payload);
    case INSPECTOR_GETTING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR:
	return {loading:false};
    default:
	return state;
	
    }
}
