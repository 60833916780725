export const getAllDispensationPharmaciesURL = () => {
  return "/inspectionAuhtority/permissionForm";
};

export const getReviewerDispensationPharmaciesURL = () => {
    return "/inspectionAuhtority/reviewer/permissionForm";
};


export const getDispensationPharmacyProfileURL = (bindingFormId) => {
    return `/inspectionAuhtority/bindingForm/${bindingFormId}`;
};

export const getDispensationPharmacyStatusUpdateURL = () => {
    return "/inspectionAuhtority/permissionForm/update-status";
};


export const getDispensationPharmacyAssignReviewerURL = () => {
    return "/inspectionAuhtority/permissionForm/assign";
};
