export const getSignInURL = () => {
  return "/distributors/sign-in";
};

export const getSignOutURL = () => {
  return "/distributors/sign-out";
};

export const getForgotPasswordURL = () => {
  return "/forgot-password";
};

export const createNewPasswordURL = () => {
  return "/distributors/create-new-password";
};

export const verifyOtpURL = () => {
  return "/distributors/verify-otp";
};

export const resendOtpURL = () => {
  return "/distributors/resend-otp";
};

export const forgetPasswordURL = () => {
  return "/distributors/forgot-password";
};

export const changePassword = () => {
  return "/change-forgotten-password";
};

export const getBasicInfoURL = () => {
  return "/distributors/user/get-basic-info";
};


export const resetNewPasswordURL = () => {
  return "/distributors/reset-password";
};


export const verifyResetOtpURL = () => {
  return "/distributors/verify-reset-password-otp";
};


export const resendResetOtpURL = () => {
  return "/distributors/resend-reset-password-otp";
};

