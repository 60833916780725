import { doRequest } from "../../../Helper/network";
import { EditAudit  } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";

export const GETTING_CURRENT_AUDIT_FORM = "GETTING_CURRENT_AUDIT_FORM";
export const GETTING_CURRENT_AUDIT_FORM_COMPLETED = "GETTING_CURRENT_AUDIT_FORM_COMPLETED";
export const GETTING_CURRENT_AUDIT_FORM_COMPLETED_WITH_ERROR = "GETTING_CURRENT_AUDIT_FORM_COMPLETED_WITH_ERROR";


export const UPDATING_AUDIT_FORM = "UPDATING_AUDIT_FORM";
export const UPDATING_AUDIT_FORM_COMPLETED = "UPDATING_AUDIT_FORM_COMPLETED";
export const UPDATING_AUDIT_FORM_COMPLETED_WITH_ERROR = "UPDATING_AUDIT_FORM_COMPLETED_WITH_ERROR";


export const GETTING_CURRENT_STATUS_FORM = "GETTING_CURRENT_STATUS_FORM";
export const GETTING_CURRENT_STATUS_FORM_COMPLETED = "GETTING_CURRENT_STATUS_FORM_COMPLETED";
export const GETTING_CURRENT_STATUS_FORM_COMPLETED_WITH_ERROR = "GETTING_CURRENT_STATUS_FORM_COMPLETED_WITH_ERROR";


export const UPDATING_AUDIT_STATUS_FORM = "UPDATING_AUDIT_STATUS_FORM";
export const UPDATING_AUDIT_STATUS_FORM_COMPLETED = "UPDATING_AUDIT_STATUS_FORM_COMPLETED";
export const UPDATING_AUDIT_STATUS_FORM_COMPLETED_WITH_ERROR = "UPDATING_AUDIT_STATUS_FORM_COMPLETED_WITH_ERROR";



const initial_state={
	data:{
        offenses:{},
        violations:{}
    },
    loading:false,
    statusData:{
        pharmacyAuditStatus:{}
    }
};





function setAuditData(state, data = {}) {
    return {...state, data:data, loading:false};
}


function setStatusData(state, data={}){
    return {...state, statusData: data, loading:false};
}



export const getAuditForm = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GETTING_CURRENT_AUDIT_FORM });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: EditAudit.getCurrentAuditFormURL(),
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_CURRENT_AUDIT_FORM_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_CURRENT_AUDIT_FORM_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const updateAuditForm = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: UPDATING_AUDIT_FORM });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: EditAudit.getUpdateAuditFormURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: UPDATING_AUDIT_FORM_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: UPDATING_AUDIT_FORM_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const getStatusForm = () => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GETTING_CURRENT_STATUS_FORM });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: EditAudit.getCurrentStatusFormURL(),
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_CURRENT_STATUS_FORM_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_CURRENT_STATUS_FORM_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const updateAuditStatusForm = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: UPDATING_AUDIT_STATUS_FORM });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: EditAudit.getUpdateAuditStatusURL(),
		data: data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: UPDATING_AUDIT_STATUS_FORM_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: UPDATING_AUDIT_STATUS_FORM_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
};


export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case GETTING_CURRENT_AUDIT_FORM:
	return {loading:true};
    case GETTING_CURRENT_AUDIT_FORM_COMPLETED:
	return setAuditData(state, payload);
    case GETTING_CURRENT_AUDIT_FORM_COMPLETED_WITH_ERROR:
	return setAuditData(state, payload);
    case GETTING_CURRENT_STATUS_FORM_COMPLETED:
	return setStatusData(state, payload);
	default:
	return state;
	
    }
}
