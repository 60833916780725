import {INSPECTOR_GETTING_INITIAL_DATA_COMPLETED, INSPECTOR_SIGNING_COMPLETED} from "../auth";

const initial_state={};

function setBranches(state, data={}){
    const {distributorBranches={}} = data;
    if (distributorBranches) {
	return {distributorBranches};
    } else return state;   
}

export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case INSPECTOR_GETTING_INITIAL_DATA_COMPLETED:
    case INSPECTOR_SIGNING_COMPLETED:
	return setBranches(state, payload);
    default:
	return state;
	
    }
}
