export const getAllNewManagerPharmaciesURL = () => {
    return `/inspectionAuhtority/newManagerRequest`;
};

export const getAllReviewerNewManagerPharmaciesURL = () => {
    return `/inspectionAuhtority/reviewer/newManagerRequest`;
};

export const getManagerProfileURL = (managerId) => {
    return `/inspectionAuhtority/newManagerRequest/${managerId}`;
};


export const getUpdateManagerPharmacyURL = () => {
    return `/inspectionAuhtority/newManagerRequest/update-status`;
};
