import { doRequest } from "../../Helper/network";
import { User } from "../../Helper/urls";
import { REQUEST_TYPE } from "../../constant";

export const UPLOADING_ATTACHMENTS = "UPLOADING_ATTACHMENTS";
export const UPLOADING_ATTACHMENTS_COMPLETED = "UPLOADING_ATTACHMENTS_COMPLETED";
export const UPLOADING_ATTACHMENTS_COMPLETED_WITH_ERROR = "UPLOADING_ATTACHMENTS_COMPLETED_WITH_ERROR";

const initial_state={};


function setUsersData(state, data={}){
    const { users } = data;
    if (users) {
    const userIds = Object.keys(users);
    const prevUsers = { ...state };
    let newState = Object.assign({}, prevUsers);

    userIds.forEach(id => {
      newState = Object.assign(
        newState,
        setIndividualUser(prevUsers, users, id)
      );
    });
    return newState;
  } else return state;
    
}

function setIndividualUser(state, users, id) {
  const user = Object.assign({}, state[id], users[id]);
  return { [id]: { ...user } };
}



export const uploadAttachmentsFirstTime = (data, onUploadProgress) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: UPLOADING_ATTACHMENTS });
	    let response = await doRequest({
		onUploadProgress: onUploadProgress,
	    	method: REQUEST_TYPE.POST,
	    	url: User.getUploadAttachmentFirstTimeURL(),
	    	data:data
		
	    });
	    const { status,payload } = response;
	    if (status === false) {
		dispatch({
		    type: UPLOADING_ATTACHMENTS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: UPLOADING_ATTACHMENTS_COMPLETED,
		    payload: payload.data
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};




export const uploadAttachments = (data, onUploadProgress) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: UPLOADING_ATTACHMENTS });
	    let response = await doRequest({
		onUploadProgress: onUploadProgress,
	    	method: REQUEST_TYPE.POST,
	    	url: User.getUploadAttachmentURL(),
	    	data:data
		
	    });
	    const { status,payload } = response;
	    if (status === false) {
		dispatch({
		    type: UPLOADING_ATTACHMENTS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: UPLOADING_ATTACHMENTS_COMPLETED,
		    payload: payload.data
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};

export const uploadPharmacyMapping = (data, onUploadProgress) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: UPLOADING_ATTACHMENTS });
	    let response = await doRequest({
		onUploadProgress: onUploadProgress,
	    	method: REQUEST_TYPE.POST,
	    	url: User.getUploadPharmacyMappingURL(),
	    	data:data
		
	    });
	    const { status,payload } = response;
	    if (status === false) {
		dispatch({
		    type: UPLOADING_ATTACHMENTS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: UPLOADING_ATTACHMENTS_COMPLETED,
		    payload: payload.data
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};

export const uploadUpdatePharmacyMapping = (data, onUploadProgress) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: UPLOADING_ATTACHMENTS });
	    let response = await doRequest({
		onUploadProgress: onUploadProgress,
	    	method: REQUEST_TYPE.POST,
	    	url: User.getUploadUpdatePharmacyMappingURL(),
	    	data:data
		
	    });
	    const { status,payload } = response;
	    if (status === false) {
		dispatch({
		    type: UPLOADING_ATTACHMENTS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: UPLOADING_ATTACHMENTS_COMPLETED,
		    payload: payload.data
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};

export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    default:
	return setUsersData(state, payload);;
	
    }
}
