import {INSPECTOR_GETTING_INITIAL_DATA_COMPLETED, INSPECTOR_SIGNING_COMPLETED} from "../auth";

const initial_state={};

function setAdminProfileManager(state, data={}){
    const { members={}, roles=[], publicAdministrationData={}, sub_roles=[], healthDistrictAdministrationsData={}, distributorBranches=[], pharmacyTypes=[] } = data;
    if (members && roles && publicAdministrationData ) {
	return {members, roles, publicAdministrationData, sub_roles, healthDistrictAdministrationsData, distributorBranches, pharmacyTypes};
    } else return state;   
}

export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case INSPECTOR_GETTING_INITIAL_DATA_COMPLETED:
    case INSPECTOR_SIGNING_COMPLETED:
	return setAdminProfileManager(state, payload);
    default:
	return state;
	
    }
}
