export const ASSIGN_REQUEST_NUMBER = "ASSIGN_REQUEST_NUMBER";

const initialState={};


export const setReqNumber = (requestNumber) => {
    return dispatch => {
	dispatch({ type: ASSIGN_REQUEST_NUMBER, payload: {requestNumber: requestNumber}});
    };
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

    case ASSIGN_REQUEST_NUMBER:
	return payload.requestNumber;
    default:
	return state;
    }
};
