import {GETTING_INITIAL_DATA_COMPLETED, SIGNING_COMPLETED} from "../auth";

const initial_state={branches:[]};

function setBranches(state, data={}){
    const { branches} = data;
    if (branches) {
	return {branches:branches};
    } else return state;   
}

export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case GETTING_INITIAL_DATA_COMPLETED:
    case SIGNING_COMPLETED:
	
	return setBranches(state, payload);
    default:
	return state;
	
    }
}
