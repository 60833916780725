import { doRequest } from "../../Helper/network";
import { Pharmacies } from "../../Helper/urls";
import { REQUEST_TYPE } from "../../constant";


export const GETTING_PHARMACIES = "GETTING_PHARMACIES";
export const GETTING_PHARMACIES_COMPLETED = "GETTING_PHARMACIES_COMPLETED";
export const GETTING_PHARMACIES_COMPLETED_WITH_ERROR = "GETTING_PHARMACIES_COMPLETED_WITH_ERROR";

export const FETCHING_OPTIONS="FETHCING_OPTIONS";
export const FETCHING_OPTIONS_COMPLETED="FETCHING_OPTIONS_COMPLETED";
export const FETCHING_OPTIONS_COMPLETED_WITH_ERROR="FETCHING_OPTIONS_COMPLETED_WITH_ERROR";


export const GETTING_PHARMACY_PROFILE="GETTING_PHARMACY_PROFILE";
export const GETTING_PHARMACY_PROFILE_COMPLETED="GETTING_PHARMACY_PROFILE_COMPLETED";
export const GETTING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR="GETTING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR";


export const REASSIGNING_PHARMACY="REASSIGNING_PHARMACY";
export const REASSIGNING_PHARMACY_COMPLETED="REASSIGNING_PHARMACY_COMPLETED";
export const REASSIGNING_PHARMACY_COMPLETED_WITH_ERROR="REASSIGNING_PHARMACY_COMPLETED_WITH_ERROR";


const initial_state={};


function setPharmaciesData(state, data = {}) {
    const { pharmacies, total=0 , dispensationQuotaDetails={}} = data;
  if (pharmacies) {
    const userIds = Object.keys(pharmacies);
    const prevUsers = { };
    let newState = Object.assign({}, prevUsers);

    userIds.forEach(id => {
      newState = Object.assign(
        newState,
        setIndividualPharmacy(prevUsers, pharmacies, id)
      );
    });
    //
      return {pharmacies: newState, totalRecords: total, loading:false, dispensationQuotaDetails};
  } else return state;
}


function setIndividualPharmacy(state, pharmacies, id) {
  const pharmacy = Object.assign({}, state[id], pharmacies[id]);
    return { [id]: { ...pharmacy }}; 
}



export const getPharmacies = (data) => {
    return async (dispatch, getState) => {
	try {
	    //const { auth = {} } = getState();
	    dispatch({ type: GETTING_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Pharmacies.getAllPharmaciesURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_PHARMACIES_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};


export const fetchStatusOptions = () => {
    return async (dispatch, getState) => {
	try {
	    //const { auth = {} } = getState();
	    dispatch({ type: FETCHING_OPTIONS});
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Pharmacies.getSearchOptionsURL(),
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: FETCHING_OPTIONS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: FETCHING_OPTIONS_COMPLETED,
		    payload: payload.data
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const getPharmacyProfile = (pharmacyId) => {
    return async (dispatch, getState) => {
	try {
	    //const { auth = {} } = getState();
	    dispatch({ type: GETTING_PHARMACY_PROFILE});
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Pharmacies.getPharmacyProfileURL(pharmacyId)
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_PHARMACY_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const reassignPharmacy = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: REASSIGNING_PHARMACY});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Pharmacies.getReassignPharmacyURL(),
		data:data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: REASSIGNING_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: REASSIGNING_PHARMACY_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };   
}


export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case GETTING_PHARMACIES:
	return {loading:true};
    case GETTING_PHARMACIES_COMPLETED:
	return setPharmaciesData(state, payload);
    case GETTING_PHARMACIES_COMPLETED_WITH_ERROR:
	return {loading:false};
    case GETTING_PHARMACY_PROFILE:
	return {loading:true};
    case GETTING_PHARMACY_PROFILE_COMPLETED:
	return setPharmaciesData(state, payload);
    case GETTING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR:
	return {loading:false};
    default:
	return state;
	
    }
}
