import { doRequest } from "../../../Helper/network";
import { BindingBasicInfo  } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";
import {ASSIGN_REQUEST_NUMBER} from "../requestNumber";

export const BINDING_BASIC_INFO = "BINDING_BASIC_INFO";
export const BINDING_BASIC_INFO_COMPLETED = "BINDING_BASIC_INFO_COMPLETED";
export const BINDING_BASIC_INFO_COMPLETED_WITH_ERROR = "BINDING_BASIC_INFO_COMPLETED";

export const GENERATING_REQUEST_NUMBER = "GENERATING_REQUEST_NUMBER";
export const GENERATING_REQUEST_NUMBER_COMPLETED = "GENERATING_REQUEST_NUMBER_COMPLETED";
export const GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR = "GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR";


export const GETTING_PHARMACY_DETAILS = "GETTING_PHARMACY_DETAILS";
export const GETTING_PHARMACY_DETAILS_COMPLETED = "GETTING_PHARMACY_DETAILS_COMPLETED";
export const GETTING_PHARMACY_DETAILS_COMPLETED_WITH_ERROR = "GETTING_PHARMACY_DETAILS_COMPLETED_WITH_ERROR";

const intial_state = {};


function setBasicInfo(state, data={}){
    const { data:{ publicAdministrationData={}, distributorBranches=[], distributor=[] , healthDistrictAdministrationsData={}, dispensationTypes={}, pharmacyGovernmentalTypes={}} = {}  } = data;
    if (publicAdministrationData &&  distributorBranches && distributor && healthDistrictAdministrationsData && dispensationTypes) {
	return {publicAdministrationData, distributorBranches, distributor, healthDistrictAdministrationsData, dispensationTypes, pharmacyGovernmentalTypes};
    } else return state;
};



export const getBasicInfo = () => {
  return async dispatch => {
      try {
	  dispatch({ type: BINDING_BASIC_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.GET,
              url: BindingBasicInfo.getBasicInfoURL()
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: BINDING_BASIC_INFO_COMPLETED_WITH_ERROR
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: BINDING_BASIC_INFO_COMPLETED,
		  payload
              });
	  }
      } catch (err) {
	  dispatch({
              type: BINDING_BASIC_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};


export const pharmacyDetails = (data) => {
    return async dispatch => {
	try{
	    dispatch({ type: GETTING_PHARMACY_DETAILS });
	    const response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: BindingBasicInfo.getPharmacyDetailsURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_PHARMACY_DETAILS_COMPLETED_WITH_ERROR,
		    payload
		});
	    }
	    if (status === true) { 
		dispatch({
		    type: GETTING_PHARMACY_DETAILS_COMPLETED,
		    payload
		});
	    }
	    return response;
	    
	} catch(err){
	    console.log("errr------- ", err);
	}
    };
};


export const generateReqNumber = (data) => {
  return async dispatch => {
      try {
	  dispatch({ type: GENERATING_REQUEST_NUMBER });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingBasicInfo.getGenReqNumberURL(),
	      data:data
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) {
	      const {data:{pharmacyBinding:{request_number}}} = payload;
              dispatch({
		  type: ASSIGN_REQUEST_NUMBER,
		  payload: {requestNumber: request_number}
              });
	  }

	  return response;
      } catch (err) {
	  dispatch({
              type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR
	  });
      }
  };
};


export const generateGeneralPharmacyRequestNumber = (data) => {
  return async dispatch => {
      try {
	  dispatch({ type: GENERATING_REQUEST_NUMBER });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingBasicInfo.getGeneralPharmacyRequestNumberURL(),
	      data:data
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) {
	      const {data:{pharmacyBinding:{request_number}}} = payload;
              dispatch({
		  type: ASSIGN_REQUEST_NUMBER,
		  payload: {requestNumber: request_number}
              });
	  }

	  return response;
      } catch (err) {
	  dispatch({
              type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR
	  });
      }
  };
};


export const generateSurgeryRoomRequestNumber = (data) => {
  return async dispatch => {
      try {
	  dispatch({ type: GENERATING_REQUEST_NUMBER });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingBasicInfo.getSurgeryRoomRequestNumberURL(),
	      data:data
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) {
	      const {data:{pharmacyBinding:{request_number}}} = payload;
              dispatch({
		  type: ASSIGN_REQUEST_NUMBER,
		  payload: {requestNumber: request_number}
              });
	  }

	  return response;
      } catch (err) {
	  dispatch({
              type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR
	  });
      }
  };
};


export const getMedicalCenterRequestNumber = (data) => {
  return async dispatch => {
      try {
	  dispatch({ type: GENERATING_REQUEST_NUMBER });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingBasicInfo.getMedicalCenterRequestNumberURL(),
	      data:data
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) {
	      const {data:{pharmacyBinding:{request_number}}} = payload;
              dispatch({
		  type: ASSIGN_REQUEST_NUMBER,
		  payload: {requestNumber: request_number}
              });
	  }

	  return response;
      } catch (err) {
	  dispatch({
              type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR
	  });
      }
  };
};


export const getGovernmentalPharmacyRequestNumber = (data) => {
  return async dispatch => {
      try {
	  dispatch({ type: GENERATING_REQUEST_NUMBER });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingBasicInfo.getGovernmentalPharmacyRequestNumberURL(),
	      data:data
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) {
	      const {data:{pharmacyBinding:{request_number}}} = payload;
              dispatch({
		  type: ASSIGN_REQUEST_NUMBER,
		  payload: {requestNumber: request_number}
              });
	  }

	  return response;
      } catch (err) {
	  dispatch({
              type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR
	  });
      }
  };
};


export const getDispensaryRequestNumber = (data) => {
  return async dispatch => {
      try {
	  dispatch({ type: GENERATING_REQUEST_NUMBER });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingBasicInfo.getDispensaryRequestNumberURL(),
	      data:data
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) {
	      const {data:{pharmacyBinding:{request_number}}} = payload;
              dispatch({
		  type: ASSIGN_REQUEST_NUMBER,
		  payload: {requestNumber: request_number}
              });
	  }

	  return response;
      } catch (err) {
	  dispatch({
              type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR
	  });
      }
  };
};


export const getEgDrugPharmacyRequestNumber = (data) => {
    return async dispatch => {
      try {
	  dispatch({ type: GENERATING_REQUEST_NUMBER });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingBasicInfo.getEgDrugPharmacyRequestNumberURL(),
	      data:data
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) {
	      const {data:{pharmacyBinding:{request_number}}} = payload;
              dispatch({
		  type: ASSIGN_REQUEST_NUMBER,
		  payload: {requestNumber: request_number}
              });
	  }

	  return response;
      } catch (err) {
	  dispatch({
              type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR
	  });
      }
  };
};


export const getDoctorClinicRequestNumber = (data) => {
    
    return async dispatch => {
      try {
	  dispatch({ type: GENERATING_REQUEST_NUMBER });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingBasicInfo.getDoctorClinicRequestNumberURL(),
	      data:data
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) {
	      const {data:{pharmacyBinding:{request_number}}} = payload;
              dispatch({
		  type: ASSIGN_REQUEST_NUMBER,
		  payload: {requestNumber: request_number}
              });
	  }

	  return response;
      } catch (err) {
	  dispatch({
              type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR
	  });
      }
  };
};


export const getPrivateHospitalRequestNumber = (data) => {
    return async dispatch => {
      try {
	  dispatch({ type: GENERATING_REQUEST_NUMBER });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingBasicInfo.getPrivateHospitalRequestNumberURL(),
	      data:data
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) {
	      const {data:{pharmacyBinding:{request_number}}} = payload;
              dispatch({
		  type: ASSIGN_REQUEST_NUMBER,
		  payload: {requestNumber: request_number}
              });
	  }

	  return response;
      } catch (err) {
	  dispatch({
              type: GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export default (state = intial_state, action = {}) => {
  const { type, payload } = action;

  switch (type) {
  case BINDING_BASIC_INFO_COMPLETED: 
      return setBasicInfo(state, payload);
 
  case GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR:
      return state;
      
    default:
      return state;
  }
};
