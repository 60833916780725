import { doRequest } from "../../Helper/network";
import { Members } from "../../Helper/urls";
import { REQUEST_TYPE} from "../../constant";

export const FETCHING_MEMBERS = "FETCHING_MEMBERS";
export const FETCHING_MEMBERS_COMPLETED = "FETCHING_MEMBERS_COMPLETED";
export const FETCHING_MEMBERS_COMPLETED_WITH_ERROR = "FETCHING_MEMBERS_COMPLETED_WITH_ERROR";

export const EDITING_MEMBER = "EDITING_MEMBER";
export const EDITING_MEMBER_COMPLETED = "EDITING_MEMBER_COMPLETED";
export const EDITING_MEMBER_COMPLETED_WITH_ERROR = "EDITING_MEMBER_COMPLETED_WITH_ERROR";


export const ADDING_MEMBER = "ADDING_MEMBER";
export const ADDING_MEMBER_COMPLETED = "ADDING_MEMBER_COMPLETED";
export const ADDING_MEMBER_COMPLETED_WITH_ERROR = "ADDING_MEMBER_COMPLETED_WITH_ERROR";


export const RESIGNING_MEMBER = "RESIGNING_MEMBER";
export const RESIGNING_MEMBER_COMPLETED = "RESIGNING_MEMBER_COMPLETED";
export const RESIGNING_MEMBER_COMPLETED_WITH_ERROR = "RESIGNING_MEMBER_COMPLETED_WITH_ERROR";


export const ACTIVATING_MEMBER = "ACTIVATING_MEMBER";
export const ACTIVATING_MEMBER_COMPLETED = "ACTIVATING_MEMBER_COMPLETED";
export const ACTIVATING_MEMBER_COMPLETED_WITH_ERROR = "ACTIVATING_MEMBER_COMPLETED_WITH_ERROR";

const initial_state = {};


function setMembers(state, data={}){
    const {members={}, dataOrder=[], total} = data;
    if(members){
	return {members:members, dataOrder:dataOrder, loading:false, totalRecords:total};
    }
    else  return state;
}


export const fetchMembers = (data={}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FETCHING_MEMBERS });
      let response = await doRequest({
          method: REQUEST_TYPE.GET,
          url: Members.fetchMembersURL(),
	  params:data
	  
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: FETCHING_MEMBERS_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: FETCHING_MEMBERS_COMPLETED,
          payload: { ...payload.data }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};



export const editMember = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: EDITING_MEMBER });
      let response = await doRequest({
          method: REQUEST_TYPE.POST,
          url: Members.editMemberURL(),
	  data:data
      });
	const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: EDITING_MEMBER_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: EDITING_MEMBER_COMPLETED,
            payload: {message: payload.message}
        });
      }
	return response;
    } catch (err) {
      throw err;
    }
  };
};



export const addMember = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ADDING_MEMBER });
      let response = await doRequest({
          method: REQUEST_TYPE.POST,
          url: Members.addMemberURL(),
	  data:data
      });
	const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: ADDING_MEMBER_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: ADDING_MEMBER_COMPLETED,
            payload: {message: payload.message}
        });
      }
	return response;
    } catch (err) {
      throw err;
    }
  };
};



export const resignMember = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: RESIGNING_MEMBER });
      let response = await doRequest({
          method: REQUEST_TYPE.POST,
          url: Members.resignMemberURL(),
	  data:data
      });
	const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: RESIGNING_MEMBER_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: RESIGNING_MEMBER_COMPLETED,
            payload: {message: payload.message}
        });
      }
	return response;
    } catch (err) {
      throw err;
    }
  };
};



export const activateMember = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ACTIVATING_MEMBER });
      let response = await doRequest({
          method: REQUEST_TYPE.POST,
          url: Members.activateMemberURL(),
	  data:data
      });
	const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: ACTIVATING_MEMBER_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: ACTIVATING_MEMBER_COMPLETED,
            payload: {message: payload.message}
        });
      }
	return response;
    } catch (err) {
      throw err;
    }
  };
};





export default (state = initial_state, action = {}) => {
  const { type, payload } = action;

    switch (type) {
    case FETCHING_MEMBERS:
	return {loading:true};
  case FETCHING_MEMBERS_COMPLETED:
	return setMembers(state, payload);
    case FETCHING_MEMBERS_COMPLETED_WITH_ERROR:
	return {loading:false};
    default:
      return state;
  }
};
