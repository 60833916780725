export const getBranchURL = () => {
    return `/distributors/branches/all`;
};

export const getBranchProfileURL = (branchId) => {
    return `/distributors/branch/${branchId}`;
};

export const getCreateBranchURL = () => {
    return `/distributors/branch/create`;
};

export const getUpdateBranchURL = () => {
    return `/distributors/branch/update`;
};


export const getUpdateBranchStatusURL = () => {
    return `/distributors/branch/updateStatus`;
};
