import { doRequest } from "../../../Helper/network";
import { AdminAuditReport  } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


const GETTING_AUDIT_REPORT = "GETTING_AUDIT_REPORT";
const GETTING_AUDIT_REPORT_COMPLETED = "GETTING_AUDIT_REPORT_COMPLETED";
const GETTING_AUDIT_REPORT_COMPLETED_WITH_ERROR = "GETTING_AUDIT_REPORT_COMPLETED_WITH_ERROR";


const GETTING_AUDIT_REPORT_PROFILE = "GETTING_AUDIT_REPORT_PROFILE";
const GETTING_AUDIT_REPORT_PROFILE_COMPLETED = "GETTING_AUDIT_REPORT_PROFILE_COMPLETED";
const GETTING_AUDIT_REPORT_PROFILE_COMPLETED_WITH_ERROR = "GETTING_AUDIT_REPORT_PROFILE_COMPLETED_WITH_ERROR";


const EDA_CHANGE_AUDIT_STATUS = "EDA_CHANGE_AUDIT_STATUS";
export const EDA_CHANGE_AUDIT_STATUS_COMPLETED = "EDA_CHANGE_AUDIT_STATUS_COMPLETED";
export const EDA_CHANGE_AUDIT_STATUS_COMPLETED_WITH_ERROR = "EDA_CHANGE_AUDIT_STATUS_COMPLETED_WITH_ERROR";


const initial_state={};

function setAuditReportData(state, data = {}) {
    return {data:data, loading:false};
}


function setAuditReportProfileData(state, data={}){
    return {data:data, loading:false};
}


export const getAuditReport = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GETTING_AUDIT_REPORT });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: AdminAuditReport.getAuditReportURL(),
		params: data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_AUDIT_REPORT_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_AUDIT_REPORT_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const getAuditPharmacyProfile = (auditId) => {

    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GETTING_AUDIT_REPORT_PROFILE });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: AdminAuditReport.getAuditReportDetailURL(auditId)
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_AUDIT_REPORT_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_AUDIT_REPORT_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const changePharmacyStatus = (auditId, data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: EDA_CHANGE_AUDIT_STATUS });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: AdminAuditReport.getAuditStatusChangeURL(auditId),
		data: data,
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: EDA_CHANGE_AUDIT_STATUS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error },
		});
	    } else if (status === true) {
		dispatch({
		    type: EDA_CHANGE_AUDIT_STATUS_COMPLETED,
		    payload: payload.data,
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
  };
};



export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case GETTING_AUDIT_REPORT:
	return {loading:true};
    case GETTING_AUDIT_REPORT_COMPLETED:
	return setAuditReportData(state, payload);
    case GETTING_AUDIT_REPORT_COMPLETED_WITH_ERROR:
	return {loading:false};
    case GETTING_AUDIT_REPORT_PROFILE_COMPLETED:
	return setAuditReportProfileData(state, payload);
    default:
	return state;
	
    }
}
