import { doRequest } from "../../../Helper/network";
import { InspectorPharmacyTypeChange as PharmacyTypeChange } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";

export const INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST = "INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST";
export const INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST_COMPLETED = "INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST_COMPLETED";
export const INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST_COMPLETED_WITH_ERROR = "INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST_COMPLETED_WITH_ERROR";


export const INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS = "INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS";
export const INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS_COMPLETED = "INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS_COMPLETED";
export const INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS_COMPLETED_WITH_ERROR = "INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS_COMPLETED_WITH_ERROR";



const INSPECTOR_PHARMACY_CHANGE_NOTIFY_OWNER = "INSPECTOR_PHARMACY_CHANGE_NOTIFY_OWNER";
export const INSPECTOR_PHARMACY_CHANGE_NOTIFY_OWNER_COMPLETED = "INSPECTOR_PHARMACY_CHANGE_NOTIFY_OWNER_COMPLETED";
export const INSPECTOR_PHARMACY_CHANGE_NOTIFY_OWNER_COMPLETED_WITH_ERROR = "INSPECTOR_PHARMACY_CHANGE_NOTIFY_OWNER_COMPLETED_WITH_ERROR";



const UPDATE_REVIEWER_REASON = "UPDATE_REVIEWER_REASON";
export const UPDATE_REVIEWER_REASON_COMPLETED = "UPDATE_REVIEWER_REASON_COMPLETED";
export const UPDATE_REVIEWER_REASON_COMPLETED_WITH_ERROR = "UPDATE_REVIEWER_REASON_COMPLETED_WITH_ERROR";


const initial_state={};


function setPharmacyTypeChangeData(state, payload = {}) {
    const {data = {}, rejectedCount=0, requestCount=0, reviewedCount=0, total=0, allCount=0, } = payload;
    return {pharmacyTypeChange: data, reviewedCount, rejectedCount, requestCount, totalRecords:total, allCount};
};

export const getPharmacyTypeChangeRequests = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: PharmacyTypeChange.getPharmacyTypeChangeURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};


export const getReviewerPharmacyTypeChangeRequests = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: PharmacyTypeChange.getReviewerPharmacyTypeChangeURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};


export const updatePharmacyTypeStatusReviewer = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: PharmacyTypeChange.getReviewerUpdatePharmacyStatusTypeURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		const {status} = data;
		if(status!=="reviewer_approved"){
		    dispatch({
			type: INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS_COMPLETED,
			payload: {message:payload.message}
		    });
		}

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };  
};


export const updatePharmacyTypeStatusDirector = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: PharmacyTypeChange.getDirectorUpdatePharmacyStatusTypeURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };  
};



export const notifyOwnerChangeReq = (pharmacyId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_PHARMACY_CHANGE_NOTIFY_OWNER });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: PharmacyTypeChange.getPharmacyChangeNotifyOwnerURL(pharmacyId),
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_PHARMACY_CHANGE_NOTIFY_OWNER_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_PHARMACY_CHANGE_NOTIFY_OWNER_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };  
};



export const updatePharmacyTypeChangeReviewerReason = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: UPDATE_REVIEWER_REASON });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: PharmacyTypeChange.getUpdateReviewerReasonURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: UPDATE_REVIEWER_REASON_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: UPDATE_REVIEWER_REASON_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };  
};


export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST:
	return {loading:true};
    case INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST_COMPLETED:
	return setPharmacyTypeChangeData(state, payload);
    case INSPECTOR_GETTING_PHARMACY_CHANGE_REQUEST_COMPLETED_WITH_ERROR:
	return {loading:false};

    default:
	return state;
	
    }
}

