export const getPharmacyTypeChangeURL = () => {
    return `/inspectionAuhtority/pharmacyTypeChangeRequest/all`;
};

export const getReviewerPharmacyTypeChangeURL = () => {
    return `/inspectionAuhtority/pharmacyTypeChangeRequest/reviewer`;
};


export const getReviewerUpdatePharmacyStatusTypeURL = () => {
    return `/inspectionAuhtority/pharmacyTypeChangeRequest/update-status/reviewer`;
};



export const getDirectorUpdatePharmacyStatusTypeURL = () => {
    return `/inspectionAuhtority/pharmacyTypeChangeRequest/update-status`;
};


export const getPharmacyChangeNotifyOwnerURL = (pharmacyId) => {
    return `/inspectionAuhtority/pharmacyTypeChangeRequest/notify-owner/${pharmacyId}`;
};



export const getUpdateReviewerReasonURL = () => {
    return `/inspectionAuhtority/pharmacyTypeChangeRequest/reviewer-reason`;
};
