import { doRequest } from "../../../Helper/network";
import { AdminConsolePharmacies as Pharmacies } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const ADMIN_CONSOLE_GETTING_PHARMACIES = "ADMIN_CONSOLE_GETTING_PHARMACIES";
export const ADMIN_CONSOLE_GETTING_PHARMACIES_COMPLETED = "ADMIN_CONSOLE_GETTING_PHARMACIES_COMPLETED";
export const ADMIN_CONSOLE_GETTING_PHARMACIES_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_PHARMACIES_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_GETTING_PHARMACY_PROFILE="ADMIN_CONSOLE_GETTING_PHARMACY_PROFILE";
export const ADMIN_CONSOLE_GETTING_PHARMACY_PROFILE_COMPLETED="ADMIN_CONSOLE_GETTING_PHARMACY_PROFILE_COMPLETED";
export const ADMIN_CONSOLE_GETTING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR="ADMIN_CONSOLE_GETTING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_REASSIGN_PHARMACY = "ADMIN_CONSOLE_REASSIGN_PHARMACY";
export const ADMIN_CONSOLE_REASSIGN_PHARMACY_COMPLETED = "ADMIN_CONSOLE_REASSIGN_PHARMACY_COMPLETED";
export const ADMIN_CONSOLE_REASSIGN_PHARMACY_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_REASSIGN_PHARMACY_COMPLETED_WITH_ERROR";



export const ADMIN_CONSOLE_UPDATE_PHARMACY_STATUS = "ADMIN_CONSOLE_UPDATE_PHARMACY_STATUS";
export const ADMIN_CONSOLE_UPDATE_PHARMACY_STATUS_COMPLETED = "ADMIN_CONSOLE_UPDATE_PHARMACY_STATUS_COMPLETED";
export const ADMIN_CONSOLE_UPDATE_PHARMACY_STATUS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_UPDATE_PHARMACY_STATUS_COMPLETED_WITH_ERROR";



export const ADMIN_CONSOLE_UPDATE_QUOTA = "ADMIN_CONSOLE_UPDATE_QUOTA";
export const ADMIN_CONSOLE_UPDATE_QUOTA_COMPLETED = "ADMIN_CONSOLE_UPDATE_QUOTA_COMPLETED";
export const ADMIN_CONSOLE_UPDATE_QUOTA_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_UPDATE_QUOTA_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_ACTIVATION_USER_UPDATE = "ADMIN_CONSOLE_ACTIVATION_USER_UPDATE";
export const ADMIN_CONSOLE_ACTIVATION_USER_UPDATE_COMPLETED = "ADMIN_CONSOLE_ACTIVATION_USER_UPDATE_COMPLETED";
export const ADMIN_CONSOLE_ACTIVATION_USER_UPDATE_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_ACTIVATION_USER_UPDATE_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_UPDATE_PHARMACY_DETAILS = "ADMIN_CONSOLE_UPDATE_PHARMACY_DETAILS";
export const ADMIN_CONSOLE_UPDATE_PHARMACY_DETAILS_COMPLETED = "ADMIN_CONSOLE_UPDATE_PHARMACY_DETAILS_COMPLETED";
export const ADMIN_CONSOLE_UPDATE_PHARMACY_DETAILS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_UPDATE_PHARMACY_DETAILS_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_CHANGE_OWNERS = "ADMIN_CONSOLE_CHANGE_OWNERS";
export const ADMIN_CONSOLE_CHANGE_OWNERS_COMPLETED = "ADMIN_CONSOLE_CHANGE_OWNERS_COMPLETED";
export const ADMIN_CONSOLE_CHANGE_OWNERS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_CHANGE_OWNERS_COMPLETED_WITH_ERROR";

const initial_state={};


function setPharmaciesData(state, data = {}) {
    const { responseData, dataOrder=[], total=0 } = data;
    if (responseData) {
	return { responseData, dataOrder, total } ;
    } else return state;
}

function setPharmacyProfileData(state, payload={}){
    const {pharmacy={}, manager={}, owner={}, dispensationQuotaDetails={}, pharmacists={}} = payload;
    return {pharmacy, manager, owner, dispensationQuotaDetails, pharmacists};
}


export const getPharmacies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Pharmacies.getAllPharmaciesURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PHARMACIES_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const getPharmacyProfile = (pharmacyId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_PHARMACY_PROFILE});
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Pharmacies.getPharmacyProfileURL(pharmacyId)
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PHARMACY_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
    
};


export const assignInspectorPharmacies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_REASSIGN_PHARMACY});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Pharmacies.getInspectorReassignURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_REASSIGN_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_REASSIGN_PHARMACY_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};




export const assignReviewerPharmacies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_REASSIGN_PHARMACY});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Pharmacies.getReviewerReassignURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_REASSIGN_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_REASSIGN_PHARMACY_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};




export const updatePharmacyStatus = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_UPDATE_PHARMACY_STATUS});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Pharmacies.getUpdatePharmacyStatusURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATE_PHARMACY_STATUS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATE_PHARMACY_STATUS_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const editQuota = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_UPDATE_QUOTA});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Pharmacies.getUpdateQuotaURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATE_QUOTA_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATE_QUOTA_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};

export const updateUserActivation = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_ACTIVATION_USER_UPDATE });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Pharmacies.getUpdateUserActivationURL(),
		data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_ACTIVATION_USER_UPDATE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_ACTIVATION_USER_UPDATE_COMPLETED,
		    payload: {message:  payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};

export const updatePharmacyDetails = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_UPDATE_PHARMACY_DETAILS });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Pharmacies.getUpdatePharmacyDetailsURL(),
		data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATE_PHARMACY_DETAILS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATE_PHARMACY_DETAILS_COMPLETED,
		    payload: {message:  payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};

export const changeOwners = (data, url) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CHANGE_OWNERS});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url,
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CHANGE_OWNERS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CHANGE_OWNERS_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case ADMIN_CONSOLE_GETTING_PHARMACIES:
	return {loading:true};
    case ADMIN_CONSOLE_GETTING_PHARMACIES_COMPLETED:
	return setPharmaciesData(state, payload);
    case ADMIN_CONSOLE_GETTING_PHARMACIES_COMPLETED_WITH_ERROR:
	return {loading:false};
    case ADMIN_CONSOLE_GETTING_PHARMACY_PROFILE:
	return {loading:true};
    case ADMIN_CONSOLE_GETTING_PHARMACY_PROFILE_COMPLETED:
	return setPharmacyProfileData(state, payload);
    case ADMIN_CONSOLE_GETTING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR:
	return {loading:false};
    default:
	return state;
	
    }
}
