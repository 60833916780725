export const getAllPublicAdminURL = () => {
    return `/eda/publicAdministrations`;
};


export const getPublicAdminProfileURL = (publicAdminId) => {
    return `/eda/publicAdministration/${publicAdminId}`;
};



export const getCreatePublicAdminURL = () => {
    return `/eda/publicAdministration/create`;
};

export const getCreateHealthAdminURL = () => {
    return `/eda/publicAdministration/healthAdministration/create`;
};



export const getEditPublicAdminURL = () => {
    return `/eda/publicAdministration/update`;
};



export const getActivatePublicAdminURL = () => {
    return `/eda/publicAdministrations/activate`;
};


export const getDeactivatePublicAdminURL = () => {
    return `/eda/publicAdministrations/suspend`;
};
