export const getAllPharmaciesURL = () => {
  return "/distributors/pharmacies";
};


export const getSearchOptionsURL = () => {
    return "/distributors/pharmacy-list-dropdown-fields";
};


export const getPharmacyProfileURL = (pharmacyId) => {
    return `/distributors/pharmacies/${pharmacyId}`;
};


export const getReassignPharmacyURL = () => {
    return `/distributors/pharmacies`;
};


export const getCityOptionsURL = () => {
    return `/distributors/cities`;
};
