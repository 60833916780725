import { doRequest } from "../../../Helper/network";
import {  BindingPharmacyDetails  } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";

export const SAVING_GENERAL_PHARMACY_GENERAL_INFO = "SAVING_GENERAL_PHARMACY_GENERAL_INFO";
export const SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED = "SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED";
export const SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR = "SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR";


export const SAVING_GENERAL_PHARMACY_OWNER_INFO = "SAVING_GENERAL_PHARMACY_OWNER_INFO";
export const SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED = "SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED";
export const SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR = "SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR";


export const SAVING_GENERAL_PHARMACY_MANAGER_INFO = "SAVING_GENERAL_PHARMACY_MANAGER_INFO";
export const SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED = "SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED";
export const SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR = "SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR";


export const SAVING_GENERAL_PHARMACY_DISPENSATION_INFO = "SAVING_GENERAL_PHARMACY_DISPENSATION_INFO";
export const SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED = "SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED";
export const SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED_WITH_ERROR = "SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED_WITH_ERROR";



export const SAVING_NIGHT_PHARMACY_GENERAL_INFO = "SAVING_NIGHT_PHARMACY_GENERAL_INFO";
export const SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED = "SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED";
export const SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR = "SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR";


export const SAVING_NIGHT_PHARMACY_OWNER_INFO = "SAVING_NIGHT_PHARMACY_OWNER_INFO";
export const SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED = "SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED";
export const SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR = "SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR";


export const SAVING_NIGHT_PHARMACY_MANAGER_INFO = "SAVING_NIGHT_PHARMACY_MANAGER_INFO";
export const SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED = "SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED";
export const SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR = "SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR";


export const SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO = "SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO";
export const SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED = "SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED";
export const SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR = "SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR";


export const SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO = "SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO";
export const SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED = "SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED";
export const SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR = "SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR";


export const SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO = "SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO";
export const SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED = "SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED";
export const SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR = "SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR";



export const GET_BINDING_PHARMACY_INFO = "GET_BINDING_PHARMACY_INFO";
export const GET_BINDING_PHARMACY_INFO_COMPLETED = "GET_BINDING_PHARMACY_INFO_COMPLETED";
export const GET_BINDING_PHARMACY_INFO_COMPLETED_WITH_ERROR = "GET_BINDING_PHARMACY_INFO_COMPLETED_WITH_ERROR";

export const SHOW_NEXT_BUTTON_ERROR = "SHOW_NEXT_BUTTON_ERROR";

const intial_state = {};


export const setBindingData = (payload) => {
    const {data={}} = payload;
    return {...data};
};


export const showError = (data) => {
    return async dispatch => {
	const {msg=""} = data;
	let payload = {};
	payload.error = {};
	payload.error.message = msg;
	dispatch({
	    type: SHOW_NEXT_BUTTON_ERROR,
	    payload
	});
    };
};


export const saveGenPharmacyGenInfo = (data, reviewerRequestNumber) => {
  return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_GENERAL_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url:  reviewerRequestNumber ? BindingPharmacyDetails.getReviewerGenPharmacyInfoURL() : BindingPharmacyDetails.getGenPharmacyGenInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveGenPharmacyOwnerInfo = (data, reviewerRequestNumber) => {
  return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_OWNER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerGenPharmacyOwnerInfoURL() : BindingPharmacyDetails.getGenPharmacyOwnerURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveGenPharmacySecondaryOwnerInfo = (data, reviewerRequestNumber) => {
    
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_OWNER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerGenPharmacyOwnerInfoURL() : BindingPharmacyDetails.getGenPharmacySecondaryOwnerURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
    
};



export const saveGenPharmacyManagerInfo = (data, reviewerRequestNumber) => {
  return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerGenPharmacyManagerInfoURL() : BindingPharmacyDetails.getGenPharmacyManagerURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveGenPharmacyDispensationInfo = (data, reviewerRequestNumber) => {
  return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_DISPENSATION_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerGenPharmacyDispensationInfoURL() :  BindingPharmacyDetails.getGenPharmacyDispensationURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveNightPharmacyGenInfo = (data) => {
  return async dispatch => {
      try {
	  dispatch({ type: SAVING_NIGHT_PHARMACY_GENERAL_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingPharmacyDetails.getNightPharmacyGenInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveNightPharmacyOwnerInfo = (data) => {
  return async dispatch => {
      try {
	  dispatch({ type: SAVING_NIGHT_PHARMACY_OWNER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingPharmacyDetails.getNightPharmacyOwnerURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveNightPharmacyManagerInfo = (data) => {
  return async dispatch => {
      try {
	  dispatch({ type: SAVING_NIGHT_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingPharmacyDetails.getNightPharmacyManagerURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};




export const saveSurgeryRoomPharmacyGenInfo = (data, reviewerRequestNumber) => {
  return async dispatch => {
      try {
	  dispatch({ type: SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerSurgeryRoomPharmacyGenInfoURL() : BindingPharmacyDetails.getSurgeryRoomPharmacyGenInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveSurgeryRoomPharmacyOwnerInfo = (data, reviewerRequestNumber) => {
  return async dispatch => {
      try {
	  dispatch({ type: SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerSurgeryRoomPharmacyOwnerURL()  :BindingPharmacyDetails.getSurgeryRoomPharmacyOwnerURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};

export const saveSurgeryRoomPharmacyManagerInfo = (data, reviewerRequestNumber) => {
  return async dispatch => {
      try {
	  dispatch({ type: SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerSurgeryRoomPharmacyManagerURL() :  BindingPharmacyDetails.getSurgeryRoomPharmacyManagerURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveSurgeryRoomDispensationInfo = (data, reviewerRequestNumber) => {
  return async dispatch => {
      try {
	  dispatch({ type: SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerSurgeryRoomPharmacyDispensationURL() : BindingPharmacyDetails.getSurgeryRoomPharmacyDispensationURL(),
	      data: data	      
	  });
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveMedicalCenterGenInfo =  (data) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_GENERAL_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingPharmacyDetails.getMedicalCenterGenInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
    
};


export const saveMedicalCenterOwnerInfo = (data) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_OWNER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingPharmacyDetails.getMedicalCenterOwnerInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};


export const saveMedicalCenterManagerInfo = (data) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingPharmacyDetails.getMedicalCenterManagerInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
    
};



export const saveMedicalCenterDispensationInfo = (data) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingPharmacyDetails.getMedicalCenterDispensationInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
    
};



export const saveGovernmentalPharmacyGenInfo =  (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_GENERAL_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerGovernmentalPharmacyGenInfoURL()  :BindingPharmacyDetails.getGovernmentalPharmacyGenInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
    
};


export const saveGovernmentalPharmacyOwnerInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_OWNER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingPharmacyDetails.getGovernmentalPharmacyOwnerInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};


export const saveGovernmentalPharmacyManagerInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerGovernmentalPharmacyManagerInfoURL() : BindingPharmacyDetails.getGovernmentalPharmacyManagerInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
    
};



export const saveGovernmentalPharmacyDispensationInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerGovernmentalPharmacyDispensationInfoURL() : BindingPharmacyDetails.getGovernmentalPharmacyDispensationInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
    
};



export const saveDispensaryPharmacyGenInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_GENERAL_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url:  reviewerRequestNumber ? BindingPharmacyDetails.getReviewerDispensaryPharmacyGenInfoURL() : BindingPharmacyDetails.getDispensaryPharmacyGenInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
}; 


export const saveDispensaryPharmacyOwnerInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_OWNER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerDispensaryPharmacyOwnerInfoURL() :BindingPharmacyDetails.getDispensaryPharmacyOwnerInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveDispensaryPharmacyManagerInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerDispensaryPharmacyManagerInfoURL()  :BindingPharmacyDetails.getDispensaryPharmacyManagerInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};




export const saveDispensaryPharmacyDispensationInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerDispensaryPharmacyDispensationInfoURL() :BindingPharmacyDetails.getDispensaryPharmacyDispensationInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveEgDrugPharmacyGenInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_GENERAL_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerEgDrugPharmacyGenInfoURL() : BindingPharmacyDetails.getEgDrugPharmacyGenInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
}; 


export const saveEgDrugPharmacyOwnerInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_OWNER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getEgDrugPharmacyOwnerInfoURL() :BindingPharmacyDetails.getEgDrugPharmacyOwnerInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveEgDrugPharmacyManagerInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerEgDrugPharmacyManagerInfoURL() : BindingPharmacyDetails.getEgDrugPharmacyManagerInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};




export const saveEgDrugPharmacyDispensationInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerEgDrugPharmacyDispensationURL() :BindingPharmacyDetails.getEgDrugPharmacyDispensationURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveDoctorClinicGenInfo = (data) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_GENERAL_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingPharmacyDetails.getDoctorClinicGenInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
}; 


export const saveDoctorClinicOwnerInfo = (data) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_OWNER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingPharmacyDetails.getDoctorClinicOwnerInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const saveDoctorClinicManagerInfo = (data) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingPharmacyDetails.getDoctorClinicManagerInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};




export const saveDoctorClinicDispensationInfo = (data) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingPharmacyDetails.getDoctorClinicDispensationInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const savePrivateHospGenInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_GENERAL_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerPrivateHospitalGenInfoURL() : BindingPharmacyDetails.getPrivateHospitalGenInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
}; 


export const savePrivateHospOwnerInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_OWNER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerPrivateHospitalOwnerInfoURL() : BindingPharmacyDetails.getPrivateHospitalOwnerInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const savePrivateHospManagerInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_MANAGER_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerPrivateHospitalManagerInfoURL() : BindingPharmacyDetails.getPrivateHospitalManagerInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};




export const savePrivateHospDispensationInfo = (data, reviewerRequestNumber) => {
    return async dispatch => {
      try {
	  dispatch({ type: SAVING_GENERAL_PHARMACY_DISPENSATION_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getReviewerPrivateHospitalDispensationURL()  : BindingPharmacyDetails.getPrivateHospitalDispensationURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};




export const getBindingPharmacyInfo = (data, reviewerRequestNumber)  => {
    return async dispatch => {
      try {
	  dispatch({ type: GET_BINDING_PHARMACY_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: reviewerRequestNumber ? BindingPharmacyDetails.getBindingPharmacyReviewerInfoURL() : BindingPharmacyDetails.getBindingPharmacyInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: GET_BINDING_PHARMACY_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: GET_BINDING_PHARMACY_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: GET_BINDING_PHARMACY_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export const getBindingPharmacyReviewerInfo = (data)  => {
    return async dispatch => {
      try {
	  dispatch({ type: GET_BINDING_PHARMACY_INFO });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingPharmacyDetails.getBindingPharmacyReviewerInfoURL(),
	      data: data	      
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: GET_BINDING_PHARMACY_INFO_COMPLETED_WITH_ERROR,
		  payload: { error: payload.error }
              });
	  }
	  if (status === true) { 
              dispatch({
		  type: GET_BINDING_PHARMACY_INFO_COMPLETED,
		  payload
              });
	  }
	  return response;
      } catch (err) {
	  dispatch({
              type: GET_BINDING_PHARMACY_INFO_COMPLETED_WITH_ERROR
	  });
      }
  };
};



export default (state = intial_state, action = {}) => {
  const { type, payload } = action;

  switch (type) { 
  case SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR:
      return state;
  case SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR:
      return state;
  case SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR:
      return state;
  case SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED_WITH_ERROR:
      return state;
  case SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR:
      return state;
  case SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR:
      return state;
  case SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR:
      return state;

  case SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR:
      return state;
  case SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR:
      return state;
  case SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR:
      return state;

  case GET_BINDING_PHARMACY_INFO_COMPLETED:
      return setBindingData(payload);
      
    default:
      return state;
  }
};

