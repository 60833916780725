export const fetchMembersURL = () => {
    return `/distributors/user`;
};


export const editMemberURL = () => {
    return `/distributors/user/edit`;
};


export const addMemberURL = () => {
    return `/distributors/user`;
};


export const resignMemberURL = () => {
    return `/distributors/user/resign`;
};


export const activateMemberURL = () => {
    return `/distributors/user/activate`;
};
