export const getAllPharmaciesURL = () => {
    return `/eda/pharmacies`;
};


export const getPharmacyProfileURL = (pharmacyId) => {
    return `/eda/pharmacies/${pharmacyId}`;
}; 


export const getInspectorReassignURL = () => {
    return `/eda/pharmacies/assign`;
};


export const getReviewerReassignURL = () => {
    return `/eda/pharmacies/assignReviewer`;
};


export const getUpdatePharmacyStatusURL = () => {
    return `/eda/pharmacies/status`;
};


export const getUpdateQuotaURL = () => {
    return `/eda/pharmacy/update-dispensation-quota`;
};

export const getUpdateUserActivationURL = () => {
    return `/eda/pharmacy/update-user-activation`;
};

export const getUpdatePharmacyDetailsURL = () => {
    return `/eda/pharmacies/details`;
};
