export const getEdaDeptURL = () => {
    return `/eda/departments`;
};


export const getEdaDeptProfileURL = (profileId) => {
    return `/eda/departments/${profileId}`;
};


export const getEdaDeptEditProfileURL = () => {
    return `/eda/department/update`;
};


export const getEdaDeptAddProfileURL = () => {
    return `/eda/department/create`;
};



export const getEdaResignURL = () => {
    return `/eda/department/suspend`;

};


export const getEdaActivateURL = () => {
    return `/eda/department/activate`;
    
};
