export const getCurrentAuditFormURL = () => {
  return `/eda/audits/audit-form`;
};

export const getUpdateAuditFormURL = () => {
  return `/eda/audits/update`;
};

export const getCurrentStatusFormURL = () => {
  return `/eda/audits/audit-status`;
};

export const getUpdateAuditStatusURL = () => {
  return `/eda/audits/audit-status/update`;
};


export const getViolationURLWithId = (violationId) => {
  return `/eda/violations/${violationId}`;
};

export const getViolationURL = () => {
  return `/eda/violations`;
};


export const getViolationOffenseURL = (violationId) => {
  return `/eda/violations/${violationId}/offenses`;
};


export const getViolationOffenseURLWitOffenseId = (violationId,offenseId) => {
  return `/eda/violations/${violationId}/offenses/${offenseId}`;
};

export const getAuditPharmacyStatusURLWithId = (auditStatusId) => {
  return `/eda/audits/audit-pharmacy-status/${auditStatusId}`;
};

export const getAuditPharmacyStatusReasonURLWithId = (auditStatusId,reasonId) => {
  return `/eda/audits/audit-pharmacy-status/${auditStatusId}/reasons/${reasonId}`;
};

