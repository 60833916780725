import { doRequest } from "../../../Helper/network";
import { InspectorManagerPharmacies as ManagerPharmacies } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";

export const INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES = "INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES";
export const INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES_COMPLETED = "INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES_COMPLETED";
export const INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES_COMPLETED_WITH_ERROR = "INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES_COMPLETED_WITH_ERROR";


export const INSPECTOR_GETTING_MANAGER_PROFILE = "INSPECTOR_GETTING_MANAGER_PROFILE";
export const INSPECTOR_GETTING_MANAGER_PROFILE_COMPLETED = "INSPECTOR_GETTING_MANAGER_PROFILE_COMPLETED";
export const INSPECTOR_GETTING_MANAGER_PROFILE_COMPLETED_WITH_ERROR = "INSPECTOR_GETTING_MANAGER_PROFILE_COMPLETED_WITH_ERROR";


export const INSPECTOR_UPDATE_MANAGER_PHARMACIES_STATUS = "INSPECTOR_UPDATE_MANAGER_PHARMACIES_STATUS";
export const INSPECTOR_UPDATE_MANAGER_PHARMACIES_STATUS_COMPLETED = "INSPECTOR_UPDATE_MANAGER_PHARMACIES_STATUS_COMPLETED";
export const INSPECTOR_UPDATE_MANAGER_PHARMACIES_STATUS_COMPLETED_WITH_ERROR = "INSPECTOR_UPDATE_MANAGER_PHARMACIES_STATUS_COMPLETED_WITH_ERROR";

const initial_state={};


function setNewManagerPharmaciesData(state, payload = {}) {
    const {data = {}, rejectedCount=0, requestCount=0, reviewedCount=0, allCount=0 } = payload;
    return {newManagerPharmacies: data, reviewedCount, rejectedCount, requestCount, allCount};
};


function setManagerProfile(state, payload={}){
    const {formData={}, managerDocuments={}} = payload;
    return {managerProfile: formData, managerDocuments};
};


export const getNewManagerPharmacies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: ManagerPharmacies.getAllNewManagerPharmaciesURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};

export const getReviewerNewManagerPharmcies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: ManagerPharmacies.getAllReviewerNewManagerPharmaciesURL()
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};


export const getManagerProfile = (managerId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_GETTING_MANAGER_PROFILE });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: ManagerPharmacies.getManagerProfileURL(managerId)
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GETTING_MANAGER_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GETTING_MANAGER_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};


export const updateManagerPharmacyStatus = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_UPDATE_MANAGER_PHARMACIES_STATUS });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: ManagerPharmacies.getUpdateManagerPharmacyURL(),
		data: data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_UPDATE_MANAGER_PHARMACIES_STATUS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_UPDATE_MANAGER_PHARMACIES_STATUS_COMPLETED,
		    payload
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };  
};




export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES:
	return {loading:true};
    case INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES_COMPLETED:
	return setNewManagerPharmaciesData(state, payload);
    case INSPECTOR_GETTING_NEW_MANAGER_PHARMACIES_COMPLETED_WITH_ERROR:
	return {loading:false};

    case INSPECTOR_GETTING_MANAGER_PROFILE:
	return {loading:true};
    case INSPECTOR_GETTING_MANAGER_PROFILE_COMPLETED:
	return setManagerProfile(state, payload);
    case INSPECTOR_GETTING_MANAGER_PROFILE_COMPLETED_WITH_ERROR:
	return {loading:false};
    default:
	return state;
	
    }
}

