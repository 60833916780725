export const getUploadURL = () => {
  return `/eda/uploadSingle`;
};


export const getBindingUploadURL = () => {
    return `/pharmacies/uploadSingle`;
};


export const getReviewerBindingURL = () => {

    return `/inspectionAuhtority/uploadSingle`;
    
};


export const getDistributorUploadURL = () => {
    return `/distributors/uploadSingle`;
};
