export const getSignInURL = () => {
  return "/eda/sign-in";
};

export const getSignOutURL = () => {
  return "/eda/sign-out";
};

export const getForgotPasswordURL = () => {
  return "/eda/forgot-password";
};

export const createNewPasswordURL = () => {
  return "/eda/create-new-password";
};


export const resetNewPasswordURL = () => {
    return "/eda/reset-password";
};


export const verifyOtpURL = () => {
  return "/eda/verify-otp";
};


export const verifyResetOtpURL = () => {
    return "/eda/verify-reset-password-otp";
};


export const resendOtpURL = () => {
  return "/eda/resend-otp";
};


export const resendResetOtpURL = () => {
    return "/eda/resend-reset-password-otp";
};


export const getBasicInfoURL = () => {
  return "/eda/user/get-basic-info";
};

