export const getAllLawsRegulationsURL = () => {
    return `/eda/lawsAndRegulations`;
};


export const getLawsRegulationsDropdownURL = () => {
    return `/eda/lawsAndRegulations/dropdownFields`;
};


export const getCreateLawsRegulationsURL = () => {
    return `/eda/lawsAndRegulation/create`;
};

export const updateLawsRegulationsURL = () => {
    return `/eda/lawsAndRegulations`;
};
