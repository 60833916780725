import { doRequest } from "../../../Helper/network";
import { BindingTrackStatus  } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";

export const GETTING_BINDING_STATUS = "GETTING_BINDING_STATUS";
export const GETTING_BINDING_STATUS_COMPLETED = "GETTING_BINDING_STATUS_COMPLETED";
export const GETTING_BINDING_STATUS_COMPLETED_WITH_ERROR = "GETTING_BINDING_STATUS_COMPLETED_WITH_ERROR";

const intial_state = {};

export const getRequestNumberStatus = (data) => {
  return async dispatch => {
      try {
	  dispatch({ type: GETTING_BINDING_STATUS });
	  const response = await doRequest({
              method: REQUEST_TYPE.POST,
              url: BindingTrackStatus.getRequestNumberStatusURL(),
	      data:data
	  });
	  
	  const { status, payload } = response;
	  if (status === false) {
              dispatch({
		  type: GETTING_BINDING_STATUS_COMPLETED_WITH_ERROR,
		  payload
              });
	  }
	  if (status === true) {
	      const {data:{status}} = payload;
              dispatch({
		  type: GETTING_BINDING_STATUS_COMPLETED,
		  payload: {status:status}
              });
	  }
      } catch (err) {
	  dispatch({
              type: GETTING_BINDING_STATUS_COMPLETED_WITH_ERROR
	  });
      }
  };
};


export default (state = intial_state, action = {}) => {
  const { type, payload } = action;

  switch (type) {
  case GETTING_BINDING_STATUS_COMPLETED:
      return payload.status;
      
    default:
      return state;
  }
};
