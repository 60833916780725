import { doRequest } from "../../../Helper/network";
import { AdminConsoleUsers as Users } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const ADMIN_CONSOLE_GETTING_USERS = "ADMIN_CONSOLE_GETTING_USERS";
export const ADMIN_CONSOLE_GETTING_USERS_COMPLETED = "ADMIN_CONSOLE_GETTING_USERS_COMPLETED";
export const ADMIN_CONSOLE_GETTING_USERS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_USERS_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_GETTING_USER_PROFILE = "ADMIN_CONSOLE_GETTING_USER_PROFILE";
export const ADMIN_CONSOLE_GETTING_USER_PROFILE_COMPLETED = "ADMIN_CONSOLE_GETTING_USER_PROFILE_COMPLETED";
export const ADMIN_CONSOLE_GETTING_USER_PROFILE_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_USER_PROFILE_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_CREATING_USER = "ADMIN_CONSOLE_CREATING_USER";
export const ADMIN_CONSOLE_CREATING_USER_COMPLETED = "ADMIN_CONSOLE_CREATING_USER_COMPLETED";
export const ADMIN_CONSOLE_CREATING_USER_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_CREATING_USER_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_SUSPEND_USER = "ADMIN_CONSOLE_SUSPEND_USER";
export const ADMIN_CONSOLE_SUSPEND_USER_COMPLETED = "ADMIN_CONSOLE_SUSPEND_USER_COMPLETED";
export const ADMIN_CONSOLE_SUSPEND_USER_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_SUSPEND_USER_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_ACTIVATE_USER = "ADMIN_CONSOLE_ACTIVATE_USER";
export const ADMIN_CONSOLE_ACTIVATE_USER_COMPLETED = "ADMIN_CONSOLE_ACTIVATE_USER_COMPLETED";
export const ADMIN_CONSOLE_ACTIVATE_USER_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_ACTIVATE_USER_COMPLETED_WITH_ERROR";


const ADMIN_CONSOLE_REASSIGN_DEPARTMENT = "ADMIN_CONSOLE_REASSIGN_DEPARTMENT";
export const ADMIN_CONSOLE_REASSIGN_DEPARTMENT_COMPLETED = "ADMIN_CONSOLE_REASSIGN_DEPARTMENT_COMPLETED";
export const ADMIN_CONSOLE_REASSIGN_DEPARTMENT_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_REASSIGN_DEPARTMENT_COMPLETED_WITH_ERROR";


const initial_state={};

function setUsersData(state, data = {}) {
    const  {members={}, dataOrder=[], total=0} = data;
    return {members, dataOrder, total};
}


function setUserProfile(state, data={}){
    const {members={}, userFiles={}} = data;
    return {userDetails:members, userFiles};
}


export const getEdaUsers = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_USERS });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Users.getEdaUsersURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_USERS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_USERS_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const getInspectorUsers = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_USERS });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Users.getInspectorUsersURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_USERS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_USERS_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};


export const getEdaUserDetail = (userId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_USER_PROFILE });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Users.getEdaUserDetailsURL(userId),
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_USER_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_USER_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const getInspectorUserDetail = (userId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_USER_PROFILE });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Users.getInspectorUserDetailsURL(userId),
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_USER_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_USER_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const createEdaUser = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_USER });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Users.getEdaUserCreateURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_USER_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_USER_COMPLETED,
		    payload: { message: payload.message }
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};




export const editEdaUser = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_USER });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Users.getEdaUserEditURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_USER_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_USER_COMPLETED,
		    payload: { message: payload.message }
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};





export const createInspectorUser = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_USER });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Users.getInspectorUserCreateURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_USER_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_USER_COMPLETED,
		    payload: { message: payload.message }
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const editInspectorUser = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_USER });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Users.getInspectorUserEditURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_USER_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_USER_COMPLETED,
		    payload: { message: payload.message }
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};

export const editPharmacyUser = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_USER });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Users.getPharmacyUserEditURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_USER_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_USER_COMPLETED,
		    payload: { message: payload.message }
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};




export const resignInspectorMember = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_SUSPEND_USER });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Users.getResignInspectorMemberURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_SUSPEND_USER_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_SUSPEND_USER_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export const resignEdaMember = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_SUSPEND_USER });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Users.getResignEdaMemberURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_SUSPEND_USER_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_SUSPEND_USER_COMPLETED,
		    payload: { message: payload.message }
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const activateInspectorMember = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_ACTIVATE_USER });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Users.getActivateInspectorMemberURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_ACTIVATE_USER_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_ACTIVATE_USER_COMPLETED,
		    payload: { message: payload.message }
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export const activateEdaMember = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_ACTIVATE_USER });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Users.getActivateEdaMemberURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_ACTIVATE_USER_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_ACTIVATE_USER_COMPLETED,
		    payload: {message: payload.message }
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const reassignDepartment = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_REASSIGN_DEPARTMENT });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Users.getReassignDeptURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_REASSIGN_DEPARTMENT_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_REASSIGN_DEPARTMENT_COMPLETED,
		    payload: { message: payload.message }
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case ADMIN_CONSOLE_GETTING_USERS:
	return {loading:true};
    case ADMIN_CONSOLE_GETTING_USERS_COMPLETED:
	return setUsersData(state, payload);
    case ADMIN_CONSOLE_GETTING_USERS_COMPLETED_WITH_ERROR:
	return {loading:false};
    case ADMIN_CONSOLE_GETTING_USER_PROFILE:
	return {loading:true};
    case ADMIN_CONSOLE_GETTING_USER_PROFILE_COMPLETED:
	return setUserProfile(state, payload);
    case ADMIN_CONSOLE_GETTING_USER_PROFILE_COMPLETED_WITH_ERROR:
	return {loading:false};
    default:
	return state;
	
    }
}
