import {GETTING_INITIAL_DATA_COMPLETED, SIGNING_COMPLETED} from "../auth";

const initial_state={cities:[]};

function setCities(state, data={}){
    const { cities} = data;
    if (cities) {
	return {cities:cities};
    } else return state;   
}

export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case GETTING_INITIAL_DATA_COMPLETED:
    case SIGNING_COMPLETED:
	return setCities(state, payload);
    default:
	return state;
	
    }
}
