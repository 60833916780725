export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_NO_AUTH_MODAL = "OPEN_NO_AUTH_MODAL";

const initialState = {
  show: false
};


export const open = (modalType, data) => {
  return dispatch => {
    dispatch({
      type: OPEN_MODAL,
      payload: { modalType, data }
    });
  };
};


export const openNoAuth = (modalType, data) => {
  return dispatch => {
    dispatch({
      type: OPEN_NO_AUTH_MODAL,
      payload: { modalType, data }
    });
  };
};


export const close = () => {
  return dispatch => {
    dispatch({ type: CLOSE_MODAL });
  };
};


export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
    case OPEN_NO_AUTH_MODAL:
    case OPEN_MODAL:
	return {
            show: true,
            modalType: payload.modalType,
            data: payload.data,
	};
	
    case CLOSE_MODAL:
	return {
            show: false
	};
    default:
	return state;
    }
};
