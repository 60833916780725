import {GETTING_INITIAL_DATA_COMPLETED, SIGNING_COMPLETED} from "../auth";

const initial_state={roles:[]};

function setRoles(state, data={}){
    const { roles, profiles } = data;
    if (roles && profiles) {
	return {roles:roles, profiles};
    } else return state;   
}

export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case GETTING_INITIAL_DATA_COMPLETED:
    case SIGNING_COMPLETED:
	return setRoles(state, payload);
    default:
	return state;
	
    }
}
