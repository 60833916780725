export const ASSIGN_LANGUAGE = "ASSIGN_LANGUAGE";

const initialState={};

export const setLanguage = (lang) => {
    return dispatch => {
	dispatch({ type: ASSIGN_LANGUAGE, payload: {lang:lang}});
    };
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

    case ASSIGN_LANGUAGE:
	return payload.lang;
    default:
	return state;
    }
};
