import { doRequest } from "../../../Helper/network";
import { REQUEST_TYPE } from "../../../constant";


export const ADMIN_CONSOLE_GETTING_EGDRUG_PHARMACIES = "ADMIN_CONSOLE_GETTING_EGDRUG_PHARMACIES";
export const ADMIN_CONSOLE_GETTING_EGDRUG_PHARMACIES_COMPLETED = "ADMIN_CONSOLE_GETTING_EGDRUG_PHARMACIES_COMPLETED";
export const ADMIN_CONSOLE_GETTING_EGDRUG_PHARMACIES_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_EGDRUG_PHARMACIES_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_GETTING_EGDRUG_USERS = "ADMIN_CONSOLE_GETTING_EGDRUG_USERS";
export const ADMIN_CONSOLE_GETTING_EGDRUG_USERS_COMPLETED = "ADMIN_CONSOLE_GETTING_EGDRUG_USERS_COMPLETED";
export const ADMIN_CONSOLE_GETTING_EGDRUG_USERS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_EGDRUG_USERS_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_ASSIGNING_OWNER_EGDRUG_PHARMACY = "ADMIN_CONSOLE_ASSIGNING_OWNER_EGDRUG_PHARMACY";
export const ADMIN_CONSOLE_ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED = "ADMIN_CONSOLE_ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED";
export const ADMIN_CONSOLE_ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED_WITH_ERROR";


const initial_state={};


function setPharmaciesData(state, data = {}) {
    const { responseData, dataOrder=[], total=0 } = data;
    if (responseData) {
	return {...state, responseData, dataOrder, total, loading: false } ;
    } else return state;
}


export const getPharmacies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_EGDRUG_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: `/eda/egDrugPharmacies`,
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_EGDRUG_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_EGDRUG_PHARMACIES_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};

export const getEgDrugDistributors = (data={}) => {
    //console.log("data ===== ", data);
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_EGDRUG_USERS });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: `/eda/distributorsEgDrugUsers`,
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_EGDRUG_USERS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_EGDRUG_USERS_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};

export const assignOwnerToEgDrug = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_ASSIGNING_OWNER_EGDRUG_PHARMACY});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: `/eda/egDrugPharmacies/assign`,
		data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };   
};

export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case ADMIN_CONSOLE_GETTING_EGDRUG_PHARMACIES:
	return {...state, responseData: {}, dataOrder: [], total: 0, loading:true};
    case ADMIN_CONSOLE_GETTING_EGDRUG_PHARMACIES_COMPLETED:
	return setPharmaciesData(state, payload);
    case ADMIN_CONSOLE_GETTING_EGDRUG_PHARMACIES_COMPLETED_WITH_ERROR:
	return {...state, loading:false};
	case ADMIN_CONSOLE_GETTING_EGDRUG_USERS_COMPLETED:
	return {...state, ...payload};
    default:
	return state;
	
    }
}
