export const getAllBindingPharmaciesURL = () => {
  return "/inspectionAuhtority/bindingForm";
};

export const getAssignBindingPharmaciesURL = () => {
    return "/inspectionAuhtority/bindingForm/assign";
};

export const getBindingPharmacyProfileURL = (bindingFormId) => {
    return `/inspectionAuhtority/bindingForm/${bindingFormId}`;
};


export const getBindingStatusUpdateURL = () => {
    return "/inspectionAuhtority/bindingForm/update-status";
};


export const getPharmacyReviewerURL = () => {
    return "/inspectionAuhtority/reviewer";
};



export const getEditQuotaURL = () => {
    return "/inspectionAuhtority/bindingForm/update-dispensation-quota";
};



export const getNotifyOwnerFromReviewerURL = (bindingFormId) => {
    //return `/bindingForm/${bindingFormId}/notifyOwner`;
    return `/inspectionAuhtority/bindingForm/${bindingFormId}/notifyOwner`;
};


export const getFetchProductsURL = () => {
    return `/inspectionAuhtority/bindingForm/narcoticDispensation/products`;
};


export const getAddNarcoticDataURL = () => {
    return `/inspectionAuhtority/bindingForm/narcoticDispensation/create`;
};


export const getEditNarcoticProductURL = () => {
    return `/inspectionAuhtority/bindingForm/narcoticDispensation/update`;
};


export const getDeleteNarcoticProductURL = () => {
    return `/inspectionAuhtority/bindingForm/narcoticDispensation/delete`;
};
