export const getInspectorPharmaciesURL = () => {
  return "/inspectionAuhtority/report/inspector-pharmacy";
};


export const getInspectorPharmaciesTransactionURL = () => {
    return `/inspectionAuhtority/report/pharmacies-transactions`;
};


export const getInspectorDistributorBranchesURL = () => {
    return `/inspectionAuhtority/report/shedule-drug-pharmacy`;
};


export const getInspectorAuditVisitURL = () => {
    return `/inspectionAuhtority/report/inspection-visit`;
};


export const getInspectorExpireProductsURL = () => {
    return `/inspectionAuhtority/report/product-expired-pharmacies`;
};

export const getInspectorDistributorPharmaciesURL = () => {
    return `/inspectionAuhtority/report/distributor-pharmacies`;
};
