export const fetchMembersURL = () => {
    return `/inspectionAuhtority/user`;
};


export const editMemberURL = () => {
    return `/inspectionAuhtority/user/update`;
};


export const addMemberURL = () => {
    return `/inspectionAuhtority/user`;
};


export const getMemberDetailsURL = (userId) => {
    return `/inspectionAuhtority/user/${userId}`;
};
