export const getDistributorsURL = () => {
    return `/eda/distributors`;
};

export const getCreateDistributorURL = () => {
    return `/eda/distributor`;
};


export const getEditDistributorURL = () => {
    return `/eda/distributor/update`;
};


export const getDistributorProfileURL = (disId) => {
    return `/eda/distributor/${disId}`;
};



export const getDistributorFormURL = (disId) => {
    return `/eda/distributors/form/${disId}`;
};



export const getCreateBranchURL = () => {
    return `/eda/distributor/branch`;
};


export const getEditBranchURL = () => {
    return `/eda/distributor/branch/update`;
};


export const getUpdateBranchStatusURL = () => {
    return `/eda/distributor/branch/updateStatus`;
};


export const getDistributorSuspendURL = (distributorId) => {
    return `/eda/distributors/suspend/${distributorId}`;
};


export const getDistributorActivateURL = (distributorId) => {
    return `/eda/distributors/activate/${distributorId}`;
};
