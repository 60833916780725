import { doRequest } from "../../../Helper/network";
import { LawsRegulations } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS = "ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS";
export const ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_COMPLETED = "ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_COMPLETED";
export const ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_DROPDOWN = "ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_DROPDOWN";
export const ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_DROPDOWN_COMPLETED = "ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_DROPDOWN_COMPLETED";
export const ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_DROPDOWN_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_DROPDOWN_COMPLETED_WITH_ERROR";



export const ADMIN_CONSOLE_CREATING_LAWS_REGULATIONS = "ADMIN_CONSOLE_CREATING_LAWS_REGULATIONS";
export const ADMIN_CONSOLE_CREATING_LAWS_REGULATIONS_COMPLETED = "ADMIN_CONSOLE_CREATING_LAWS_REGULATIONS_COMPLETED";
export const ADMIN_CONSOLE_CREATING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_CREATING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_DELETING_LAWS_REGULATIONS = "ADMIN_CONSOLE_DELETING_LAWS_REGULATIONS";
export const ADMIN_CONSOLE_DELETING_LAWS_REGULATIONS_COMPLETED = "ADMIN_CONSOLE_DELETING_LAWS_REGULATIONS_COMPLETED";
export const ADMIN_CONSOLE_DELETING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_DELETING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR";

const initial_state={};


function setLawsRegulationData(state, data = {}) {
    const {dataOrder=[], responseData={}, total=0 } = data;
    return {dataOrder, responseData, total};
}


function setLawsRegulationDropdown(state, data={}) {
    return {...state, dropDown:{...data}};
    
    // const {dataOrder=[], responseData={}, total=0 } = data;
    // return {dataOrder, responseData, total};
}


export const fetchLawsRegulations = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: LawsRegulations.getAllLawsRegulationsURL(),
		params:data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const fetchLawsRegulationsDropdown = () => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_DROPDOWN});
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: LawsRegulations.getLawsRegulationsDropdownURL(),
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_DROPDOWN_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_DROPDOWN_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const createLawsRegulation = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_LAWS_REGULATIONS});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: LawsRegulations.getCreateLawsRegulationsURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_LAWS_REGULATIONS_COMPLETED,
		    payload: {message: payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};

export const deleteLawsRegulation = (id) => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: ADMIN_CONSOLE_DELETING_LAWS_REGULATIONS });
			let response = await doRequest({
				method: REQUEST_TYPE.PUT,
				url: LawsRegulations.updateLawsRegulationsURL(),
				data: { id }
			});
			const { status, payload } = response;
			if (status === false) {
				dispatch({
					type: ADMIN_CONSOLE_DELETING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR,
					payload: { error: payload.error }
				});
			} else if (status === true) {
				dispatch({
					type: ADMIN_CONSOLE_DELETING_LAWS_REGULATIONS_COMPLETED,
					payload: { message: payload.message }
				});

			}

			return response;

		} catch (err) {
			throw err;
		}
	};
};



export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS:
	return {loading:true};
    case ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_COMPLETED:
	return setLawsRegulationData(state, payload);
    case ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR:
	return {loading:false};
    case ADMIN_CONSOLE_GETTING_LAWS_REGULATIONS_DROPDOWN_COMPLETED:
	return setLawsRegulationDropdown(state, payload);
	
    default:
	return state;
	
    }
}
