import { doRequest } from "../../../Helper/network";
import { InspectorMembers as Members } from "../../../Helper/urls";
import { REQUEST_TYPE} from "../../../constant";

const INSPECTOR_FETCHING_MEMBERS = "INSPECTOR_FETCHING_MEMBERS";
const INSPECTOR_FETCHING_MEMBERS_COMPLETED = "INSPECTOR_FETCHING_MEMBERS_COMPLETED";
const INSPECTOR_FETCHING_MEMBERS_COMPLETED_WITH_ERROR = "INSPECTOR_FETCHING_MEMBERS_COMPLETED_WITH_ERROR";

export const INSPECTOR_EDITING_MEMBER = "INSPECTOR_EDITING_MEMBER";
export const INSPECTOR_EDITING_MEMBER_COMPLETED = "INSPECTOR_EDITING_MEMBER_COMPLETED";
export const INSPECTOR_EDITING_MEMBER_COMPLETED_WITH_ERROR = "INSPECTOR_EDITING_MEMBER_COMPLETED_WITH_ERROR";


export const INSPECTOR_ADDING_MEMBER = "INSPECTOR_ADDING_MEMBER";
export const INSPECTOR_ADDING_MEMBER_COMPLETED = "INSPECTOR_ADDING_MEMBER_COMPLETED";
export const INSPECTOR_ADDING_MEMBER_COMPLETED_WITH_ERROR = "INSPECTOR_ADDING_MEMBER_COMPLETED_WITH_ERROR";


const INSPECTOR_FETCHING_MEMBER_DETAILS = "INSPECTOR_FETCHING_MEMBER_DETAILS";
const INSPECTOR_FETCHING_MEMBER_DETAILS_COMPLETED = "INSPECTOR_FETCHING_MEMBER_DETAILS_COMPLETED";
const INSPECTOR_FETCHING_MEMBER_DETAILS_COMPLETED_WITH_ERROR = "INSPECTOR_FETCHING_MEMBER_DETAILS_COMPLETED_WITH_ERROR";



const initial_state = {};


function setMembers(state, data={}){
    const {members={}, dataOrder=[], total} = data;
    if(members){
	return {members:members, dataOrder:dataOrder, loading:false, totalRecords:total};
    }
    else  return state;
}


function setMemberDetails(state, data={}){
    const {members={}} = data;
    if(members){
	return {members:members, loading:false};
    }
};


export const fetchMembers = (data={}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSPECTOR_FETCHING_MEMBERS });
      let response = await doRequest({
          method: REQUEST_TYPE.GET,
          url: Members.fetchMembersURL(),
	  params:data
	  
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_FETCHING_MEMBERS_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: INSPECTOR_FETCHING_MEMBERS_COMPLETED,
          payload: { ...payload.data }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};



export const fetchMemberDetails = (userId) => {
    
    return async (dispatch, getState) => {
    try {
      dispatch({ type: INSPECTOR_FETCHING_MEMBER_DETAILS });
      let response = await doRequest({
          method: REQUEST_TYPE.GET,
          url: Members.getMemberDetailsURL(userId)
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_FETCHING_MEMBER_DETAILS_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: INSPECTOR_FETCHING_MEMBER_DETAILS_COMPLETED,
          payload: { ...payload.data }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
    
};




export const editMember = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSPECTOR_EDITING_MEMBER });
      let response = await doRequest({
          method: REQUEST_TYPE.POST,
          url: Members.editMemberURL(),
	  data:data
      });
	const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_EDITING_MEMBER_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: INSPECTOR_EDITING_MEMBER_COMPLETED,
            payload: {message: payload.message}
        });
      }
	return response;
    } catch (err) {
      throw err;
    }
  };
};



export const addMember = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSPECTOR_ADDING_MEMBER });
      let response = await doRequest({
          method: REQUEST_TYPE.POST,
          url: Members.addMemberURL(),
	  data:data
      });
	const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_ADDING_MEMBER_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: INSPECTOR_ADDING_MEMBER_COMPLETED,
            payload: {message: payload.message}
        });
      }
	return response;
    } catch (err) {
      throw err;
    }
  };
};



export default (state = initial_state, action = {}) => {
  const { type, payload } = action;

    switch (type) {
    case INSPECTOR_FETCHING_MEMBERS:
	return {loading:true};
    case INSPECTOR_FETCHING_MEMBERS_COMPLETED:
	return setMembers(state, payload);
    case INSPECTOR_FETCHING_MEMBERS_COMPLETED_WITH_ERROR:
	return {loading:false};
    case INSPECTOR_FETCHING_MEMBER_DETAILS:
	return {loading:true};
    case INSPECTOR_FETCHING_MEMBER_DETAILS_COMPLETED:
	return setMemberDetails(state, payload);
    case INSPECTOR_FETCHING_MEMBER_DETAILS_COMPLETED_WITH_ERROR:
	return {loading:false};
	
    default:
      return state;
  }
};
