import { doRequest } from "../../../Helper/network";
import { AdminNotitifcation } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";
const intialState = {};

export const GETTING_ADMIN_NOTIFICATIONS_COMPLETED =
    "GETTING_ADMIN_NOTIFICATIONS_COMPLETED";
const GETTING_ADMIN_NOTIFICATIONS_COMPLETED_WITH_ERROR =
    "GETTING_ADMIN_NOTIFICATIONS_COMPLETED_WITH_ERROR";
const SETTING_ADMIN_UNSEEN_NOTIFICATION = "SETTING_ADMIN_UNSEEN_NOTIFICATION";

function setNotification(state, data) {
    const { notifications = {} } = data;
    if (notifications) {
        return { ...state, ...notifications };
    } else {
        return state;
    }
}

function setNotificationCount(state, data) {
    const { unseen } = data;
    if (!unseen || unseen !== null) {
        const newstate = { ...state };
        newstate.unseen = unseen;
        return { ...newstate };
    } else {
        return state;
    }
}

export const updateUnseenNotification = unseenCount => {
    try {
        return {
            type: SETTING_ADMIN_UNSEEN_NOTIFICATION,
            payload: { unseen: unseenCount }
        };
    } catch (error) {
        // console.log("error=========>", error);
    }
};
export const getNotification = data => {
    return async dispatch => {
        try {
            const response = await doRequest({
                url: AdminNotitifcation.getNotificationURL(),
                method: REQUEST_TYPE.POST,
                data: { activities: data }
            });
            // console.log('response-------------------- :>> ', response);
            const { status, payload } = response;
            if (status === true) {
                dispatch({
                    type: GETTING_ADMIN_NOTIFICATIONS_COMPLETED,
                    payload: payload.data
                });
            } else if (status === false) {
                dispatch({
                    type: GETTING_ADMIN_NOTIFICATIONS_COMPLETED_WITH_ERROR,
                    payload: payload.error
                });
            }
            return response;
        } catch (err) {
            throw new Error(err);
        }
    };
};

export default (state = intialState, action) => {
    const { type, payload = {} } = action;
    // const {medication} = payload
    switch (type) {
        case SETTING_ADMIN_UNSEEN_NOTIFICATION:
            return setNotificationCount(state, payload);
        case GETTING_ADMIN_NOTIFICATIONS_COMPLETED:
            return setNotification(state, payload);
        default:
            return state;
    }
};
