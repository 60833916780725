export const getUploadAttachmentURL = () => {
    return `/distributors/upload-dispensation`;
};


export const getUploadAttachmentFirstTimeURL = () => {
    return `/distributors/upload-first-time-dispensation`;
};

export const getUploadPharmacyMappingURL = () => {
    return `/distributors/upload-pharmacy-mapping`;
};

export const getUploadUpdatePharmacyMappingURL = () => {
    return `/distributors/upload-update-pharmacy-mapping`;
};


export const getDispensationOrdersURL = () => {
    return `/distributors/dispensation`;
};
