export const getEdaUsersURL = () => {
    return `/eda/user`;
};


export const getInspectorUsersURL = () => {
    return `/eda/users/inspectionAuthority`;
};

export const getEdaUserDetailsURL = (userId) => {
    return `/eda/user/${userId}`;
};


export const getInspectorUserDetailsURL = (userId) => {
    return `/eda/user/inspectionAuthority/${userId}`;
};


export const getEdaUserCreateURL = () => {
    return `/eda/user`;
};


export const getEdaUserEditURL = () => {
    return `/eda/user/update`;
};


export const getInspectorUserCreateURL = () => {
    return `/eda/user/inspectionAuhtority`;
};



export const getInspectorUserEditURL = () => {
    return `/eda/user/inspectionAuhtority/update`;
};

export const getPharmacyUserEditURL = () => {
    return `/eda/user/pharmacy/update`;
};


export const getResignEdaMemberURL = () => {
    return `/eda/user/resign`;
};

export const getResignInspectorMemberURL = () => {
    return `/eda/user/inspectionAuthority/resign`;
};


export const getActivateEdaMemberURL = () => {
    return `/eda/user/activate`;
};

export const getActivateInspectorMemberURL = () => {
    return `/eda/user/inspectionAuthority/activate`;
};


export const getReassignDeptURL = () => {
    return `/eda/user/reassign`;
};
