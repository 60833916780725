import {ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED, ADMIN_CONSOLE_SIGNING_COMPLETED} from "../auth";

const initial_state={};

function setDropDownData(state, data={}){
    const { distributorBranches=[], cities=[], statusTypeDropdownFields={}, inspectionAuthorityMemembers={}, publicAdministrationData={}, edaDepartment=[],offensesCategories=[], pharmacyTypes=[]} = data;
    return {distributorBranches, cities, statusTypeDropdownFields, inspectionAuthorityMemembers, publicAdministrationData, edaDepartment, offensesCategories, pharmacyTypes};
    
}

export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED:
    case ADMIN_CONSOLE_SIGNING_COMPLETED:
	return setDropDownData(state, payload);
    default:
	return state;
	
    }
}
