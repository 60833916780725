export const getAllProductsURL = () => {
    return `/eda/products`;
};

export const getProductProfileURL = (productId) => {
    return `/eda/products/${productId}`;
};


export const getProductBatchDetailsURL = (productId, batchId) => {
    return `/eda/product/${productId}/batches/${batchId}`;
};

export const getSuspendBatchURL = () => {
    return `/eda/productBatches/status`;
};


export const getCreateProductURL = () => {
    return `/eda/product/create`;
};


export const getEditProductURL = () => {
    return `/eda/product/update`;
};


export const getProductDropDownListURL = () => {
    return `/eda/product/dropdowns`;
};


export const getProductStatusUpdateURL = () => {
    return `/eda/product/status`;
};


export const getUpdateRequireTicketURL = () => {
    return `/eda/product/requireTicket`;
};


export const getUpdateRequirePrescriptionURL = () => {
    return `/eda/product/requirePrescrption`;
};

export const getProductMappingsURL = (productId) => {
    return `/eda/product/${productId}/productMappings`;
};

export const getUpdateProductMappingsURL = () => {
    return `/eda/product/updateProductMappings`;
};

export const getProductMappingStatusUpdateURL = () => {
    return `/eda/product/ProductMappings/updateStatus`;
}
