export const getAllCitizensResourcesURL = () => {
    return `/eda/citizensResources`;
};

export const getCreateCitizensResourcesURL = () => {
    return `/eda/citizensResources/create`;
};

export const updateCitizensResourcesURL = () => {
    return `/eda/citizensResources`;
};
