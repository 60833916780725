export const getGenPharmacyGenInfoURL = () => {
  return "/pharmacies/binding/generalPharmacy/generalInfo";
};


export const getReviewerGenPharmacyInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/generalPharmacy/generalInfo";
};


export const getGenPharmacyOwnerURL = () => {
    return "/pharmacies/binding/generalPharmacy/ownerInfo";
};


export const getReviewerGenPharmacyOwnerInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/generalPharmacy/ownerInfo";
};


export const getGenPharmacySecondaryOwnerURL = () => {
    return "/pharmacies/binding/generalPharmacy/ownerInfo";
};



export const getGenPharmacyManagerURL = () => {
    return "/pharmacies/binding/generalPharmacy/managerInfo";
};


export const getReviewerGenPharmacyManagerInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/generalPharmacy/managerInfo";
};


export const getGenPharmacyDispensationURL = () => {
    return "/pharmacies/binding/generalPharmacy/dispensationInfo";
};


export const getReviewerGenPharmacyDispensationInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/generalPharmacy/dispensationInfo";
};


export const getNightPharmacyGenInfoURL = () => {
    return "/pharmacies/register/nightPharmacy/info";
};


export const getNightPharmacyOwnerURL = () => {
    return "/pharmacies/register/nightPharmacy/ownerInfo";
};


export const getNightPharmacyManagerURL = () => {
    return "pharmacies/register/nightPharmacy/managerInfo";
};


export const getSurgeryRoomPharmacyGenInfoURL = () => {
    return "/pharmacies/binding/surgeryRooms/generalInfo";
};


export const getReviewerSurgeryRoomPharmacyGenInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/surgeryRooms/generalInfo";
};


export const getSurgeryRoomPharmacyOwnerURL = () => {
    return "/pharmacies/binding/surgeryRooms/ownerInfo";
};


export const getReviewerSurgeryRoomPharmacyOwnerURL = () => {
    return "/inspectionAuhtority/bindingForm/surgeryRooms/ownerInfo";
};


export const getSurgeryRoomPharmacyManagerURL = () => {
    return "/pharmacies/binding/surgeryRooms/managerInfo";
};


export const getReviewerSurgeryRoomPharmacyManagerURL = () => {
    return "/inspectionAuhtority/bindingForm/surgeryRooms/managerInfo";
};


export const getSurgeryRoomPharmacyDispensationURL = () => {
    return `/pharmacies/binding/surgeryRooms/dispensationInfo`;
};


export const getReviewerSurgeryRoomPharmacyDispensationURL = () => {
    return `/inspectionAuhtority/bindingForm/surgeryRooms/dispensationInfo`;
};


export const getMedicalCenterGenInfoURL = () => {
    return "/pharmacies/binding/medicalCenter/generalInfo";
};


export const getMedicalCenterOwnerInfoURL = () => {
    return "/pharmacies/binding/medicalCenter/ownerInfo";
};


export const getMedicalCenterManagerInfoURL = () => {
    return "/pharmacies/binding/medicalCenter/managerInfo";
};


export const getMedicalCenterDispensationInfoURL = () => {
    return `/pharmacies/binding/medicalCenter/dispensationInfo`;
};



export const getDispensaryPharmacyGenInfoURL = () => {
    return "/pharmacies/binding/despensaryAndClinicalComplex/generalInfo";
};



export const getReviewerDispensaryPharmacyGenInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/despensaryAndClinicalComplex/generalInfo";
};



export const getDispensaryPharmacyOwnerInfoURL = () => {
    return "/pharmacies/binding/despensaryAndClinicalComplex/ownerInfo";
};


export const getReviewerDispensaryPharmacyOwnerInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/despensaryAndClinicalComplex/ownerInfo";
};


export const getDispensaryPharmacyManagerInfoURL = () => {
    return "/pharmacies/binding/despensaryAndClinicalComplex/managerInfo";
};


export const getReviewerDispensaryPharmacyManagerInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/despensaryAndClinicalComplex/managerInfo";
};


export const getDispensaryPharmacyDispensationInfoURL = () => {
    return `/pharmacies/binding/despensaryAndClinicalComplex/dispensationInfo`;
};


export const getReviewerDispensaryPharmacyDispensationInfoURL = () => {
    return `/inspectionAuhtority/bindingForm/despensaryAndClinicalComplex/dispensationInfo`;
};


export const getBindingPharmacyInfoURL = () => {
    return "/pharmacies/getPharmacyBindingForm";
};


export const getBindingPharmacyReviewerInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/toUpdate";
};


export const getEgDrugPharmacyGenInfoURL = () => {
    return "/pharmacies/binding/egdrugPharmacy/generalInfo";
};


export const getReviewerEgDrugPharmacyGenInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/egdrugPharmacy/generalInfo";
};


export const getEgDrugPharmacyOwnerInfoURL = () => {
    return "/pharmacies/binding/egdrugPharmacy/ownerInfo";
};


export const getReviewerEgDrugPharmacyOwnerInfoURL = () => {
    return "";
};


export const getEgDrugPharmacyManagerInfoURL = () => {
    return "/pharmacies/binding/egdrugPharmacy/managerInfo";
};


export const getReviewerEgDrugPharmacyManagerInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/egdrugPharmacy/managerInfo";
};


export const getEgDrugPharmacyDispensationURL = () => {
    return `/pharmacies/binding/egdrugPharmacy/dispensationInfo`;
};


export const getReviewerEgDrugPharmacyDispensationURL = () => {
    return `/inspectionAuhtority/bindingForm/egdrugPharmacy/dispensationInfo`;
};


export const getDoctorClinicGenInfoURL = () => {
    return "/pharmacies/binding/doctorClinic/generalInfo";
};

export const getDoctorClinicOwnerInfoURL = () => {
    return "/pharmacies/binding/doctorClinic/ownerInfo";
};

export const getDoctorClinicManagerInfoURL = () => {
    return "/pharmacies/binding/doctorClinic/managerInfo";
};


export const getDoctorClinicDispensationInfoURL = () => {
    return `/pharmacies/binding/doctorClinic/dispensationInfo`;
};


export const getPrivateHospitalGenInfoURL = () => {
    return "/pharmacies/binding/privateHospital/generalInfo";
};


export const getReviewerPrivateHospitalGenInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/privateHospital/generalInfo";
};


export const getPrivateHospitalOwnerInfoURL = () => {
    return "/pharmacies/binding/privateHospital/ownerInfo";
};


export const getReviewerPrivateHospitalOwnerInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/privateHospital/ownerInfo";
};


export const getPrivateHospitalManagerInfoURL = () => {
    return "/pharmacies/binding/privateHospital/managerInfo";
};


export const getReviewerPrivateHospitalManagerInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/privateHospital/managerInfo";
};


export const getPrivateHospitalDispensationURL = () => {
    return `/pharmacies/binding/privateHospital/dispensationInfo`;
};


export const getReviewerPrivateHospitalDispensationURL = () => {
    return `/inspectionAuhtority/bindingForm/privateHospital/dispensationInfo`;
};


export const getGovernmentalPharmacyGenInfoURL = () => {
    return "/pharmacies/binding/governmentalHospital/generalInfo";
};


export const getReviewerGovernmentalPharmacyGenInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/governmentalHospital/generalInfo";
};


export const getGovernmentalPharmacyOwnerInfoURL = () => {
    return "/pharmacies/binding/governmentalHospital/ownerInfo";
};


export const getReviewerGovernmentalPharmacyOwnerInfoURL = () => {
    return "";
};


export const getGovernmentalPharmacyManagerInfoURL = () => {
    return "/pharmacies/binding/governmentalHospital/managerInfo";
};


export const getReviewerGovernmentalPharmacyManagerInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/governmentalHospital/managerInfo";
};


export const getGovernmentalPharmacyDispensationInfoURL = () => {
    return `/pharmacies/binding/governmentalHospital/dispensationInfo`;
};


export const getReviewerGovernmentalPharmacyDispensationInfoURL = () => {
    return "/inspectionAuhtority/bindingForm/governmentalHospital/dispensationInfo";
};
