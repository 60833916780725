import { doRequest } from "../../../Helper/network";
import { InspectorDispensationPharmacies as DispensationPharmacies } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const INSPECTOR_GETTING_DISPENSATION_FORMS = "INSPECTOR_GETTING_DISPENSATION_FORMS";
export const INSPECTOR_GETTING_DISPENSATION_FORMS_COMPLETED = "INSPECTOR_GETTING_DISPENSATION_FORMS_COMPLETED";
export const INSPECTOR_GETTING_DISPENSATION_FORMS_COMPLETED_WITH_ERROR = "INSPECTOR_GETTING_DISPENSATION_FORMS_COMPLETED_WITH_ERROR";


export const INSPECTOR_GET_DISPENSATION_PHARMACY_PROFILE = "INSPECTOR_GET_DISPENSATION_PHARMACY_PROFILE";
export const INSPECTOR_GET_DISPENSATION_PHARMACY_PROFILE_COMPLETED = "INSPECTOR_GET_DISPENSATION_PHARMACY_PROFILE_COMPLETED";
export const INSPECTOR_GET_DISPENSATION_PHARMACY_PROFILE_COMPLETED_WITH_ERROR = "INSPECTOR_GET_DISPENSATION_PHARMACY_PROFILE_COMPLETED_WITH_ERROR";


export const INSPECTOR_UPDATING_STATUS_DISPENSATION_PHARMACIES = "INSPECTOR_UPDATING_STATUS_DISPENSATION_PHARMACIES";
export const INSPECTOR_UPDATING_STATUS_DISPENSATION_PHARMACIES_COMPLETED = "INSPECTOR_UPDATING_STATUS_DISPENSATION_PHARMACIES_COMPLETED";
export const INSPECTOR_UPDATING_STATUS_DISPENSATION_PHARMACIES_COMPLETED_WITH_ERROR = "INSPECTOR_UPDATING_STATUS_DISPENSATION_PHARMACIES_COMPLETED_WITH_ERROR";


export const INSPECTOR_ASSIGNING_DISPENSATION_PHARMACIES = "INSPECTOR_ASSIGNING_DISPENSATION_PHARMACIES";
export const INSPECTOR_ASSIGNING_DISPENSATION_PHARMACIES_COMPLETED = "INSPECTOR_ASSIGNING_DISPENSATION_PHARMACIES_COMPLETED";
export const INSPECTOR_ASSIGNING_DISPENSATION_PHARMACIES_COMPLETED_WITH_ERROR = "INSPECTOR_ASSIGNING_DISPENSATION_PHARMACIES_COMPLETED_WITH_ERROR";

const initial_state={};


function setDispensationPharmaciesData(state, payload = {}) {
    const {data={}, rejectedCount=0, requestCount=0, reviewedCount=0, totalRecords=0, allCount=0 } = payload;
    if (data) {
	return {...state, data, rejectedCount, reviewedCount, requestCount, loading:false, totalRecords, allCount};
    } else return state;
}


function setDispensationPharmacyProfileData(state, payload={}){
    const {pharmacy={}, manager={}, owner={}, dispensationQuotaDetails={}} = payload;
    return {pharmacy, manager, owner, dispensationQuotaDetails};
}


export const getDispensationPharmacies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_GETTING_DISPENSATION_FORMS });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: DispensationPharmacies.getAllDispensationPharmaciesURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GETTING_DISPENSATION_FORMS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GETTING_DISPENSATION_FORMS_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};


export const getReviewerrDispensationPharmcies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_GETTING_DISPENSATION_FORMS });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: DispensationPharmacies.getReviewerDispensationPharmaciesURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GETTING_DISPENSATION_FORMS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GETTING_DISPENSATION_FORMS_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};


export const getDispensationPharmacyProfile = (bindingFormId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_GET_DISPENSATION_PHARMACY_PROFILE});
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: DispensationPharmacies.getDispensationPharmacyProfileURL(bindingFormId)
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GET_DISPENSATION_PHARMACY_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GET_DISPENSATION_PHARMACY_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
    
};


export const assignReviewerDispensationPharmacy = (data) => {
    return async (dispatch, getState) => {
	try{
	    dispatch({ type: INSPECTOR_ASSIGNING_DISPENSATION_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: DispensationPharmacies.getDispensationPharmacyAssignReviewerURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_ASSIGNING_DISPENSATION_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_ASSIGNING_DISPENSATION_PHARMACIES_COMPLETED,
		    payload
		});
	    }
	    return response;
	}
	catch(err){
	    throw err;
	}
    };
};


export const updateDispensationPharmacyStatus = (data) => {
    return async (dispatch, getState) => {
	try{
	    dispatch({ type: INSPECTOR_UPDATING_STATUS_DISPENSATION_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: DispensationPharmacies.getDispensationPharmacyStatusUpdateURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_UPDATING_STATUS_DISPENSATION_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_UPDATING_STATUS_DISPENSATION_PHARMACIES_COMPLETED,
		    payload: {message:payload.message}
		});
	    }
	    return response;
	}
	catch(err){
	    throw err;
	}
    };
};


export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case INSPECTOR_GETTING_DISPENSATION_FORMS:
	return {loading:true, ...state};
    case INSPECTOR_GETTING_DISPENSATION_FORMS_COMPLETED:
	return setDispensationPharmaciesData(state, payload);
    case INSPECTOR_GETTING_DISPENSATION_FORMS_COMPLETED_WITH_ERROR:
	return {loading:false};
    case INSPECTOR_GET_DISPENSATION_PHARMACY_PROFILE_COMPLETED:
	return setDispensationPharmacyProfileData(state, payload);
    default:
	return state;
	
    }
}
