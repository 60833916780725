export const getAllExpiredNightPharmacyURL = () => {
    return `/inspectionAuhtority/expiredNightPharmacy/all`;
};

export const getAllExpiredNightPharmacyReviewerChangeURL = () => {
    return `/inspectionAuhtority/expiredNightPharmacy/typeChange`;
};

export const getAllExpiredNightPharmacyReviewerURL = () => {
    return `/inspectionAuhtority/expiredNightPharmacy/reviewer`;
};


export const getChangePharmacyTypeURL = () => {
    return `/inspectionAuhtority/expiredNightPharmacy/submitTypeChange`;
};

export const getApproveChangePharmacyTypeURL = () => {
    return `/inspectionAuhtority/expiredNightPharmacy/approveTypeChange`;
};

export const getNotifyOwnerURL = () => {
    return `/inspectionAuhtority/expiredNightPharmacy/notifyOwner`;
};


export const getRenewNightPharmacyURL = () => {
    return `/inspectionAuhtority/expiredNightPharmacy/renew`;
};
