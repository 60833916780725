import { doRequest } from "../../../Helper/network";
import { InspectorAuth as Auth } from "../../../Helper/urls";
import { REQUEST_TYPE, path, INSPECTION_DEPARTMENT_PATH } from "../../../constant";

export const INSPECTOR_SIGNING = "INSPECTOR_SIGNING";
export const INSPECTOR_SIGNING_COMPLETED = "INSPECTOR_SIGNING_COMPLETED";
export const INSPECTOR_SIGNING_COMPLETED_WITH_ERROR = "INSPECTOR_SIGNING_COMPLETED_WITH_ERROR";

export const INSPECTOR_CREATING_NEW_PASSWORD = "INSPECTOR_CREATING_NEW_PASSWORD";
export const INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED =
  "INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED";
export const INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR =
  "INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR";

export const INSPECTOR_VERIFYING_OTP = "INSPECTOR_VERIFYING_OTP";
export const INSPECTOR_VERIFYING_OTP_COMPLETED = "INSPECTOR_VERIFYING_OTP_COMPLETED";
export const INSPECTOR_VERIFYING_OTP_COMPLETED_WITH_ERROR =
  "INSPECTOR_VERIFYING_OTP_COMPLETED_WITH_ERROR";

export const INSPECTOR_RESENDING_OTP = "INSPECTOR_RESENDING_OTP";
export const INSPECTOR_RESENDING_OTP_COMPLETED = "INSPECTOR_RESENDING_OTP_COMPLETED";
export const INSPECTOR_RESENDING_OTP_COMPLETED_WITH_ERROR =
  "INSPECTOR_RESENDING_OTP_COMPLETED_WITH_ERROR";

export const INSPECTOR_FORGET_PASSWORD = "INSPECTOR_FORGET_PASSWORD";
export const INSPECTOR_FORGET_PASSWORD_COMPLETED = "INSPECTOR_FORGET_PASSWORD_COMPLETED";
export const INSPECTOR_FORGET_PASSWORD_COMPLETED_WITH_ERROR =
  "INSPECTOR_FORGET_PASSWORD_COMPLETED_WITH_ERROR";

export const INSPECTOR_GETTING_INITIAL_DATA = "INSPECTOR_GETTING_INITIAL_DATA";
export const INSPECTOR_GETTING_INITIAL_DATA_COMPLETED = "INSPECTOR_GETTING_INITIAL_DATA_COMPLETED";
export const INSPECTOR_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR =
    "INSPECTOR_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR";



const INSPECTOR_RESETTING_NEW_PASSWORD = "INSPECTOR_RESETTING_NEW_PASSWORD";
export const INSPECTOR_RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR = "INSPECTOR_RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR";
export const INSPECTOR_RESETTING_NEW_PASSWORD_COMPLETED = "INSPECTOR_RESETTING_NEW_PASSWORD_COMPLETED";

const INSPECTOR_VERIFYING_RESET_OTP = "INSPECTOR_VERIFYING_RESET_OTP";
export const INSPECTOR_VERIFYING_RESET_OTP_COMPLETED = "INSPECTOR_VERIFYING_RESET_OTP_COMPLETED";
export const INSPECTOR_VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR = "INSPECTOR_VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR";

const INSPECTOR_RESENDING_RESET_OTP = "INSPECTOR_RESENDING_RESET_OTP";
export const INSPECTOR_RESENDING_RESET_OTP_COMPLETED = "INSPECTOR_RESENDING_RESET_OTP_COMPLETED";
export const INSPECTOR_RESENDING_RESET_OTP_COMPLETED_WITH_ERROR = "INSPECTOR_RESENDING_RESET_OTP_COMPLETED_WITH_ERROR";



export const INSPECTOR_SIGNING_OUT = "INSPECTOR_SIGNING_OUT";
export const INSPECTOR_SIGNING_OUT_COMPLETED = "INSPECTOR_SIGNING_OUT_COMPLETED";


export const INSPECTOR_RESET_UNAUTHORIZED_ERROR = "INSPECTOR_RESET_UNAUTHORIZED_ERROR";

export const INSPECTOR_RESET_ERROR = "INSPECTOR_RESET_ERROR";


export const INSPECTOR_EMPTY_AUTH_REDIRECTION="INSPECTOR_EMPTY_AUTH_REDIRECTION";

const intial_state = {};


export const resetUnauthorizedError = state => {
    return { type: INSPECTOR_RESET_UNAUTHORIZED_ERROR };
};


export const clearError = () => {
    return { type: INSPECTOR_RESET_ERROR };
};


export const emptyAuthRedirection = () => {
    return dispatch => {
	dispatch({type:INSPECTOR_EMPTY_AUTH_REDIRECTION});
    };
};

export const signIn = (data = {}, lastUrl) => {
  return async (dispatch, getState) => {
    try {
	const { InspectorAuth:auth = {} } = getState();
      dispatch({ type: INSPECTOR_SIGNING });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.getSignInURL()
      });
	const { status, payload } = response;
	
      if (status === false) {
        dispatch({
          type: INSPECTOR_SIGNING_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
	  const {data:{authUser} = {}}  = payload;
	  const {data:{users={}, branches=[], cities=[], user_profiles, user_roles=[], members={}, publicAdministrationData={},  inspectorData={}, distributorBranches={}, statusTypeDropdownFields={}, healthDistrictAdministrationsData={}, user_role, authUserGovernorateId, pharmacyTypes=[]} = {}} = payload;
	  const is_verified = users[authUser].is_verified;
          let redirectTo = "";
          if (is_verified) {
	      if (lastUrl && auth.unauthorizedError !== undefined) {
		  redirectTo =  INSPECTION_DEPARTMENT_PATH + lastUrl;
              }
	      else{
		  redirectTo = path.INSPECTOR.PHARMACY_LIST;
	      }
        } else {
          redirectTo = path.INSPECTOR.CREATE_PASSWORD;
        }
        dispatch({
            type: INSPECTOR_SIGNING_COMPLETED,
            payload: { ...users[authUser], authRedirection: redirectTo, authUser:authUser, users:users, branches:branches, cities:cities, roles:user_profiles, members, publicAdministrationData, inspectorData, distributorBranches, statusTypeDropdownFields, sub_roles:user_roles, healthDistrictAdministrationsData, user_role, authUserGovernorateId, pharmacyTypes}
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const createPassword = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSPECTOR_CREATING_NEW_PASSWORD });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.createNewPasswordURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        let redirectTo = path.INSPECTOR.OTP;
        dispatch({
          type: INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED,
          payload: { ...payload.data, authRedirection: redirectTo }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const verifyOTP = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSPECTOR_VERIFYING_OTP });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.verifyOtpURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_VERIFYING_OTP_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        let redirectTo = path.INSPECTOR.SIGN_IN;

        dispatch({
          type: INSPECTOR_VERIFYING_OTP_COMPLETED,
          payload: { ...payload.data, authRedirection: redirectTo }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const resendOTP = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSPECTOR_RESENDING_OTP });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.resendOtpURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_RESENDING_OTP_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: INSPECTOR_RESENDING_OTP_COMPLETED,
          payload: {message:payload.message}
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const forgotPassword = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSPECTOR_FORGET_PASSWORD });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.getForgotPasswordURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_FORGET_PASSWORD_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        let redirectTo = path.INSPECTOR.CREATE_PASSWORD;
        dispatch({
          type: INSPECTOR_FORGET_PASSWORD_COMPLETED,
          payload: { ...payload.data, authRedirection: redirectTo }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const verifyForgotPasswordOTP = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSPECTOR_VERIFYING_OTP });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.verifyOtpForgotPasswordURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_VERIFYING_OTP_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        let redirectTo = path.INSPECTOR.SIGN_IN;

        dispatch({
          type: INSPECTOR_VERIFYING_OTP_COMPLETED,
          payload: { ...payload.data, authRedirection: redirectTo }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const resendForgotPasswordOTP = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSPECTOR_RESENDING_OTP });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.resendOtpForgotPasswordURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_RESENDING_OTP_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: INSPECTOR_RESENDING_OTP_COMPLETED,
            payload: {message:payload.message}
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const onInspectorAppStart = () => {
  return async dispatch => {
      try {
	  dispatch({ type: INSPECTOR_GETTING_INITIAL_DATA });
      const response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Auth.getBasicInfoURL()
      });
      
	const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR,
          payload: {
          }
        });
      }
	if (status === true) {
	    const {data:{authUser} = {}}  = payload;
	    const {data:{users={}, branches=[], cities=[], user_profiles=[], members={}, publicAdministrationData={}, inspectorData={}, statusTypeDropdownFields={}, distributorBranches={}, user_roles=[], healthDistrictAdministrationsData={}, user_role, authUserGovernorateId, pharmacyTypes=[]} = {}} = payload;
	  const redirectTo = "";
        dispatch({
          type: INSPECTOR_GETTING_INITIAL_DATA_COMPLETED,
          payload: {
              authenticatedUser: authUser,
	      authUser:authUser,
              authRedirection: redirectTo,
	      users:users,
	      branches,
	      cities,
	      roles:user_profiles,
	      sub_roles:user_roles,
	      members,
	      publicAdministrationData,
	      inspectorData,
	      statusTypeDropdownFields,
	      distributorBranches,
	      healthDistrictAdministrationsData,
	      user_role,
	      authUserGovernorateId,
        pharmacyTypes
          }
        });
      }
    } catch (err) {
      dispatch({
        type: INSPECTOR_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR
      });
    }
  };
};


export const resetPassword = (data={}) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_RESETTING_NEW_PASSWORD });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		data: data,
		url: Auth.resetNewPasswordURL()
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
    };
};


export const verifyResetOtp = (data={}) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_VERIFYING_RESET_OTP });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		data: data,
		url: Auth.verifyResetOtpURL()
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_VERIFYING_RESET_OTP_COMPLETED,
		    payload: { message:payload.message}
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
    };
};


export const resendResetOtp = (data={}) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_RESENDING_RESET_OTP });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		data: data,
		url: Auth.resendResetOtpURL()
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_RESENDING_RESET_OTP_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_RESENDING_RESET_OTP_COMPLETED,
		    payload: {message:payload.message}
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
    };
};


export const logOut = (data = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: INSPECTOR_SIGNING_OUT });
      let response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Auth.getSignOutURL()
      });

      const { status } = response;
      if (status === true) {
        dispatch({ type: INSPECTOR_SIGNING_OUT_COMPLETED });
      } 
      return status;
    } catch (err) {
      throw err;
    }
  };
};


function unsetUnathorizedError(state) {
  const { unauthorizedError } = state;
  if (unauthorizedError) {
    return {
      ...state,
      unauthorizedError: false
    };
  }
  return state;
}


export default (state = intial_state, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case INSPECTOR_GETTING_INITIAL_DATA_COMPLETED: {
      return {
        authenticated: true,
        authenticated_user: payload.authenticatedUser,
        authRedirection: payload.authRedirection,
          authUser:payload.authUser,
	  user_role:payload.user_role,
	  authUserGovernorateId:payload.authUserGovernorateId
	  
      };
    }
    case INSPECTOR_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR:
      return {
        authenticated: false,
        unauthorizedError: true
      };

    case INSPECTOR_SIGNING_COMPLETED_WITH_ERROR:
      return {
        authenticated: false,
        error: payload.error
      };

    case INSPECTOR_SIGNING_COMPLETED:
      return {
          ...state,
          authenticated: payload.is_verified,
          is_verified: payload.is_verified,
          user_id: payload.id,
          authRedirection: payload.authRedirection,
	  authUser:payload.authUser,
	  user_role:payload.user_role,
	  authUserGovernorateId:payload.authUserGovernorateId
      };

    case INSPECTOR_SIGNING_OUT_COMPLETED:
      return {
        authenticated: false,
        authRedirection: path.INSPECTOR.SIGN_IN
      };
      
  case INSPECTOR_RESET_ERROR:
      return {
          ...state,
          error: null
      };

  case INSPECTOR_RESET_UNAUTHORIZED_ERROR:
      return unsetUnathorizedError(state);

    case INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED:
      return {
        ...state,
        authRedirection: payload.authRedirection
      };
    case INSPECTOR_VERIFYING_OTP_COMPLETED:
      return {
        ...state,
        authRedirection: payload.authRedirection
      };
    case INSPECTOR_FORGET_PASSWORD_COMPLETED:
      return {
        ...state,
        user_id: payload.id,
        authRedirection: payload.authRedirection
      };

  case INSPECTOR_FORGET_PASSWORD_COMPLETED_WITH_ERROR:
      return {
	  ...state,
          authenticated: false,
          error: payload.error
      };

  case INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR:
      return {
	  ...state,
          authenticated: false,
          error: payload.error
      };

  case INSPECTOR_VERIFYING_OTP_COMPLETED_WITH_ERROR:
      return {
	  ...state,
          authenticated: false,
          error: payload.error
      };

  case INSPECTOR_EMPTY_AUTH_REDIRECTION:
      return {
	  ...state,
	  authRedirection:""
      };
      
    default:
      return state;
  }
};
