import { doRequest } from "../../../Helper/network";
import { InspectorGovEntities as GovEntities } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";

const FETCHING_GOV_ENTITIES_PHARMACY = "FETCHING_GOV_ENTITIES_PHARMACY";
const FETCHING_GOV_ENTITIES_PHARMACY_COMPLETED = "FETCHING_GOV_ENTITIES_PHARMACY_COMPLETED";
const FETCHING_GOV_ENTITIES_PHARMACY_COMPLETED_WITH_ERROR = "FETCHING_GOV_ENTITIES_PHARMACY_COMPLETED_WITH_ERROR";

const ASSIGNING_INSPECTOR_OWNER_PHARMACY = "ASSIGNING_INSPECTOR_OWNER_PHARMACY";
export const ASSIGNING_INSPECTOR_OWNER_PHARMACY_COMPLETED = "ASSIGNING_INSPECTOR_OWNER_PHARMACY_COMPLETED";
export const ASSIGNING_INSPECTOR_OWNER_PHARMACY_COMPLETED_WITH_ERROR = "ASSIGNING_INSPECTOR_OWNER_PHARMACY_COMPLETED_WITH_ERROR";


const initial_state={};


function setGovEntitiesData(state, payload){
    const {dataOrder=[], responseData={}, total=0} = payload;
    return {responseData, dataOrder, totalRecords:total };
};



export const getGovEntitesPharmacies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: FETCHING_GOV_ENTITIES_PHARMACY });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: data.adminURL ? data.adminURL : GovEntities.getGovEntitesPharmacyURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: FETCHING_GOV_ENTITIES_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: FETCHING_GOV_ENTITIES_PHARMACY_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};


export const assignInspectorOwner = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ASSIGNING_INSPECTOR_OWNER_PHARMACY});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: data.adminURL ? data.adminURL : GovEntities.getInspectorOwnerAssignURL(),
		data:{...data, adminURL: undefined}
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ASSIGNING_INSPECTOR_OWNER_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ASSIGNING_INSPECTOR_OWNER_PHARMACY_COMPLETED,
		    payload: {message: payload.message}
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };   
};



export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case FETCHING_GOV_ENTITIES_PHARMACY:
	return {loading:true};
    case FETCHING_GOV_ENTITIES_PHARMACY_COMPLETED:
	return setGovEntitiesData(state, payload);
    case FETCHING_GOV_ENTITIES_PHARMACY_COMPLETED_WITH_ERROR:
	return {loading:false};
    default:
	return state;
	
    }
}
