import { doRequest } from "../../../Helper/network";
import { EdaDept } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const ADMIN_CONSOLE_GETTING_EDA_DEPT = "ADMIN_CONSOLE_GETTING_EDA_DEPT";
export const ADMIN_CONSOLE_GETTING_EDA_DEPT_COMPLETED = "ADMIN_CONSOLE_GETTING_EDA_DEPT_COMPLETED";
export const ADMIN_CONSOLE_GETTING_EDA_DEPT_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_EDA_DEPT_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_GETTING_EDA_DEPT_PROFILE = "ADMIN_CONSOLE_GETTING_EDA_DEPT_PROFILE";
export const ADMIN_CONSOLE_GETTING_EDA_DEPT_PROFILE_COMPLETED = "ADMIN_CONSOLE_GETTING_EDA_DEPT_PROFILE_COMPLETED";
export const ADMIN_CONSOLE_GETTING_EDA_DEPT_PROFILE_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_EDA_DEPT_PROFILE_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_UPDATING_EDA_DEPT = "ADMIN_CONSOLE_UPDATING_EDA_DEPT";
export const ADMIN_CONSOLE_UPDATING_EDA_DEPT_COMPLETED = "ADMIN_CONSOLE_UPDATING_EDA_DEPT_COMPLETED";
export const ADMIN_CONSOLE_UPDATING_EDA_DEPT_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_UPDATING_EDA_DEPT_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_ADDING_EDA_DEPT = "ADMIN_CONSOLE_ADDING_EDA_DEPT";
export const ADMIN_CONSOLE_ADDING_EDA_DEPT_COMPLETED = "ADMIN_CONSOLE_ADDING_EDA_DEPT_COMPLETED";
export const ADMIN_CONSOLE_ADDING_EDA_DEPT_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_ADDING_EDA_DEPT_COMPLETED_WITH_ERROR";



export const ADMIN_CONSOLE_UPDATING_DEPT_STATUS = "ADMIN_CONSOLE_UPDATING_DEPT_STATUS";
export const ADMIN_CONSOLE_UPDATING_DEPT_STATUS_COMPLETED = "ADMIN_CONSOLE_UPDATING_DEPT_STATUS_COMPLETED";
export const ADMIN_CONSOLE_UPDATING_DEPT_STATUS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_UPDATING_DEPT_STATUS_COMPLETED_WITH_ERROR";


const initial_state={};


function setEdaDeptList(state, data = {}) {
    const {dataOrder=[], responseData={}, total=0 } = data;
    return {dataOrder, responseData, total};
}


function setEdaDeptProfile(state, data={}) {
    return {profile: data};
}


export const getEdaDept = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_EDA_DEPT });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: EdaDept.getEdaDeptURL(),
		params: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_EDA_DEPT_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_EDA_DEPT_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};




export const getEdaDeptProfile = (profileId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_EDA_DEPT_PROFILE });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: EdaDept.getEdaDeptProfileURL(profileId)
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_EDA_DEPT_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_EDA_DEPT_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};




export const editEdaDeptProfile = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_UPDATING_EDA_DEPT });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: EdaDept.getEdaDeptEditProfileURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATING_EDA_DEPT_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATING_EDA_DEPT_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const addEdaDeptProfile = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_ADDING_EDA_DEPT });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: EdaDept.getEdaDeptAddProfileURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_ADDING_EDA_DEPT_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_ADDING_EDA_DEPT_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};




export const resignEdaMember = (data) => {
    
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_UPDATING_DEPT_STATUS});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: EdaDept.getEdaResignURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATING_DEPT_STATUS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATING_DEPT_STATUS_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export const activateEdaMember = (data) => {
    
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_UPDATING_DEPT_STATUS});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: EdaDept.getEdaActivateURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATING_DEPT_STATUS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATING_DEPT_STATUS_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case ADMIN_CONSOLE_GETTING_EDA_DEPT:
	return {loading:true};
    case ADMIN_CONSOLE_GETTING_EDA_DEPT_COMPLETED:
	return setEdaDeptList(state, payload);
    case ADMIN_CONSOLE_GETTING_EDA_DEPT_PROFILE_COMPLETED:
	return setEdaDeptProfile(state, payload);
    default:
	return state;
	
    }
}
