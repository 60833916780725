import { doRequest } from "../../../Helper/network";
import { InspectorBindingPharmacies as BindingPharmacies } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const INSPECTOR_GETTING_BINDING_PHARMACIES = "INSPECTOR_GETTING_BINDING_PHARMACIES";
export const INSPECTOR_GETTING_BINDING_PHARMACIES_COMPLETED = "INSPECTOR_GETTING_BINDING_PHARMACIES_COMPLETED";
export const INSPECTOR_GETTING_BINDING_PHARMACIES_COMPLETED_WITH_ERROR = "INSPECTOR_GETTING_BINDING_PHARMACIES_COMPLETED_WITH_ERROR";


export const INSPECTOR_ASSIGNING_BINDING_PHARMACIES = "INSPECTOR_ASSIGNING_BINDING_PHARMACIES";
export const INSPECTOR_ASSIGNING_BINDING_PHARMACIES_COMPLETED = "INSPECTOR_ASSIGNING_BINDING_PHARMACIES_COMPLETED";
export const INSPECTOR_ASSIGNING_BINDING_PHARMACIES_COMPLETED_WITH_ERROR = "INSPECTOR_ASSIGNING_BINDING_PHARMACIES_COMPLETED_WITH_ERROR";


export const INSPECTOR_GET_BINDING_PHARMACY_PROFILE = "INSPECTOR_GET_BINDING_PHARMACY_PROFILE";
export const INSPECTOR_GET_BINDING_PHARMACY_PROFILE_COMPLETED = "INSPECTOR_GET_BINDING_PHARMACY_PROFILE_COMPLETED";
export const INSPECTOR_GET_BINDING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR = "INSPECTOR_GET_BINDING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR";


export const INSPECTOR_UPDATING_STATUS_BINDING_PHARMACIES = "INSPECTOR_UPDATING_STATUS_BINDING_PHARMACIES";
export const INSPECTOR_UPDATING_STATUS_BINDING_PHARMACIES_COMPLETED = "INSPECTOR_UPDATING_STATUS_BINDING_PHARMACIES_COMPLETED";
export const INSPECTOR_UPDATING_STATUS_BINDING_PHARMACIES_COMPLETED_WITH_ERROR = "INSPECTOR_UPDATING_STATUS_BINDING_PHARMACIES_COMPLETED_WITH_ERROR";



export const INSPECTOR_GETTING_REVIEWER = "INSPECTOR_GETTING_REVIEWER";
export const INSPECTOR_GETTING_REVIEWER_COMPLETED = "INSPECTOR_GETTING_REVIEWER_COMPLETED";
export const INSPECTOR_GETTING_REVIEWER_COMPLETED_WITH_ERROR = "INSPECTOR_GETTING_REVIEWER_COMPLETED_WITH_ERROR";


export const INSPECTOR_EDIT_QUOTA = "INSPECTOR_EDIT_QUOTA";
export const INSPECTOR_EDIT_QUOTA_COMPLETED = "INSPECTOR_EDIT_QUOTA_COMPLETED";
export const INSPECTOR_EDIT_QUOTA_COMPLETED_WITH_ERROR = "INSPECTOR_EDIT_QUOTA_COMPLETED_WITH_ERROR";


export const INSPECTOR_NOTIFYING_OWNER = "INSPECTOR_NOTIFYING_OWNER";
export const INSPECTOR_NOTIFYING_OWNER_COMPLETED = "INSPECTOR_NOTIFYING_OWNER_COMPLETED";
export const INSPECTOR_NOTIFYING_OWNER_COMPLETED_WITH_ERROR = "INSPECTOR_NOTIFYING_OWNER_COMPLETED_WITH_ERROR";


const INSPECTOR_FETCHING_PRODUCTS = "INSPECTOR_FETCHING_PRODUCTS";
const INSPECTOR_FETCHING_PRODUCTS_COMPLETED = "INSPECTOR_FETCHING_PRODUCTS_COMPLETED";
const INSPECTOR_FETCHING_PRODUCTS_COMPLETED_WITH_ERROR = "INSPECTOR_FETCHING_PRODUCTS_COMPLETED_WITH_ERROR";


const INSPECTOR_ADDING_NARCOTIC_DATA = "INSPECTOR_ADDING_NARCOTIC_DATA";
export const INSPECTOR_ADDING_NARCOTIC_DATA_COMPLETED = "INSPECTOR_ADDING_NARCOTIC_DATA_COMPLETED";
export const INSPECTOR_ADDING_NARCOTIC_DATA_COMPLETED_WITH_ERROR = "INSPECTOR_ADDING_NARCOTIC_DATA_COMPLETED_WITH_ERROR";


const INSPECTOR_UPDATING_NARCOTIC = "INSPECTOR_UPDATING_NARCOTIC";
export const INSPECTOR_UPDATING_NARCOTIC_COMPLETED = "INSPECTOR_UPDATING_NARCOTIC_COMPLETED";
export const INSPECTOR_UPDATING_NARCOTIC_COMPLETED_WITH_ERROR = "INSPECTOR_UPDATING_NARCOTIC_COMPLETED_WITH_ERROR";

const initial_state={};


function setBindingPharmaciesData(state, data = {}) {
    const {bindingFormIds=[], bindingForm={} ,  rejectedCount=0, requestCount=0, reviewedCount=0, allCount=0 } = data;
  if (bindingForm) {
      return {...state, bindingForm, bindingFormIds, loading:false, rejectedCount, requestCount, reviewedCount, allCount};
  } else return state;
}


function setReviewer(state, data={}){
    const {members={}} = data;
    if(members){
	return {...state, members};
    }
    else return state;
}


function setBindingPharmacyProfileData(state, payload={}){
    const {pharmacy={}, manager={}, owners=[], dispensationQuotaDetails={}} = payload;
    return {pharmacy, manager, owners, dispensationQuotaDetails};
}


export const getBindingPharmacies = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_GETTING_BINDING_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: BindingPharmacies.getAllBindingPharmaciesURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GETTING_BINDING_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GETTING_BINDING_PHARMACIES_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};


export const getBindingPharmacyProfile = (bindingFormId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_GET_BINDING_PHARMACY_PROFILE});
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: BindingPharmacies.getBindingPharmacyProfileURL(bindingFormId)
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GET_BINDING_PHARMACY_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GET_BINDING_PHARMACY_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const getPharmacyReviewer = (data) => {
    return async (dispatch, getState) => {
	try{
	    dispatch({ type: INSPECTOR_GETTING_REVIEWER });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: BindingPharmacies.getPharmacyReviewerURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GETTING_REVIEWER_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GETTING_REVIEWER_COMPLETED,
		    payload: payload.data
		});
	    }
	    return response;
	}
	catch(err){
	    throw err;
	}
    };
};



export const assignPharmacyReviewer = (data) => {
    return async (dispatch, getState) => {
	try{
	    dispatch({ type: INSPECTOR_ASSIGNING_BINDING_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: BindingPharmacies.getAssignBindingPharmaciesURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_ASSIGNING_BINDING_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_ASSIGNING_BINDING_PHARMACIES_COMPLETED,
		    payload:{message:payload.message}
		});
	    }
	    return response;
	}
	catch(err){
	    throw err;
	}
    };
};



export const updateBindingStatus = (data) => {
    return async (dispatch, getState) => {
	try{
	    dispatch({ type: INSPECTOR_UPDATING_STATUS_BINDING_PHARMACIES });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: BindingPharmacies.getBindingStatusUpdateURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_UPDATING_STATUS_BINDING_PHARMACIES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_UPDATING_STATUS_BINDING_PHARMACIES_COMPLETED,
		    payload
		});
	    }
	    return response;
	}
	catch(err){
	    throw err;
	}
    };
};



export const editQuota = (data) => {
    return async (dispatch, getState) => {
	try{
	    dispatch({ type: INSPECTOR_EDIT_QUOTA });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: BindingPharmacies.getEditQuotaURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_EDIT_QUOTA_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_EDIT_QUOTA_COMPLETED,
		    payload:{message:payload.message}
		});
	    }
	    return response;
	}
	catch(err){
	    throw err;
	}
    };
};



export const notifyOwnerFromReviewer = (bindingFormId) => {
    return async (dispatch, getState) => {
	try{
	    dispatch({ type: INSPECTOR_NOTIFYING_OWNER });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: BindingPharmacies.getNotifyOwnerFromReviewerURL(bindingFormId),
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_NOTIFYING_OWNER_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_NOTIFYING_OWNER_COMPLETED,
		    payload:{message:payload.message}
		});
	    }
	    return response;
	}
	catch(err){
	    throw err;
	}
    };
};



export const getProductNames = (data) => {
    return async (dispatch, getState) => {
	try{
	    dispatch({ type: INSPECTOR_FETCHING_PRODUCTS });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: BindingPharmacies.getFetchProductsURL(),
		params: data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_FETCHING_PRODUCTS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_FETCHING_PRODUCTS_COMPLETED,
		    payload:{message:payload.message}
		});
	    }
	    return response;
	}
	catch(err){
	    throw err;
	}
    };
};


export const addNarcoticProduct = (data) => {
    return async (dispatch, getState) => {
	try{
	    dispatch({ type: INSPECTOR_ADDING_NARCOTIC_DATA });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: BindingPharmacies.getAddNarcoticDataURL(),
		data: data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_ADDING_NARCOTIC_DATA_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_ADDING_NARCOTIC_DATA_COMPLETED,
		    payload:{message:payload.message}
		});
	    }
	    return response;
	}
	catch(err){
	    throw err;
	}
    };
};



export const editNarcoticProduct = (data) => {

    return async (dispatch, getState) => {
	try{
	    dispatch({ type: INSPECTOR_UPDATING_NARCOTIC });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: BindingPharmacies.getEditNarcoticProductURL(),
		data: data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_UPDATING_NARCOTIC_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_UPDATING_NARCOTIC_COMPLETED,
		    payload:{message:payload.message}
		});
	    }
	    return response;
	}
	catch(err){
	    throw err;
	}
    };

};


export const deleteNarcoticProduct = (data) => {

    return async (dispatch, getState) => {
	try{
	    dispatch({ type: INSPECTOR_UPDATING_NARCOTIC });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: BindingPharmacies.getDeleteNarcoticProductURL(),
		data: data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_UPDATING_NARCOTIC_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_UPDATING_NARCOTIC_COMPLETED,
		    payload:{message:payload.message}
		});
	    }
	    return response;
	}
	catch(err){
	    throw err;
	}
    };
    
};


export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case INSPECTOR_GETTING_BINDING_PHARMACIES:
	return {loading:true};
    case INSPECTOR_GETTING_BINDING_PHARMACIES_COMPLETED:
	return setBindingPharmaciesData(state, payload);
    case INSPECTOR_GETTING_BINDING_PHARMACIES_COMPLETED_WITH_ERROR:
	return {loading:false};
    case INSPECTOR_GET_BINDING_PHARMACY_PROFILE_COMPLETED:
	return setBindingPharmacyProfileData(state, payload);
    case INSPECTOR_GETTING_REVIEWER_COMPLETED:
	return setReviewer(state, payload);
    default:
	return state;
	
    }
}
