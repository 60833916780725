export const getAllPharmaciesURL = () => {
  return "/inspectionAuhtority/pharmacies";
};


export const getSearchOptionsURL = () => {
    return "/inspectionAuhtority/pharmacy-list-dropdown-fields";
};


export const getPharmacyProfileURL = (pharmacyId) => {
    return `/inspectionAuhtority/pharmacies/${pharmacyId}`;
};


export const getReassignPharmacyURL = () => {
    return `/inspectionAuhtority/pharmacies/assign`;
};


export const getReviewerReassignPharmacyURL = () => {
    return `/inspectionAuhtority/pharmacies/assignReviewer`;
};


export const getReassignGovURL = () => {
    return `/inspectionAuhtority/pharmacies/governorate`;
};


export const getCityOptionsURL = () => {
    return `/inspectionAuhtority/cities`;
};

export const getEnableUpdatePharmacyInventoryURL = () => {
    return `/inspectionAuhtority/pharmacies/enable-update-pharmacy-inventory`;
};

