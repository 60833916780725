import { doRequest } from "../../../Helper/network";
import { AdminConsoleProducts as Products } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const ADMIN_CONSOLE_GETTING_PRODUCTS = "ADMIN_CONSOLE_GETTING_PRODUCTS";
export const ADMIN_CONSOLE_GETTING_PRODUCTS_COMPLETED = "ADMIN_CONSOLE_GETTING_PRODUCTS_COMPLETED";
export const ADMIN_CONSOLE_GETTING_PRODUCTS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_PRODUCTS_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_GETTING_PRODUCT_PROFILE = "ADMIN_CONSOLE_GETTING_PRODUCT_PROFILE";
export const ADMIN_CONSOLE_GETTING_PRODUCT_PROFILE_COMPLETED = "ADMIN_CONSOLE_GETTING_PRODUCT_PROFILE_COMPLETED";
export const ADMIN_CONSOLE_GETTING_PRODUCT_PROFILE_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_PRODUCT_PROFILE_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_SUSPEND_BATCH = "ADMIN_CONSOLE_SUSPEND_BATCH";
export const ADMIN_CONSOLE_SUSPEND_BATCH_COMPLETED = "ADMIN_CONSOLE_SUSPEND_BATCH_COMPLETED";
export const ADMIN_CONSOLE_SUSPEND_BATCH_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_SUSPEND_BATCH_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_ADDING_PRODUCT = "ADMIN_CONSOLE_ADDING_PRODUCT";
export const ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED = "ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED";
export const ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_GETTING_DROPDOWN_LIST = "ADMIN_CONSOLE_GETTING_DROPDOWN_LIST";
export const ADMIN_CONSOLE_GETTING_DROPDOWN_LIST_COMPLETED = "ADMIN_CONSOLE_GETTING_DROPDOWN_LIST_COMPLETED";
export const ADMIN_CONSOLE_GETTING_DROPDOWN_LIST_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_DROPDOWN_LIST_COMPLETED_WITH_ERROR";



export const ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS = "ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS";
export const ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS_COMPLETED = "ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS_COMPLETED";
export const ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET = "ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET";
export const ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET_COMPLETED = "ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET_COMPLETED";
export const ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_GETTING_PRODUCT_BATCH_DETAILS = "ADMIN_CONSOLE_GETTING_PRODUCT_BATCH_DETAILS";
export const ADMIN_CONSOLE_GETTING_PRODUCT_BATCH_DETAILS_COMPLETED = "ADMIN_CONSOLE_GETTING_PRODUCT_BATCH_DETAILS_COMPLETED";
export const ADMIN_CONSOLE_GETTING_PRODUCT_BATCH_DETAILS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_PRODUCT_BATCH_DETAILS_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_GETTING_PRODUCT_MAPPINGS = "ADMIN_CONSOLE_GETTING_PRODUCT_MAPPINGS";
export const ADMIN_CONSOLE_GETTING_PRODUCT_MAPPINGS_COMPLETED = "ADMIN_CONSOLE_GETTING_PRODUCT_MAPPINGS_COMPLETED";
export const ADMIN_CONSOLE_GETTING_PRODUCT_MAPPINGS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_PRODUCT_MAPPINGS_COMPLETED_WITH_ERROR";

const initial_state={};


function setProductsData(state, data = {}) {
    const {dataOrder=[], products={}, total=0 } = data;
    if(products){
	return {products, total, dataOrder};
    }   
};


function setProductProfile(state, data={}){
    const {dataOrder=[], products={}, batches={}, total=0, productFiles={}} = data;
    if(products){
	return {...state,  products, batches, dataOrder, total, productFiles};
    }
}


function setProductBatchDetails(state, data={}){
    const {dataOrder=[],  batches={}, total=0} = data;
    if(batches){
	return {...state,  batches, dataOrder, total};
    }
}

function setProductMappings(state, data={}){
    const { productMappings=[] } = data;
	return {...state, productMappings};
}

function setDropdownOptions(state, data={}){
    const {productLicenseType=[], productDosageForm=[], productPackUnitType=[], productMarketType=[], productRouteName=[], productType=[], tradingRegulation=[], subUnit=[]}  = data;
    return {...state, dropDownList: {productLicenseType, productDosageForm, productPackUnitType, productMarketType, productRouteName, productType, tradingRegulation, subUnit}};
}


export const getProducts = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_PRODUCTS });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Products.getAllProductsURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PRODUCTS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PRODUCTS_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const getProductProfile = (productId, data) => {
    
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_PRODUCT_PROFILE });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Products.getProductProfileURL(productId),
		params: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PRODUCT_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PRODUCT_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
};




export const getProductBatchDetails = (productId, batchId, data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_PRODUCT_BATCH_DETAILS });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Products.getProductBatchDetailsURL(productId, batchId),
		params: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PRODUCT_BATCH_DETAILS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PRODUCT_BATCH_DETAILS_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
};


export const suspendBatch = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_SUSPEND_BATCH});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Products.getSuspendBatchURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_SUSPEND_BATCH_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_SUSPEND_BATCH_COMPLETED,
		    payload: { message: payload.message }
		});

	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export const createProduct = (data) => {
    
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_ADDING_PRODUCT});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Products.getCreateProductURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED,
		    payload: { message: payload.message }
		});
	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};




export const editProduct = (data) => {
    
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_ADDING_PRODUCT});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Products.getEditProductURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED,
		    payload: { message: payload.message }
		});
	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const getProductDropDownList = () => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_DROPDOWN_LIST});
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Products.getProductDropDownListURL(),
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_DROPDOWN_LIST_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_DROPDOWN_LIST_COMPLETED,
		    payload: payload.data
		});
	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export const updateProductStatus = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Products.getProductStatusUpdateURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS_COMPLETED,
		    payload: { message: payload.message }
		});
	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export const updateRequireTicket = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Products.getUpdateRequireTicketURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET_COMPLETED,
		    payload: { message: payload.message }
		});
	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const updateRequirePrescriptionPhoto = (data) => {
    
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Products.getUpdateRequirePrescriptionURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET_COMPLETED,
		    payload: { message: payload.message }
		});
	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};

export const getProductMappings = (productId) => {
    return async (dispatch) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_PRODUCT_MAPPINGS});
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Products.getProductMappingsURL(productId),
		
	    });
	    const { status, payload } = response;
		if (status === false) {
			dispatch({
				type: ADMIN_CONSOLE_GETTING_PRODUCT_MAPPINGS_COMPLETED_WITH_ERROR,
				payload: { error: payload.error }
			});
			} else if (status === true) {
			dispatch({
				type: ADMIN_CONSOLE_GETTING_PRODUCT_MAPPINGS_COMPLETED,
				payload: payload.data
			});
		}
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};

export const updateProductMappings = (data) => {
    
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_ADDING_PRODUCT});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Products.getUpdateProductMappingsURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED,
		    payload: { message: payload.message }
		});
	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};

export const updateProductMapping = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Products.getProductMappingStatusUpdateURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS_COMPLETED,
		    payload: { message: payload.message }
		});
	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case ADMIN_CONSOLE_GETTING_PRODUCTS:
	return {loading:true};
    case ADMIN_CONSOLE_GETTING_PRODUCTS_COMPLETED:
	return setProductsData(state, payload);
    case ADMIN_CONSOLE_GETTING_PRODUCTS_COMPLETED_WITH_ERROR:
	return {loading:false};
    case ADMIN_CONSOLE_GETTING_PRODUCT_PROFILE:
	return {loading: true};
    case ADMIN_CONSOLE_GETTING_PRODUCT_PROFILE_COMPLETED:
	return setProductProfile(state, payload);
    case ADMIN_CONSOLE_GETTING_PRODUCT_PROFILE_COMPLETED_WITH_ERROR:
	return {loading: false};
    case ADMIN_CONSOLE_GETTING_PRODUCT_BATCH_DETAILS_COMPLETED:
	return setProductBatchDetails(state, payload);
    case ADMIN_CONSOLE_GETTING_PRODUCT_BATCH_DETAILS_COMPLETED_WITH_ERROR:
	return {loading: false};
    case ADMIN_CONSOLE_GETTING_DROPDOWN_LIST:
	return state;
    case ADMIN_CONSOLE_GETTING_DROPDOWN_LIST_COMPLETED:
	return setDropdownOptions(state, payload);
	case ADMIN_CONSOLE_GETTING_PRODUCT_MAPPINGS_COMPLETED:
	return setProductMappings(state, payload);
    default:
	return state;
	
    }
}
