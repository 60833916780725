import { doRequest } from "../../../Helper/network";
import { CitizensResources } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const ADMIN_CONSOLE_GETTING_CITIZENS_RESOURCES = "ADMIN_CONSOLE_GETTING_CITIZENS_RESOURCES";
export const ADMIN_CONSOLE_GETTING_CITIZENS_RESOURCES_COMPLETED = "ADMIN_CONSOLE_GETTING_CITIZENS_RESOURCES_COMPLETED";
export const ADMIN_CONSOLE_GETTING_CITIZENS_RESOURCES_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_CITIZENS_RESOURCES_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_CREATING_CITIZENS_RESOURCES = "ADMIN_CONSOLE_CREATING_CITIZENS_RESOURCES";
export const ADMIN_CONSOLE_CREATING_CITIZENS_RESOURCES_COMPLETED = "ADMIN_CONSOLE_CREATING_CITIZENS_RESOURCES_COMPLETED";
export const ADMIN_CONSOLE_CREATING_CITIZENS_RESOURCES_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_CREATING_CITIZENS_RESOURCES_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_DELETING_CITIZENS_RESOURCES = "ADMIN_CONSOLE_DELETING_CITIZENS_RESOURCES";
export const ADMIN_CONSOLE_DELETING_CITIZENS_RESOURCES_COMPLETED = "ADMIN_CONSOLE_DELETING_CITIZENS_RESOURCES_COMPLETED";
export const ADMIN_CONSOLE_DELETING_CITIZENS_RESOURCES_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_DELETING_CITIZENS_RESOURCES_COMPLETED_WITH_ERROR";

const initial_state={};


function setCitizensResourcesData(state, data = {}) {
    const {dataOrder=[], responseData={}, total=0 } = data;
    return {dataOrder, responseData, total};
}


export const fetchCitizensResources = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_CITIZENS_RESOURCES });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: CitizensResources.getAllCitizensResourcesURL(),
		params:data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_CITIZENS_RESOURCES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_CITIZENS_RESOURCES_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};

export const createCitizensResources = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_CITIZENS_RESOURCES});
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: CitizensResources.getCreateCitizensResourcesURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_CITIZENS_RESOURCES_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_CITIZENS_RESOURCES_COMPLETED,
		    payload: {message: payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};

export const deleteCitizensResources = (id) => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: ADMIN_CONSOLE_DELETING_CITIZENS_RESOURCES });
			let response = await doRequest({
				method: REQUEST_TYPE.PUT,
				url: CitizensResources.updateCitizensResourcesURL(),
				data: { id }
			});
			const { status, payload } = response;
			if (status === false) {
				dispatch({
					type: ADMIN_CONSOLE_DELETING_CITIZENS_RESOURCES_COMPLETED_WITH_ERROR,
					payload: { error: payload.error }
				});
			} else if (status === true) {
				dispatch({
					type: ADMIN_CONSOLE_DELETING_CITIZENS_RESOURCES_COMPLETED,
					payload: { message: payload.message }
				});

			}

			return response;

		} catch (err) {
			throw err;
		}
	};
};



export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    
		case ADMIN_CONSOLE_GETTING_CITIZENS_RESOURCES:
			return {loading:true};
    
		case ADMIN_CONSOLE_GETTING_CITIZENS_RESOURCES_COMPLETED:
			return setCitizensResourcesData(state, payload);
    
		case ADMIN_CONSOLE_GETTING_CITIZENS_RESOURCES_COMPLETED_WITH_ERROR:
			return {loading:false};

    	default:
			return state;
	
    }
}
