import { doRequest } from "../../Helper/network";
import { Auth } from "../../Helper/urls";
import { REQUEST_TYPE, path } from "../../constant";

export const SIGNING = "SIGNING";
export const SIGNING_COMPLETED = "SIGNING_COMPLETED";
export const SIGNING_COMPLETED_WITH_ERROR = "SIGNING_COMPLETED_WITH_ERROR";

export const CREATING_NEW_PASSWORD = "CREATING_NEW_PASSWORD";
export const CREATING_NEW_PASSWORD_COMPLETED =
  "CREATING_NEW_PASSWORD_COMPLETED";
export const CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR =
  "CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR";

export const VERIFYING_OTP = "VERIFYING_OTP";
export const VERIFYING_OTP_COMPLETED = "VERIFYING_OTP_COMPLETED";
export const VERIFYING_OTP_COMPLETED_WITH_ERROR =
  "VERIFYING_OTP_COMPLETED_WITH_ERROR";

export const RESENDING_OTP = "RESENDING_OTP";
export const RESENDING_OTP_COMPLETED = "RESENDING_OTP_COMPLETED";
export const RESENDING_OTP_COMPLETED_WITH_ERROR =
  "RESENDING_OTP_COMPLETED_WITH_ERROR";

export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_COMPLETED = "FORGET_PASSWORD_COMPLETED";
export const FORGET_PASSWORD_COMPLETED_WITH_ERROR =
  "FORGET_PASSWORD_COMPLETED_WITH_ERROR";

export const GETTING_INITIAL_DATA = "GETTING_INITIAL_DATA";
export const GETTING_INITIAL_DATA_COMPLETED = "GETTING_INITIAL_DATA_COMPLETED";
export const GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR =
    "GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR";


const RESETTING_NEW_PASSWORD = "RESETTING_NEW_PASSWORD";
export const RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR = "RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR";
export const RESETTING_NEW_PASSWORD_COMPLETED = "RESETTING_NEW_PASSWORD_COMPLETED";

const VERIFYING_RESET_OTP = "VERIFYING_RESET_OTP";
export const VERIFYING_RESET_OTP_COMPLETED = "VERIFYING_RESET_OTP_COMPLETED";
export const VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR = "VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR";

const RESENDING_RESET_OTP = "RESENDING_RESET_OTP";
export const RESENDING_RESET_OTP_COMPLETED = "RESENDING_RESET_OTP_COMPLETED";
export const RESENDING_RESET_OTP_COMPLETED_WITH_ERROR = "RESENDING_RESET_OTP_COMPLETED_WITH_ERROR";



export const SIGNING_OUT = "SIGNING_OUT";
export const SIGNING_OUT_COMPLETED = "SIGNING_OUT_COMPLETED";


export const RESET_UNAUTHORIZED_ERROR = "RESET_UNAUTHORIZED_ERROR";

export const RESET_ERROR = "RESET_ERROR";


export const EMPTY_AUTH_REDIRECTION="EMPTY_AUTH_REDIRECTION";

const intial_state = {};


export const resetUnauthorizedError = state => {
    return { type: RESET_UNAUTHORIZED_ERROR };
};


export const clearError = () => {
    return { type: RESET_ERROR };
};


export const emptyAuthRedirection = () => {
    return dispatch => {
	dispatch({type:EMPTY_AUTH_REDIRECTION});
    };
};

export const signIn = (data = {}, lastUrl) => {
  return async (dispatch, getState) => {
    try {
      const { auth = {} } = getState();
      dispatch({ type: SIGNING });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.getSignInURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: SIGNING_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
	  const {data:{authUser} = {}}  = payload;
	  const {data:{users={}, branches=[], cities=[], user_category, user_profiles=[], publicAdministrationData = {}, pharmacyTypes=[], isDistributorActive={}} = {}} = payload;
	  const is_verified = users[authUser].is_verified;
          let redirectTo = "";
          if (is_verified) {
	      if (lastUrl && auth.unauthorizedError !== undefined) {
		  redirectTo = lastUrl;
              }
	      else{
		  redirectTo = path.PHARMACY_LIST;
	      }
        } else {
          redirectTo = path.CREATE_PASSWORD;
        }
        dispatch({
            type: SIGNING_COMPLETED,
            payload: { ...users[authUser], authRedirection: redirectTo, authUser:authUser, users:users, branches:branches, cities:cities, roles:user_category, profiles:user_profiles, publicAdministrationData, pharmacyTypes, isDistributorActive}
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const createPassword = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: CREATING_NEW_PASSWORD });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.createNewPasswordURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        let redirectTo = path.OTP;
        dispatch({
          type: CREATING_NEW_PASSWORD_COMPLETED,
          payload: { ...payload.data, authRedirection: redirectTo }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const verifyOTP = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: VERIFYING_OTP });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.verifyOtpURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: VERIFYING_OTP_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        let redirectTo = path.SIGN_IN;

        dispatch({
          type: VERIFYING_OTP_COMPLETED,
          payload: { ...payload.data, authRedirection: redirectTo }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const resendOTP = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: RESENDING_OTP });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.resendOtpURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: RESENDING_OTP_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: RESENDING_OTP_COMPLETED,
          payload: {message:payload.message}
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const forgotPassword = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FORGET_PASSWORD });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.forgetPasswordURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: FORGET_PASSWORD_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        let redirectTo = path.CREATE_PASSWORD;
        dispatch({
          type: FORGET_PASSWORD_COMPLETED,
          payload: { ...payload.data, authRedirection: redirectTo }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const verifyForgotPasswordOTP = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: VERIFYING_OTP });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.verifyOtpForgotPasswordURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: VERIFYING_OTP_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        let redirectTo = path.SIGN_IN;

        dispatch({
          type: VERIFYING_OTP_COMPLETED,
          payload: { ...payload.data, authRedirection: redirectTo }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const resendForgotPasswordOTP = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: RESENDING_OTP });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.resendOtpForgotPasswordURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: RESENDING_OTP_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: RESENDING_OTP_COMPLETED,
          payload: {message:payload.message}
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const onAppStart = () => {
  return async dispatch => {
      try {
	  dispatch({ type: GETTING_INITIAL_DATA });
      const response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Auth.getBasicInfoURL()
      });
      
	const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR,
          payload: {
          }
        });
      }
	if (status === true) {
	    const {data:{authUser} = {}}  = payload;
	    const {data:{users={}, branches=[], cities=[], user_category=[], user_profiles=[], publicAdministrationData={}, pharmacyTypes=[], distributor = {}} = {}} = payload;
	  const redirectTo = "";
        dispatch({
          type: GETTING_INITIAL_DATA_COMPLETED,
          payload: {
              authenticatedUser: authUser,
	      authUser:authUser,
              authRedirection: redirectTo,
	      users:users,
	      branches,
	      cities,
	      publicAdministrationData,
	      roles:user_category,
	      profiles: user_profiles,
        pharmacyTypes,
        distributor,
          }
        });
      }
    } catch (err) {
      dispatch({
        type: GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR
      });
    }
  };
};



export const resetPassword = (data={}) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: RESETTING_NEW_PASSWORD });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		data: data,
		url: Auth.resetNewPasswordURL()
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
    };
};


export const verifyResetOtp = (data={}) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: VERIFYING_RESET_OTP });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		data: data,
		url: Auth.verifyResetOtpURL()
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: VERIFYING_RESET_OTP_COMPLETED,
		    payload: { message:payload.message}
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
    };
};


export const resendResetOtp = (data={}) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: RESENDING_RESET_OTP });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		data: data,
		url: Auth.resendResetOtpURL()
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: RESENDING_RESET_OTP_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: RESENDING_RESET_OTP_COMPLETED,
		    payload: {message:payload.message}
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
    };
};



export const logOut = (data = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: SIGNING_OUT });
      let response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Auth.getSignOutURL()
      });

      const { status } = response;
      if (status === true) {
        dispatch({ type: SIGNING_OUT_COMPLETED });
      } 
      return status;
    } catch (err) {
      throw err;
    }
  };
};


function unsetUnathorizedError(state) {
  const { unauthorizedError } = state;
  if (unauthorizedError) {
    return {
      ...state,
      unauthorizedError: false
    };
  }
  return state;
}


export default (state = intial_state, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GETTING_INITIAL_DATA_COMPLETED: {
      return {
        authenticated: true,
        authenticated_user: payload.authenticatedUser,
        authRedirection: payload.authRedirection,
          authUser:payload.authUser,
	  publicAdministrationData:payload.publicAdministrationData,
    pharmacyTypes: payload.pharmacyTypes,
    distributor: payload.distributor || {},
	  
      };
    }
    case GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR:
      return {
        authenticated: false,
        unauthorizedError: true
      };

    case SIGNING_COMPLETED_WITH_ERROR:
      return {
        authenticated: false,
        error: payload.error
      };

    case SIGNING_COMPLETED:
      return {
          ...state,
          authenticated: payload.is_verified,
          is_verified: payload.is_verified,
          user_id: payload.id,
          authRedirection: payload.authRedirection,
	  authUser:payload.authUser,
	  publicAdministrationData:payload.publicAdministrationData,
    pharmacyTypes: payload.pharmacyTypes,
    distributor: payload.isDistributorActive,
      };

    case SIGNING_OUT_COMPLETED:
      return {
        authenticated: false,
        authRedirection: path.SIGN_IN
      };
      
  case RESET_ERROR:
      return {
          ...state,
          error: null
      };

  case RESET_UNAUTHORIZED_ERROR:
      return unsetUnathorizedError(state);

    case CREATING_NEW_PASSWORD_COMPLETED:
      return {
        ...state,
        authRedirection: payload.authRedirection
      };
    case VERIFYING_OTP_COMPLETED:
      return {
        ...state,
        authRedirection: payload.authRedirection
      };
    case FORGET_PASSWORD_COMPLETED:
      return {
        ...state,
        user_id: payload.id,
        authRedirection: payload.authRedirection
      };

  case FORGET_PASSWORD_COMPLETED_WITH_ERROR:
      return {
	  ...state,
          authenticated: false,
          error: payload.error
      };

  case CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR:
      return {
	  ...state,
          authenticated: false,
          error: payload.error
      };

  case VERIFYING_OTP_COMPLETED_WITH_ERROR:
      return {
	  ...state,
          authenticated: false,
          error: payload.error
      };

  case EMPTY_AUTH_REDIRECTION:
      return {
	  ...state,
	  authRedirection:""
      };
      
    default:
      return state;
  }
};
