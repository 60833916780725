import { doRequest } from "../../../Helper/network";
import { InspectorExpiredNightPharmacy as ExpiredNightPharmacy } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";

const GET_ALL_EXPIRED_NIGHT_PHARMACY = "GET_ALL_EXPIRED_NIGHT_PHARMACY";
const GET_ALL_EXPIRED_NIGHT_PHARMACY_COMPLETED = "GET_ALL_EXPIRED_NIGHT_PHARMACY_COMPLETED";
const GET_ALL_EXPIRED_NIGHT_PHARMACY_COMPLETED_WITH_ERROR = "GET_ALL_EXPIRED_NIGHT_PHARMACY_COMPLETED_WITH_ERROR";


export const CHANGE_PHARMACY_TYPE = "CHANGE_PHARMACY_TYPE";
export const CHANGE_PHARMACY_TYPE_COMPLETED = "CHANGE_PHARMACY_TYPECOMPLETED";
export const CHANGE_PHARMACY_TYPE_COMPLETED_WITH_ERROR = "CHANGE_PHARMACY_TYPE_COMPLETED_WITH_ERROR";

export const APPROVE_CHANGE_PHARMACY_TYPE = "APPROVE_CHANGE_PHARMACY_TYPE";
export const APPROVE_CHANGE_PHARMACY_TYPE_COMPLETED = "APPROVE_CHANGE_PHARMACY_TYPECOMPLETED";
export const APPROVE_CHANGE_PHARMACY_TYPE_COMPLETED_WITH_ERROR = "APPROVE_CHANGE_PHARMACY_TYPE_COMPLETED_WITH_ERROR";


export const NOTIFY_OWNER = "NOTIFY_OWNER";
export const NOTIFY_OWNER_COMPLETED = "NOTIFY_OWNER_COMPLETED";
export const NOTIFY_OWNER_COMPLETED_WITH_ERROR = "NOTIFY_OWNER_COMPLETED_WITH_ERROR";



export const RENEW_NIGHT_PHARMACY = "RENEW_NIGHT_PHARMACY";
export const RENEW_NIGHT_PHARMACY_COMPLETED = "RENEW_NIGHT_PHARMACY_COMPLETED";
export const RENEW_NIGHT_PHARMACY_COMPLETED_WITH_ERROR = "RENEW_NIGHT_PHARMACY_COMPLETED_WITH_ERROR";


const initial_state={};

function setExpiredNightPharmacyData(state, payload = {}) {
    const {data = {}, total=0, typeChnageCount=0, requestCount=0 } = payload;
    return {expiredNightPharmacies:data, totalRecords:total, typeChnageCount, requestCount};
};


export const getAllNightPharmaciesExpired = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GET_ALL_EXPIRED_NIGHT_PHARMACY });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: ExpiredNightPharmacy.getAllExpiredNightPharmacyURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GET_ALL_EXPIRED_NIGHT_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GET_ALL_EXPIRED_NIGHT_PHARMACY_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};


export const getAllNightPharmaciesExpiredReviewerChange = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GET_ALL_EXPIRED_NIGHT_PHARMACY });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: ExpiredNightPharmacy.getAllExpiredNightPharmacyReviewerChangeURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GET_ALL_EXPIRED_NIGHT_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GET_ALL_EXPIRED_NIGHT_PHARMACY_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};


export const getAllNightPharmaciesExpiredReviewer = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GET_ALL_EXPIRED_NIGHT_PHARMACY });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: ExpiredNightPharmacy.getAllExpiredNightPharmacyReviewerURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: GET_ALL_EXPIRED_NIGHT_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GET_ALL_EXPIRED_NIGHT_PHARMACY_COMPLETED,
		    payload: payload.data
		});
	    }
	    
	} catch (err) {
	    throw err;
	}
    };  
};


export const changePharmacyType = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: CHANGE_PHARMACY_TYPE });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: ExpiredNightPharmacy.getChangePharmacyTypeURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: CHANGE_PHARMACY_TYPE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: CHANGE_PHARMACY_TYPE_COMPLETED,
		    payload:{message:payload.message}
		});
	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };  
};

export const approveChangePharmacyType = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: APPROVE_CHANGE_PHARMACY_TYPE });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: ExpiredNightPharmacy.getApproveChangePharmacyTypeURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: APPROVE_CHANGE_PHARMACY_TYPE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: APPROVE_CHANGE_PHARMACY_TYPE_COMPLETED,
		    payload:{message:payload.message}
		});
	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };  
};



export const notifyOwner = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: NOTIFY_OWNER });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: ExpiredNightPharmacy.getNotifyOwnerURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: NOTIFY_OWNER_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: NOTIFY_OWNER_COMPLETED,
		    payload:{message:payload.message}
		});
	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };  
};



export const renewNightPharmacy = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: RENEW_NIGHT_PHARMACY });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: ExpiredNightPharmacy.getRenewNightPharmacyURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: RENEW_NIGHT_PHARMACY_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: RENEW_NIGHT_PHARMACY_COMPLETED,
		    payload:{message:payload.message}
		});
	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };  
};




export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case GET_ALL_EXPIRED_NIGHT_PHARMACY:
	return {loading:true};
    case GET_ALL_EXPIRED_NIGHT_PHARMACY_COMPLETED:
	return setExpiredNightPharmacyData(state, payload);
    case GET_ALL_EXPIRED_NIGHT_PHARMACY_COMPLETED_WITH_ERROR:
	return {loading:false};

    default:
	return state;
	
    }
}
