import { doRequest } from "../../Helper/network";
import { User } from "../../Helper/urls";
import { REQUEST_TYPE } from "../../constant";

export const GETTING_DISPENSATION_ORDERS = "GETTING_DISPENSATION_ORDERS";
export const GETTING_DISPENSATION_ORDERS_COMPLETED = "GETTING_DISPENSATION_ORDERS_COMPLETED";
export const GETTING_DISPENSATION_ORDERS_COMPLETED_WITH_ERROR = "GETTING_DISPENSATION_ORDERS_COMPLETED_WITH_ERROR";

const initial_state = {};


function setDispensationSheets(state, data={}) {
    const {orders={}, total=0, dataOrder=[]} = data;
    if(Object.keys(orders).length){
	return {orders, totalRecords:total, loading:false, dataOrder:dataOrder};
    }
    else return {...state, loading:false};
}



export const getDispensationOrders = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: GETTING_DISPENSATION_ORDERS });
	    let response = await doRequest({
	    	method: REQUEST_TYPE.GET,
	    	url: User.getDispensationOrdersURL(),
		params:data
		
	    });
	    const { status,payload } = response;
	    if (status === false) {
		dispatch({
		    type: GETTING_DISPENSATION_ORDERS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: GETTING_DISPENSATION_ORDERS_COMPLETED,
		    payload: payload.data
		});
	    }
	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
}


export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){

    case GETTING_DISPENSATION_ORDERS:
	return {loading:true};

    case GETTING_DISPENSATION_ORDERS_COMPLETED:
	return setDispensationSheets(state, payload);
	
    case GETTING_DISPENSATION_ORDERS_COMPLETED_WITH_ERROR:
	return {loading:false};
	
    default:
	return state;
	
    }
}
