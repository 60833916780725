export const getAllGeographicalListURL = () => {
    return `/eda/geographies`;
};


export const getGeographicalProfileURL = (geographicalId) => {
    return `/eda/geography/${geographicalId}`;
};


export const getAddGeographyURL = () => {
    return `/eda/geography/create`;
};


export const getEditGeographyURL = () => {
    return `/eda/geography/update`;
};



export const getSuspendGeographyURL = (geographicalId) => {
    return `/eda/geography/suspend/${geographicalId}`;
};


export const getActivateGeographyURL = (geographicalId) => {
    return `/eda/geography/activate/${geographicalId}`;
};
