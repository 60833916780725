import { doRequest } from "../../../Helper/network";
import { InspectorAudit as AuditPharmacies } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";

export const INSPECTOR_GETTING_AUDIT_PHARMACIES =
  "INSPECTOR_GETTING_AUDIT_PHARMACIES";
export const INSPECTOR_GETTING_AUDIT_PHARMACIES_COMPLETED =
  "INSPECTOR_GETTING_AUDIT_PHARMACIES_COMPLETED";
export const INSPECTOR_GETTING_AUDIT_PHARMACIES_COMPLETED_WITH_ERROR =
  "INSPECTOR_GETTING_AUDIT_PHARMACIES_COMPLETED_WITH_ERROR";

export const INSPECTOR_FETCHING_AUDIT_OPTIONS =
  "INSPECTOR_FETHCING_AUDIT_OPTIONS";
export const INSPECTOR_FETCHING_AUDIT_OPTIONS_COMPLETED =
  "INSPECTOR_FETCHING_AUDIT_OPTIONS_COMPLETED";
export const INSPECTOR_FETCHING_AUDIT_OPTIONS_COMPLETED_WITH_ERROR =
  "INSPECTOR_FETCHING_AUDIT_OPTIONS_COMPLETED_WITH_ERROR";

export const INSPECTOR_GETTING_AUDIT_PHARMACY_PROFILE =
  "INSPECTOR_GETTING_AUDIT_PHARMACY_PROFILE";
export const INSPECTOR_GETTING_AUDIT_PHARMACY_PROFILE_COMPLETED =
  "INSPECTOR_GETTING_AUDIT_PHARMACY_PROFILE_COMPLETED";
export const INSPECTOR_GETTING_AUDIT_PHARMACY_PROFILE_COMPLETED_WITH_ERROR =
  "INSPECTOR_GETTING_AUDIT_PHARMACY_PROFILE_COMPLETED_WITH_ERROR";

export const INSPECTOR_CHANGE_PHARMACY_STATUS =
  "INSPECTOR_CHANGE_PHARMACY_STATUS";
export const INSPECTOR_CHANGE_PHARMACY_STATUS_COMPLETED =
  "INSPECTOR_CHANGE_PHARMACY_STATUS_COMPLETED";
export const INSPECTOR_CHANGE_PHARMACY_STATUS_COMPLETED_WITH_ERROR =
  "INSPECTOR_CHANGE_PHARMACY_STATUS_COMPLETED_WITH_ERROR";

const initial_state = {};

function setAuditPharmaciesData(state, data = {}) {
  const {
    audits = {},
    pharmacies = {},
    auditReport = [],
    statusChange = [],
    inspectorUsers = {},
    total = 0,
  } = data;
  if (audits) {
    return {
      audits,
      pharmacies,
      auditReport,
      statusChange,
      inspectorUsers,
      totalRecords: total,
      loading: false,
    };
  } else return {};
}

function setAuditPharmacyProfileData(state, data = {}) {
  const {
    audits = {},
    pharmacy = {},
    users = {},
    inspectorUsers = {},
    pharmacyAuditStatus = {},
    offenses = {},
    violations = {},
  } = data;
  if (audits) {
    return {
      pharmacyProfile: {
        audits,
        pharmacy,
        users,
        inspectorUsers,
        pharmacyAuditStatus,
        offenses,
        violations,
      },
      loading: false,
    };
  } else return {};
}

export const getAuditPharmacies = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSPECTOR_GETTING_AUDIT_PHARMACIES });
      let response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: AuditPharmacies.getAllAuditPharmaciesURL(),
        params: data,
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_GETTING_AUDIT_PHARMACIES_COMPLETED_WITH_ERROR,
          payload: { error: payload.error },
        });
      } else if (status === true) {
        dispatch({
          type: INSPECTOR_GETTING_AUDIT_PHARMACIES_COMPLETED,
          payload: payload.data,
        });
      }
    } catch (err) {
      throw err;
    }
  };
};

// export const fetchStatusOptions = () => {
//     return async (dispatch, getState) => {
// 	try {
// 	    //const { auth = {} } = getState();
// 	    dispatch({ type: INSPECTOR_FETCHING_AUDIT_OPTIONS});
// 	    let response = await doRequest({
// 		method: REQUEST_TYPE.GET,
// 		url: AuditPharmacies.getSearchOptionsURL(),
		
// 	    });
// 	    const { status, payload } = response;
// 	    if (status === false) {
// 		dispatch({
// 		    type: INSPECTOR_FETCHING_AUDIT_OPTIONS_COMPLETED_WITH_ERROR,
// 		    payload: { error: payload.error }
// 		});
// 	    } else if (status === true) {
// 		dispatch({
// 		    type: INSPECTOR_FETCHING_AUDIT_OPTIONS_COMPLETED,
// 		    payload: payload.data
// 		});

// 	    }
// 	    return response;

// 	} catch (err) {
// 	    throw err;
// 	}
//     };

// };

export const getAuditPharmacyProfile = (pharmacyId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSPECTOR_GETTING_AUDIT_PHARMACY_PROFILE });
      let response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: AuditPharmacies.getAuditPharmacyProfileURL(pharmacyId),
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_GETTING_AUDIT_PHARMACY_PROFILE_COMPLETED_WITH_ERROR,
          payload: { error: payload.error },
        });
      } else if (status === true) {
        dispatch({
          type: INSPECTOR_GETTING_AUDIT_PHARMACY_PROFILE_COMPLETED,
          payload: payload.data,
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const changePharmacyStatus = (auditId, data) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSPECTOR_CHANGE_PHARMACY_STATUS });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: AuditPharmacies.getAuditPharmacyProfileURL(auditId),
        data: data,
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: INSPECTOR_CHANGE_PHARMACY_STATUS_COMPLETED_WITH_ERROR,
          payload: { error: payload.error },
        });
      } else if (status === true) {
        dispatch({
          type: INSPECTOR_CHANGE_PHARMACY_STATUS_COMPLETED,
          payload: payload.data,
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export default (state = initial_state, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case INSPECTOR_GETTING_AUDIT_PHARMACIES:
      return { loading: true };
    case INSPECTOR_GETTING_AUDIT_PHARMACIES_COMPLETED:
      return setAuditPharmaciesData(state, payload);
    case INSPECTOR_GETTING_AUDIT_PHARMACIES_COMPLETED_WITH_ERROR:
      return { loading: false };
    case INSPECTOR_GETTING_AUDIT_PHARMACY_PROFILE:
      return { loading: true };
    case INSPECTOR_GETTING_AUDIT_PHARMACY_PROFILE_COMPLETED:
      return setAuditPharmacyProfileData(state, payload);
    case INSPECTOR_GETTING_AUDIT_PHARMACY_PROFILE_COMPLETED_WITH_ERROR:
      return { loading: false };
    default:
      return state;
  }
};
