import { doRequest } from "../../../Helper/network";
import { CitizensVideos } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";

const ADMIN_CONSOLE_CREATING_CITIZENS_VIDEOS = "ADMIN_CONSOLE_CREATING_CITIZENS_VIDEOS";
export const ADMIN_CONSOLE_CREATING_CITIZENS_VIDEOS_COMPLETED = "ADMIN_CONSOLE_CREATING_CITIZENS_VIDEOS_COMPLETED";
export const ADMIN_CONSOLE_CREATING_CITIZENS_VIDEOS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_CREATING_CITIZENS_VIDEOS_COMPLETED_WITH_ERROR";


const initial_state={};

export const createCitizensVideos = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_CITIZENS_VIDEOS });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: CitizensVideos.getCreateCitizensVideosURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_CITIZENS_VIDEOS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_CITIZENS_VIDEOS_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    default:
	return state;
	
    }
}


