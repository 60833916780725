import english from './en.json';
import arabic from './ar.json';
// import config from '../../config';

// let en;
// let ar;

// const fetchTranslations = () => {
//     let enJsonData;
//     let arJsonData;
    
//     Promise.all([
// 	fetch(`${config.S3_HOST}/${config.BUCKET_NAME}/en.json`, {
// 	    headers : { 
// 		'Content-Type': 'application/json',
// 		'Accept': 'application/json'
// 	    }
// 	}),
// 	fetch(`${config.S3_HOST}/${config.BUCKET_NAME}/ar.json`, {
// 	    headers : { 
// 		'Content-Type': 'application/json',
// 		'Accept': 'application/json'
// 	    }
// 	})
//     ]).then(function (responses) {
// 	return Promise.all(responses.map(function (response) {
// 	    return response.json();
// 	}));
	
//     })
// 	.then(function (data) {
// 	    const langs = data;
// 	    enJsonData = langs[0];
// 	    arJsonData = langs[1];
// 	    const translations = {
// 		en:enJsonData,
// 		ar:arJsonData
// 	    };
	    
// 	    localStorage.setItem("translations", JSON.stringify(translations));
	    
// 	})
// 	.catch(function (error) {
// 	    console.log(error);
// 	});
    
// };



// const lang = localStorage.getItem("translations");
// if(lang && Object.keys(JSON.parse(lang)).length){
//     const translations = JSON.parse(lang);
//     en = translations["en"];
//     ar = translations["ar"];
//     fetchTranslations();
// }
// else{
//     en=english;
//     ar=arabic;
//     fetchTranslations();
// }


export default {
    en:english,
    ar:arabic
};


