import { doRequest } from "../../../Helper/network";
import { AdminDistributors as Distributors } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const ADMIN_CONSOLE_GETTING_DISTRIBUTORS = "ADMIN_CONSOLE_GETTING_DISTRIBUTORS";
export const ADMIN_CONSOLE_GETTING_DISTRIBUTORS_COMPLETED = "ADMIN_CONSOLE_GETTING_DISTRIBUTORS_COMPLETED";
export const ADMIN_CONSOLE_GETTING_DISTRIBUTORS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_DISTRIBUTORS_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_CREATING_DISTRIBUTOR = "ADMIN_CONSOLE_CREATING_DISTRIBUTOR";
export const ADMIN_CONSOLE_CREATING_DISTRIBUTOR_COMPLETED = "ADMIN_CONSOLE_CREATING_DISTRIBUTOR_COMPLETED";
export const ADMIN_CONSOLE_CREATING_DISTRIBUTOR_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_CREATING_DISTRIBUTOR_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_CREATING_BRANCH = "ADMIN_CONSOLE_CREATING_BRANCH";
export const ADMIN_CONSOLE_CREATING_BRANCH_COMPLETED = "ADMIN_CONSOLE_CREATING_BRANCH_COMPLETED";
export const ADMIN_CONSOLE_CREATING_BRANCH_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_CREATING_BRANCH_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE = "ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE";
export const ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE_COMPLETED = "ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE_COMPLETED";
export const ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_UPDATING_BRANCH_STATUS = "ADMIN_CONSOLE_UPDATING_BRANCH_STATUS";
export const ADMIN_CONSOLE_UPDATING_BRANCH_STATUS_COMPLETED = "ADMIN_CONSOLE_UPDATING_BRANCH_STATUS_COMPLETED";
export const ADMIN_CONSOLE_UPDATING_BRANCH_STATUS_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_UPDATING_BRANCH_STATUS_COMPLETED_WITH_ERROR";



const DISTRIBUTOR_STATUS_CHANGED = "DISTRIBUTOR_STATUS_CHANGED";
export const DISTRIBUTOR_STATUS_CHANGED_COMPLETED = "DISTRIBUTOR_STATUS_CHANGED_COMPLETED";
export const DISTRIBUTOR_STATUS_CHANGED_COMPLETED_WITH_ERROR = "DISTRIBUTOR_STATUS_CHANGED_COMPLETED_WITH_ERROR";



const initial_state={};

function setDistributorsData(state, data = {}) {
    const  {responseData={}, total=0, dataOrder=[]} = data;
    return {responseData, total, dataOrder};
}

function setDistributorProfile(state, data={}){
    // const {distributor={}, distributorBranchData={}} = data;
    return {...data};
}


export const getDistributors = (data={}) => {
    //console.log("data ===== ", data);
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_DISTRIBUTORS });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Distributors.getDistributorsURL(),
		params:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_DISTRIBUTORS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_DISTRIBUTORS_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};


export const createDistributor = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_DISTRIBUTOR });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Distributors.getCreateDistributorURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_DISTRIBUTOR_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_DISTRIBUTOR_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const editDistributor = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_DISTRIBUTOR });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Distributors.getEditDistributorURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_DISTRIBUTOR_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_DISTRIBUTOR_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const getDistributorProfle = (disId, data={}) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Distributors.getDistributorProfileURL(disId),
		params:data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type:  ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type:  ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export const getDistributorForm = (disId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: Distributors.getDistributorFormURL(disId),
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type:  ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type:  ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export const createBranch = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_BRANCH });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Distributors.getCreateBranchURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_BRANCH_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_BRANCH_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};




export const editBranch = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_BRANCH });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Distributors.getEditBranchURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_BRANCH_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_BRANCH_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export const updateDistributorBranchStatus = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_UPDATING_BRANCH_STATUS });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Distributors.getUpdateBranchStatusURL(),
		data:data
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATING_BRANCH_STATUS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_UPDATING_BRANCH_STATUS_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const activateDistributor = (distributorId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: DISTRIBUTOR_STATUS_CHANGED });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Distributors.getDistributorActivateURL(distributorId),
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: DISTRIBUTOR_STATUS_CHANGED_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: DISTRIBUTOR_STATUS_CHANGED_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};




export const suspendDistributor = (distributorId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: DISTRIBUTOR_STATUS_CHANGED });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: Distributors.getDistributorSuspendURL(distributorId),
		
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: DISTRIBUTOR_STATUS_CHANGED_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: DISTRIBUTOR_STATUS_CHANGED_COMPLETED,
		    payload: {message:payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};




export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case ADMIN_CONSOLE_GETTING_DISTRIBUTORS:
	return {loading:true};
    case ADMIN_CONSOLE_GETTING_DISTRIBUTORS_COMPLETED:
	return setDistributorsData(state, payload);
    case ADMIN_CONSOLE_GETTING_DISTRIBUTORS_COMPLETED_WITH_ERROR:
	return {loading:false};
    case ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE:
	return {...state};
    case ADMIN_CONSOLE_GETTING_DISTRIBUTOR_PROFILE_COMPLETED:
	return setDistributorProfile(state, payload);
    default:
	return state;
	
    }
}
