export const getAuditReportURL = () => {
    return `/eda/audits`;    
};



export const getAuditReportDetailURL = (auditId) => {
    return `/eda/audits/${auditId}`;
};


export const getAuditStatusChangeURL = (auditId) => {
    return `/eda/audits/${auditId}`;
};
