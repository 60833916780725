import { doRequest } from "../../../Helper/network";
import { PublicAdmin } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN = "ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN";
export const ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_COMPLETED = "ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_COMPLETED";
export const ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_PROFILE = "ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_PROFILE";
export const ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_PROFILE_COMPLETED = "ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_PROFILE_COMPLETED";
export const ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_PROFILE_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_PROFILE_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_CREATING_HEALTH_ADMIN = "ADMIN_CONSOLE_CREATING_HEALTH_ADMIN";
export const ADMIN_CONSOLE_CREATING_HEALTH_ADMIN_COMPLETED = "ADMIN_CONSOLE_CREATING_HEALTH_ADMIN_COMPLETED";
export const ADMIN_CONSOLE_CREATING_HEALTH_ADMIN_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_CREATING_HEALTH_ADMIN_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_EDITING_PUBLIC_ADMIN = "ADMIN_CONSOLE_EDITING_PUBLIC_ADMIN";
export const ADMIN_CONSOLE_EDITING_PUBLIC_ADMIN_COMPLETED = "ADMIN_CONSOLE_EDITING_PUBLIC_ADMIN_COMPLETED";
export const ADMIN_CONSOLE_EDITING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_EDITING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_ACTIVATE_PUBLIC_ADMIN = "ADMIN_CONSOLE_ACTIVATE_PUBLIC_ADMIN";
export const ADMIN_CONSOLE_ACTIVATE_PUBLIC_ADMIN_COMPLETED = "ADMIN_CONSOLE_ACTIVATE_PUBLIC_ADMIN_COMPLETED";
export const ADMIN_CONSOLE_ACTIVATE_PUBLIC_ADMIN_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_ACTIVATE_PUBLIC_ADMIN_COMPLETED_WITH_ERROR";


export const ADMIN_CONSOLE_DEACTIVATE_PUBLIC_ADMIN = "ADMIN_CONSOLE_DEACTIVATE_PUBLIC_ADMIN";
export const ADMIN_CONSOLE_DEACTIVATE_PUBLIC_ADMIN_COMPLETED = "ADMIN_CONSOLE_DEACTIVATE_PUBLIC_ADMIN_COMPLETED";
export const ADMIN_CONSOLE_DEACTIVATE_PUBLIC_ADMIN_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_DEACTIVATE_PUBLIC_ADMIN_COMPLETED_WITH_ERROR";



export const ADMIN_CONSOLE_CREATING_PUBLIC_ADMIN = "ADMIN_CONSOLE_CREATING_PUBLIC_ADMIN";
export const ADMIN_CONSOLE_CREATING_PUBLIC_ADMIN_COMPLETED = "ADMIN_CONSOLE_CREATING_PUBLIC_ADMIN_COMPLETED";
export const ADMIN_CONSOLE_CREATING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_CREATING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR";


const initial_state={};


function setPublicAdminData(state, data = {}) {
    const {dataOrder=[], responseData={}, total=0 } = data;
    return {dataOrder, responseData, total, loading:false};
}


function setPublicAdminProfile(state, data={}){
    return {publicAdminProfile: {...data}, loading:false};
}


export const fetchPublicAdmin = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: PublicAdmin.getAllPublicAdminURL(),
		params: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const fetchPublicAdminProfile = (publicAdminId) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_PROFILE });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: PublicAdmin.getPublicAdminProfileURL(publicAdminId),
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_PROFILE_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_PROFILE_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};


export const createPublicAdmin = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_PUBLIC_ADMIN });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: PublicAdmin.getCreatePublicAdminURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_PUBLIC_ADMIN_COMPLETED,
		    payload: {message:  payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export const createHealthAdmin = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_CREATING_HEALTH_ADMIN });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: PublicAdmin.getCreateHealthAdminURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_HEALTH_ADMIN_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_CREATING_HEALTH_ADMIN_COMPLETED,
		    payload: {message:  payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export const editPublicAdmin = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_EDITING_PUBLIC_ADMIN });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: PublicAdmin.getEditPublicAdminURL(),
		data: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_EDITING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_EDITING_PUBLIC_ADMIN_COMPLETED,
		    payload: {message:  payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};



export const activatePublicAdmin = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_ACTIVATE_PUBLIC_ADMIN });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: PublicAdmin.getActivatePublicAdminURL(),
		data,
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_ACTIVATE_PUBLIC_ADMIN_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_ACTIVATE_PUBLIC_ADMIN_COMPLETED,
		    payload: {message:  payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export const deactivatePublicAdmin = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_DEACTIVATE_PUBLIC_ADMIN });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		url: PublicAdmin.getDeactivatePublicAdminURL(),
		data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_DEACTIVATE_PUBLIC_ADMIN_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_DEACTIVATE_PUBLIC_ADMIN_COMPLETED,
		    payload: {message:  payload.message}
		});

	    }

	    return response;
	    
	} catch (err) {
	    throw err;
	}
    };
};


export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN:
	return {loading:true};
    case ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_COMPLETED:
	return setPublicAdminData(state, payload);
    case ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_COMPLETED_WITH_ERROR:
	return {loading:false};
    case ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_PROFILE:
	return {loading: true};
    case ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_PROFILE_COMPLETED:
	return setPublicAdminProfile(state, payload);
    case ADMIN_CONSOLE_GETTING_PUBLIC_ADMIN_PROFILE_COMPLETED_WITH_ERROR:
	return {loading: false};
    default:
	return state;
	
    }
}
