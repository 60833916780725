export const getBasicInfoURL = () => {
  return "/pharmacies/basic-info";
};


export const getPharmacyDetailsURL = () => {
    return "/pharmacies/getPharmacy";
};


export const getGenReqNumberURL = () => {
    return "/pharmacies/requestNumber";
};


export const getGeneralPharmacyRequestNumberURL = () => {
    return "/pharmacies/binding/generalPharmacy/requestNumber";
};


export const getSurgeryRoomRequestNumberURL = () => {
    return "/pharmacies/binding/surgeryRooms/requestNumber";
};


export const getMedicalCenterRequestNumberURL = () => {
    return "/pharmacies/binding/medicalCenter/requestNumber";
};


export const getDispensaryRequestNumberURL = () => {
    return "/pharmacies/binding/despensaryAndClinicalComplex/requestNumber";
};


export const getEgDrugPharmacyRequestNumberURL = () => {
    return "/pharmacies/binding/egdrugPharmacy/requestNumber";
};

export const getDoctorClinicRequestNumberURL = () => {
    return "/pharmacies/binding/doctorClinic/requestNumber";
};


export const getPrivateHospitalRequestNumberURL = () => {
    return "/pharmacies/binding/privateHospital/requestNumber";
};


export const getGovernmentalPharmacyRequestNumberURL = () => {
    return "/pharmacies/binding/governmentalHospital/requestNumber";
};
