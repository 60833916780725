export const fetchDispensationReportURL = () => {
  return "/distributors/report/dispensation/";
};


export const fetchForecastConsumptionReportURL = () => {
    return "/distributors/report/forcast-consumption";
};


export const fetchQuantityContributionReportURL = () => {
    return "/distributors/report/quantity-contribution";
};


export const fetchLoginActivitiesReportURL = () => {
    return "/distributors/report/login-activities";
};


export const fetchDispensationViolationReportURL = () => {
    return "/distributors/report/dispensation-violation";
};


export const fetchOrderReportURL = () => {
    return `/distributors/orderData`;
};
