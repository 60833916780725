import { doRequest } from "../../../Helper/network";
import { InspectorLawsRegulations } from "../../../Helper/urls";
import { REQUEST_TYPE } from "../../../constant";


export const INSPECTOR_GETTING_LAWS_REGULATIONS = "INSPECTOR_GETTING_LAWS_REGULATIONS";
export const INSPECTOR_GETTING_LAWS_REGULATIONS_COMPLETED = "INSPECTOR_GETTING_LAWS_REGULATIONS_COMPLETED";
export const INSPECTOR_GETTING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR = "INSPECTOR_GETTING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR";


const initial_state={};


function setLawsRegulationData(state, data = {}) {
    const {dataOrder=[], responseData={}, total=0 } = data;
    return {dataOrder, responseData, total};
}


export const fetchLawsRegulations = (data) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: INSPECTOR_GETTING_LAWS_REGULATIONS });
	    let response = await doRequest({
		method: REQUEST_TYPE.GET,
		url: InspectorLawsRegulations.getAllLawsRegulationsURL(),
		params: data
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: INSPECTOR_GETTING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: INSPECTOR_GETTING_LAWS_REGULATIONS_COMPLETED,
		    payload: payload.data
		});

	    }
	    
	} catch (err) {
	    throw err;
	}
    };
    
};



export default (state = initial_state, action = {}) => {
    const { type, payload } = action;
    switch(type){
    case INSPECTOR_GETTING_LAWS_REGULATIONS:
	return {loading:true};
    case INSPECTOR_GETTING_LAWS_REGULATIONS_COMPLETED:
	return setLawsRegulationData(state, payload);
    case INSPECTOR_GETTING_LAWS_REGULATIONS_COMPLETED_WITH_ERROR:
	return {loading:false};
	
    default:
	return state;
	
    }
}
