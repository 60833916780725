import {
    REASSIGNING_PHARMACY_COMPLETED
} from "../pharmacies";

import {
    EDITING_MEMBER_COMPLETED,
    ADDING_MEMBER_COMPLETED,
    RESIGNING_MEMBER_COMPLETED,
    ACTIVATING_MEMBER_COMPLETED
} from "../members";


import {
    ADDING_BRANCH_COMPLETED,
    ACTIVATING_BRANCH_COMPLETED,
    EDITING_BRANCH_COMPLETED
} from "../branches";


import {
    RESENDING_OTP_COMPLETED,
    RESENDING_RESET_OTP_COMPLETED,
    VERIFYING_RESET_OTP_COMPLETED
} from "../auth";

import {
    INSPECTOR_RESENDING_OTP_COMPLETED,
    INSPECTOR_RESENDING_RESET_OTP_COMPLETED,
    INSPECTOR_VERIFYING_RESET_OTP_COMPLETED
} from "../Inspector/auth";

import {
    ADMIN_CONSOLE_RESENDING_OTP_COMPLETED,
    ADMIN_CONSOLE_RESENDING_RESET_OTP_COMPLETED,
    ADMIN_CONSOLE_VERIFYING_RESET_OTP_COMPLETED
} from "../AdminConsole/auth";

import {INSPECTOR_ADDING_MEMBER_COMPLETED, INSPECTOR_EDITING_MEMBER_COMPLETED} from "../Inspector/members";


import {INSPECTOR_EDIT_QUOTA_COMPLETED, INSPECTOR_NOTIFYING_OWNER_COMPLETED} from "../Inspector/bindingPharmacies";


import {
    SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED,
    SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED,
    SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
    SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED,
    SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED,
    SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED,
    SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED,
    SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED,
    SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED,
    SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED
} from "../BindingPharmacy/pharmacyDetails";




import {
    INSPECTOR_UPDATE_MANAGER_PHARMACIES_STATUS_COMPLETED
} from "../Inspector/managerRequest";

import {
    INSPECTOR_ASSIGNING_BINDING_PHARMACIES_COMPLETED,
    INSPECTOR_UPDATING_STATUS_BINDING_PHARMACIES_COMPLETED,
    INSPECTOR_ADDING_NARCOTIC_DATA_COMPLETED,
    INSPECTOR_UPDATING_NARCOTIC_COMPLETED
} from "../Inspector/bindingPharmacies";

import {
    INSPECTOR_ASSIGNING_DISPENSATION_PHARMACIES_COMPLETED
} from "../Inspector/dispensationForms";

import {
    INSPECTOR_REASSIGNING_PHARMACY_COMPLETED,
    INSPECTOR_ENABLE_UPDATE_PHARMACY_INVENTORY_COMPLETED,
} from "../Inspector/pharmacies";


import {
    INSPECTOR_UPDATING_STATUS_DISPENSATION_PHARMACIES_COMPLETED
} from "../Inspector/dispensationForms";


import {
    UPDATE_REVIEWER_REASON_COMPLETED,
    INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS_COMPLETED
} from "../Inspector/pharmacyTypes";


import {
    CHANGE_PHARMACY_TYPE_COMPLETED,
    APPROVE_CHANGE_PHARMACY_TYPE_COMPLETED
} from "../Inspector/expiredNightPharmacy";


import {
    ASSIGNING_INSPECTOR_OWNER_PHARMACY_COMPLETED
} from "../Inspector/govEntities";

import {
    ADMIN_CONSOLE_ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED
} from "../AdminConsole/egDrugPharmacies";

import {
    ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED
} from "../egDrugPharmacies";


import {
    ADMIN_CONSOLE_SUSPEND_USER_COMPLETED,
    ADMIN_CONSOLE_ACTIVATE_USER_COMPLETED,
    ADMIN_CONSOLE_CREATING_USER_COMPLETED,
    ADMIN_CONSOLE_REASSIGN_DEPARTMENT_COMPLETED
} from "../AdminConsole/users";


import {
    EDA_CHANGE_AUDIT_STATUS_COMPLETED
} from "../AdminConsole/auditReport";


import {
    ADMIN_CONSOLE_CREATING_LAWS_REGULATIONS_COMPLETED
} from "../AdminConsole/lawsRegulations";


import {
    ADMIN_CONSOLE_CREATING_HOME_OFFICE_MESSAGE_COMPLETED
} from "../AdminConsole/homeOfficeMessage";


import {
    ADMIN_CONSOLE_REASSIGN_PHARMACY_COMPLETED,
    ADMIN_CONSOLE_UPDATE_PHARMACY_STATUS_COMPLETED,
    ADMIN_CONSOLE_UPDATE_PHARMACY_DETAILS_COMPLETED
} from "../AdminConsole/pharmacies";


import {
    ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET_COMPLETED,
    ADMIN_CONSOLE_SUSPEND_BATCH_COMPLETED,
    ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED,
    ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS_COMPLETED
} from "../AdminConsole/products";


import {
    ADMIN_CONSOLE_CREATING_HEALTH_ADMIN_COMPLETED,
    ADMIN_CONSOLE_EDITING_PUBLIC_ADMIN_COMPLETED,
    ADMIN_CONSOLE_ACTIVATE_PUBLIC_ADMIN_COMPLETED,
    ADMIN_CONSOLE_DEACTIVATE_PUBLIC_ADMIN_COMPLETED,
    ADMIN_CONSOLE_CREATING_PUBLIC_ADMIN_COMPLETED
} from "../AdminConsole/publicAdmin";


import {
    ADMIN_CONSOLE_UPDATING_BRANCH_STATUS_COMPLETED,
    ADMIN_CONSOLE_CREATING_DISTRIBUTOR_COMPLETED,
    ADMIN_CONSOLE_CREATING_BRANCH_COMPLETED,
    DISTRIBUTOR_STATUS_CHANGED_COMPLETED
} from "../AdminConsole/distributors";



import {
    ADMIN_CONSOLE_CREATING_GEOGRAPHICAL_STRUCTURE_COMPLETED,
    ADMIN_CONSOLE_EDITING_GEOGRAPHICAL_STRUCTURE_COMPLETED,
    CHANGING_GEOGRAPHY_STATUS_COMPLETED
} from "../AdminConsole/geographical";


import {
    ADMIN_CONSOLE_UPDATE_QUOTA_COMPLETED
} from "../AdminConsole/pharmacies";


import {
    ADMIN_CONSOLE_UPDATING_EDA_DEPT_COMPLETED,
    ADMIN_CONSOLE_ADDING_EDA_DEPT_COMPLETED,
    ADMIN_CONSOLE_UPDATING_DEPT_STATUS_COMPLETED
} from "../AdminConsole/edaDept";


import {
    UPDATING_AUDIT_FORM_COMPLETED,
    UPDATING_AUDIT_STATUS_FORM_COMPLETED
} from "../AdminConsole/editAudit";

export const CLEAR_MSG = "CLEAR_MSG";
export const SHOW_MSG = "SHOW_MSG";

const initialState = {};

export const clearMsg = () => {
  return dispatch => {
    dispatch({ type: CLEAR_MSG });
  };
};

export const showMsg = msg => {
  return dispatch => {
    dispatch({ type: SHOW_MSG, payload: { message: msg } });
  };
};

export default (state = initialState, action) => {
    const { type, payload } = action;
  switch (type) {
  case REASSIGNING_PHARMACY_COMPLETED:
  case EDITING_MEMBER_COMPLETED:
  case ADDING_MEMBER_COMPLETED:
  case SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED:
  case SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED:
  case SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED:
  case SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED:
  case SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED:
  case SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED:
  case SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED:
  case SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED:
  case SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED:
  case SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED:
  case INSPECTOR_ASSIGNING_BINDING_PHARMACIES_COMPLETED:
  case INSPECTOR_ADDING_MEMBER_COMPLETED:
  case INSPECTOR_UPDATING_STATUS_BINDING_PHARMACIES_COMPLETED:
  case INSPECTOR_REASSIGNING_PHARMACY_COMPLETED:
  case INSPECTOR_UPDATE_MANAGER_PHARMACIES_STATUS_COMPLETED:
  case ACTIVATING_MEMBER_COMPLETED:
  case RESIGNING_MEMBER_COMPLETED:
  case INSPECTOR_ASSIGNING_DISPENSATION_PHARMACIES_COMPLETED:
  case ADMIN_CONSOLE_ACTIVATE_USER_COMPLETED:
  case ADMIN_CONSOLE_SUSPEND_USER_COMPLETED:
  case ADMIN_CONSOLE_UPDATING_REQUIRE_TICKET_COMPLETED:
  case ADMIN_CONSOLE_UPDATING_BRANCH_STATUS_COMPLETED:
  case ADMIN_CONSOLE_REASSIGN_PHARMACY_COMPLETED:
  case ADMIN_CONSOLE_UPDATE_PHARMACY_STATUS_COMPLETED:
  case ADMIN_CONSOLE_CREATING_LAWS_REGULATIONS_COMPLETED:
  case ADMIN_CONSOLE_CREATING_HEALTH_ADMIN_COMPLETED:
  case ADMIN_CONSOLE_EDITING_PUBLIC_ADMIN_COMPLETED:
  case ADMIN_CONSOLE_ACTIVATE_PUBLIC_ADMIN_COMPLETED:
  case ADMIN_CONSOLE_DEACTIVATE_PUBLIC_ADMIN_COMPLETED:
  case ADMIN_CONSOLE_CREATING_GEOGRAPHICAL_STRUCTURE_COMPLETED:
  case ADMIN_CONSOLE_CREATING_PUBLIC_ADMIN_COMPLETED:
  case ADMIN_CONSOLE_EDITING_GEOGRAPHICAL_STRUCTURE_COMPLETED:
  case UPDATING_AUDIT_FORM_COMPLETED:
  case INSPECTOR_UPDATING_PHARMACY_TYPE_STATUS_COMPLETED:
  case ADMIN_CONSOLE_UPDATING_EDA_DEPT_COMPLETED:
  case ADMIN_CONSOLE_ADDING_EDA_DEPT_COMPLETED:
  case ADMIN_CONSOLE_UPDATING_DEPT_STATUS_COMPLETED:
  case UPDATING_AUDIT_STATUS_FORM_COMPLETED:
  case INSPECTOR_UPDATING_STATUS_DISPENSATION_PHARMACIES_COMPLETED:
  case INSPECTOR_EDIT_QUOTA_COMPLETED:
  case ADMIN_CONSOLE_UPDATE_QUOTA_COMPLETED:
  case CHANGE_PHARMACY_TYPE_COMPLETED:
  case APPROVE_CHANGE_PHARMACY_TYPE_COMPLETED:
  case UPDATE_REVIEWER_REASON_COMPLETED:
  case ADMIN_CONSOLE_CREATING_DISTRIBUTOR_COMPLETED:
  case ADMIN_CONSOLE_CREATING_BRANCH_COMPLETED:
  case ADMIN_CONSOLE_CREATING_USER_COMPLETED:
  case CHANGING_GEOGRAPHY_STATUS_COMPLETED:
  case DISTRIBUTOR_STATUS_CHANGED_COMPLETED:
  case ASSIGNING_INSPECTOR_OWNER_PHARMACY_COMPLETED:
  case ADMIN_CONSOLE_ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED:
  case ASSIGNING_OWNER_EGDRUG_PHARMACY_COMPLETED:
  case INSPECTOR_NOTIFYING_OWNER_COMPLETED:
  case ADMIN_CONSOLE_SUSPEND_BATCH_COMPLETED:
  case RESENDING_OTP_COMPLETED:
  case ADMIN_CONSOLE_RESENDING_OTP_COMPLETED:
  case INSPECTOR_RESENDING_OTP_COMPLETED:
  case ADMIN_CONSOLE_RESENDING_RESET_OTP_COMPLETED:
  case  ADMIN_CONSOLE_VERIFYING_RESET_OTP_COMPLETED:
  case INSPECTOR_RESENDING_RESET_OTP_COMPLETED:
  case INSPECTOR_VERIFYING_RESET_OTP_COMPLETED:
  case RESENDING_RESET_OTP_COMPLETED:
  case  VERIFYING_RESET_OTP_COMPLETED:
  case INSPECTOR_EDITING_MEMBER_COMPLETED:
  case ADMIN_CONSOLE_CREATING_HOME_OFFICE_MESSAGE_COMPLETED:
  case ADMIN_CONSOLE_REASSIGN_DEPARTMENT_COMPLETED:
  case INSPECTOR_ADDING_NARCOTIC_DATA_COMPLETED:
  case INSPECTOR_UPDATING_NARCOTIC_COMPLETED:
  case EDA_CHANGE_AUDIT_STATUS_COMPLETED:
  case ADMIN_CONSOLE_CHANGING_PRODUCT_STATUS_COMPLETED:
  case ADMIN_CONSOLE_ADDING_PRODUCT_COMPLETED:
  case ADDING_BRANCH_COMPLETED:
  case ACTIVATING_BRANCH_COMPLETED:
  case EDITING_BRANCH_COMPLETED:
  case ADMIN_CONSOLE_UPDATE_PHARMACY_DETAILS_COMPLETED:
  case INSPECTOR_ENABLE_UPDATE_PHARMACY_INVENTORY_COMPLETED:
  case SHOW_MSG:
      return {
        msg: payload.message
      };
    case CLEAR_MSG:
      return {};
    default:
      return state;
  }
};
