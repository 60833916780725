import {
    SIGNING_COMPLETED, SIGNING_COMPLETED_WITH_ERROR, SIGNING, GETTING_INITIAL_DATA_COMPLETED,
    CREATING_NEW_PASSWORD_COMPLETED, VERIFYING_OTP_COMPLETED, FORGET_PASSWORD_COMPLETED,
    GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR, RESET_ERROR, CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR, VERIFYING_OTP_COMPLETED_WITH_ERROR, FORGET_PASSWORD_COMPLETED_WITH_ERROR, EMPTY_AUTH_REDIRECTION, RESENDING_OTP_COMPLETED, RESENDING_OTP_COMPLETED_WITH_ERROR
} from "./modules/auth";


import {
    INSPECTOR_SIGNING_COMPLETED, INSPECTOR_SIGNING, INSPECTOR_SIGNING_COMPLETED_WITH_ERROR, INSPECTOR_GETTING_INITIAL_DATA_COMPLETED, INSPECTOR_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR, INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED, INSPECTOR_VERIFYING_OTP_COMPLETED, INSPECTOR_FORGET_PASSWORD_COMPLETED, INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR, INSPECTOR_FORGET_PASSWORD_COMPLETED_WITH_ERROR, INSPECTOR_VERIFYING_OTP_COMPLETED_WITH_ERROR, INSPECTOR_EMPTY_AUTH_REDIRECTION, INSPECTOR_RESET_ERROR, INSPECTOR_RESENDING_OTP_COMPLETED, INSPECTOR_RESENDING_OTP_COMPLETED_WITH_ERROR
} from "./modules/Inspector/auth";

import {
    ADMIN_CONSOLE_SIGNING_COMPLETED, ADMIN_CONSOLE_SIGNING, ADMIN_CONSOLE_SIGNING_COMPLETED_WITH_ERROR, ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED, ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR, ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED, ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED, ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED, ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR, ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED_WITH_ERROR, ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED_WITH_ERROR, ADMIN_CONSOLE_EMPTY_AUTH_REDIRECTION, ADMIN_CONSOLE_RESET_ERROR, ADMIN_CONSOLE_RESENDING_OTP_COMPLETED, ADMIN_CONSOLE_RESENDING_OTP_COMPLETED_WITH_ERROR
} from "./modules/AdminConsole/auth";

import {
    OPEN_NO_AUTH_MODAL,
    CLOSE_MODAL
} from "./modules/modals";

import {BINDING_BASIC_INFO_COMPLETED, GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR, GETTING_PHARMACY_DETAILS_COMPLETED_WITH_ERROR,  GENERATING_REQUEST_NUMBER_COMPLETED} from "./modules/BindingPharmacy/basicInfo";


import {GETTING_BINDING_STATUS_COMPLETED, GETTING_BINDING_STATUS_COMPLETED_WITH_ERROR} from "./modules/BindingPharmacy/trackRequest";

import {SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED,
	SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
	SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED,
	SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
	SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
	SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED,
	SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED,
	SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED_WITH_ERROR,

	SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED,
	SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
	SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED,
	SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
	SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
	SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED,
	
	SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED,
	SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR,
	SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED,
	SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR,
	SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED,
	SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR,
	GET_BINDING_PHARMACY_INFO_COMPLETED,
	SHOW_NEXT_BUTTON_ERROR,
	GET_BINDING_PHARMACY_INFO_COMPLETED_WITH_ERROR
       } from "./modules/BindingPharmacy/pharmacyDetails";


import {CLEAR_ERROR_MSG} from "./modules/errorMsg";

import {ASSIGN_LANGUAGE} from "./modules/lang";

import {ASSIGN_REQUEST_NUMBER} from "./modules/BindingPharmacy/requestNumber";

const retainState = (store) => (next) => (action) => {
  const storeInstance = store.getState();
    const { auth = {}, InspectorAuth = {}, AdminAuth={} } = storeInstance;

    const  inspection_department = window.location.href.includes("inspection-department") ? true : false;
    const admin_console = window.location.href.includes("admin-console") ? true : false;
    let authenticated;
    if(inspection_department){
	authenticated = InspectorAuth.authenticated;
    }
    else if(admin_console){
	authenticated = AdminAuth.authenticated;
    }
    else{
	authenticated = auth.authenticated;;
    }
    
  if (authenticated) {
    next(action);
  } else {
      switch (action.type) {
      case RESET_ERROR:
      case INSPECTOR_RESET_ERROR:
      case ADMIN_CONSOLE_RESET_ERROR:
    case SIGNING:
    case SIGNING_COMPLETED:
      case SIGNING_COMPLETED_WITH_ERROR:
      case INSPECTOR_SIGNING:
      case INSPECTOR_SIGNING_COMPLETED_WITH_ERROR:
      case INSPECTOR_SIGNING_COMPLETED:
      case ADMIN_CONSOLE_SIGNING:
      case ADMIN_CONSOLE_SIGNING_COMPLETED_WITH_ERROR:
      case ADMIN_CONSOLE_SIGNING_COMPLETED:
      case CREATING_NEW_PASSWORD_COMPLETED:
      case CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR:
      case ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED:
      case ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR:
      case INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED:
      case INSPECTOR_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR:
      case VERIFYING_OTP_COMPLETED:
      case VERIFYING_OTP_COMPLETED_WITH_ERROR:
      case ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED:
      case ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED_WITH_ERROR:
      case INSPECTOR_VERIFYING_OTP_COMPLETED:
      case INSPECTOR_VERIFYING_OTP_COMPLETED_WITH_ERROR:
      case FORGET_PASSWORD_COMPLETED:
      case FORGET_PASSWORD_COMPLETED_WITH_ERROR:
      case ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED:
      case ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED_WITH_ERROR:
      case INSPECTOR_FORGET_PASSWORD_COMPLETED:
      case INSPECTOR_FORGET_PASSWORD_COMPLETED_WITH_ERROR:
    case GETTING_INITIAL_DATA_COMPLETED:
      case GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR:
      case ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED:
      case ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR:
      case INSPECTOR_GETTING_INITIAL_DATA_COMPLETED:
      case INSPECTOR_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR:
      case CLEAR_ERROR_MSG:
      case ASSIGN_LANGUAGE:
      case ASSIGN_REQUEST_NUMBER:
      case EMPTY_AUTH_REDIRECTION:
      case ADMIN_CONSOLE_EMPTY_AUTH_REDIRECTION:
      case INSPECTOR_EMPTY_AUTH_REDIRECTION:
      case OPEN_NO_AUTH_MODAL:
      case CLOSE_MODAL:
      case BINDING_BASIC_INFO_COMPLETED:
      case GENERATING_REQUEST_NUMBER_COMPLETED_WITH_ERROR:
      case GENERATING_REQUEST_NUMBER_COMPLETED:
      case GETTING_PHARMACY_DETAILS_COMPLETED_WITH_ERROR:
      case SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED:
      case SAVING_GENERAL_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR:
      case SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED:
      case SAVING_GENERAL_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR:
      case SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED:
      case SAVING_GENERAL_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR:
      case SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED:
      case SAVING_GENERAL_PHARMACY_DISPENSATION_INFO_COMPLETED_WITH_ERROR:
      case SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED:
      case SAVING_NIGHT_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR:
      case SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED:
      case SAVING_NIGHT_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR:
      case SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR:
      case SAVING_NIGHT_PHARMACY_OWNER_INFO_COMPLETED:
      case SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED:
      case SAVING_SURGERY_ROOM_PHARMACY_GENERAL_INFO_COMPLETED_WITH_ERROR:
      case SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED:
      case SAVING_SURGERY_ROOM_PHARMACY_OWNER_INFO_COMPLETED_WITH_ERROR:
      case SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED:
      case SAVING_SURGERY_ROOM_PHARMACY_MANAGER_INFO_COMPLETED_WITH_ERROR:
      case GET_BINDING_PHARMACY_INFO_COMPLETED:
      case GETTING_BINDING_STATUS_COMPLETED:
      case GETTING_BINDING_STATUS_COMPLETED_WITH_ERROR:
      case SHOW_NEXT_BUTTON_ERROR:
      case RESENDING_OTP_COMPLETED_WITH_ERROR:
      case INSPECTOR_RESENDING_OTP_COMPLETED_WITH_ERROR:
      case ADMIN_CONSOLE_RESENDING_OTP_COMPLETED_WITH_ERROR:
      case ADMIN_CONSOLE_RESENDING_OTP_COMPLETED:
      case INSPECTOR_RESENDING_OTP_COMPLETED:
      case RESENDING_OTP_COMPLETED:
      case GET_BINDING_PHARMACY_INFO_COMPLETED_WITH_ERROR:
	  return next(action);
    default:
	return;
    }
  }
};
export default retainState;
