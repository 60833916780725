import { doRequest } from "../../../Helper/network";
import { AdminConsoleAuth as Auth } from "../../../Helper/urls";
import { REQUEST_TYPE, path, ADMIN_CONSOLE_PATH } from "../../../constant";

export const ADMIN_CONSOLE_SIGNING = "ADMIN_CONSOLE_SIGNING";
export const ADMIN_CONSOLE_SIGNING_COMPLETED = "ADMIN_CONSOLE_SIGNING_COMPLETED";
export const ADMIN_CONSOLE_SIGNING_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_SIGNING_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_CREATING_NEW_PASSWORD = "ADMIN_CONSOLE_CREATING_NEW_PASSWORD";
export const ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED =
  "ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED";
export const ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR =
  "ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_VERIFYING_OTP = "ADMIN_CONSOLE_VERIFYING_OTP";
export const ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED = "ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED";
export const ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED_WITH_ERROR =
  "ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_RESENDING_OTP = "ADMIN_CONSOLE_RESENDING_OTP";
export const ADMIN_CONSOLE_RESENDING_OTP_COMPLETED = "ADMIN_CONSOLE_RESENDING_OTP_COMPLETED";
export const ADMIN_CONSOLE_RESENDING_OTP_COMPLETED_WITH_ERROR =
  "ADMIN_CONSOLE_RESENDING_OTP_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_FORGET_PASSWORD = "ADMIN_CONSOLE_FORGET_PASSWORD";
export const ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED = "ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED";
export const ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED_WITH_ERROR =
  "ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_GETTING_INITIAL_DATA = "ADMIN_CONSOLE_GETTING_INITIAL_DATA";
export const ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED = "ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED";
export const ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR =
    "ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR";



const ADMIN_CONSOLE_RESETTING_NEW_PASSWORD = "ADMIN_CONSOLE_RESETTING_NEW_PASSWORD";
export const ADMIN_CONSOLE_RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR";
export const ADMIN_CONSOLE_RESETTING_NEW_PASSWORD_COMPLETED = "ADMIN_CONSOLE_RESETTING_NEW_PASSWORD_COMPLETED";

const ADMIN_CONSOLE_VERIFYING_RESET_OTP = "ADMIN_CONSOLE_VERIFYING_RESET_OTP";
export const ADMIN_CONSOLE_VERIFYING_RESET_OTP_COMPLETED = "ADMIN_CONSOLE_VERIFYING_RESET_OTP_COMPLETED";
export const ADMIN_CONSOLE_VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR";

const ADMIN_CONSOLE_RESENDING_RESET_OTP = "ADMIN_CONSOLE_RESENDING_RESET_OTP";
export const ADMIN_CONSOLE_RESENDING_RESET_OTP_COMPLETED = "ADMIN_CONSOLE_RESENDING_RESET_OTP_COMPLETED";
export const ADMIN_CONSOLE_RESENDING_RESET_OTP_COMPLETED_WITH_ERROR = "ADMIN_CONSOLE_RESENDING_RESET_OTP_COMPLETED_WITH_ERROR";

export const ADMIN_CONSOLE_SIGNING_OUT = "ADMIN_CONSOLE_SIGNING_OUT";
export const ADMIN_CONSOLE_SIGNING_OUT_COMPLETED = "ADMIN_CONSOLE_SIGNING_OUT_COMPLETED";


export const ADMIN_CONSOLE_RESET_UNAUTHORIZED_ERROR = "ADMIN_CONSOLE_RESET_UNAUTHORIZED_ERROR";

export const ADMIN_CONSOLE_RESET_ERROR = "ADMIN_CONSOLE_RESET_ERROR";


export const ADMIN_CONSOLE_EMPTY_AUTH_REDIRECTION="ADMIN_CONSOLE_EMPTY_AUTH_REDIRECTION";

const intial_state = {};


export const resetUnauthorizedError = state => {
    return { type: ADMIN_CONSOLE_RESET_UNAUTHORIZED_ERROR };
};


export const clearError = () => {
    return { type: ADMIN_CONSOLE_RESET_ERROR };
};


export const emptyAuthRedirection = () => {
    return dispatch => {
	dispatch({type:ADMIN_CONSOLE_EMPTY_AUTH_REDIRECTION});
    };
};

export const signIn = (data = {}, lastUrl) => {
  return async (dispatch, getState) => {
    try {
	const { AdminAuth:auth = {} } = getState();
      dispatch({ type: ADMIN_CONSOLE_SIGNING });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.getSignInURL()
      });
	const { status, payload } = response;
	
      if (status === false) {
        dispatch({
          type: ADMIN_CONSOLE_SIGNING_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
	  const {data:{authUser, users={}} = {}}  = payload;
	  const is_verified = users[authUser].is_verified;
          let redirectTo = "";
          if (is_verified) {
	      if (lastUrl && auth.unauthorizedError !== undefined) {
		  redirectTo = ADMIN_CONSOLE_PATH + lastUrl;
              }
	      else{
		  redirectTo = path.ADMIN_CONSOLE.PHARMACY_LIST;
	      }
        } else {
          redirectTo = path.ADMIN_CONSOLE.CREATE_PASSWORD;
        }
        dispatch({
            type: ADMIN_CONSOLE_SIGNING_COMPLETED,
            payload: { ...payload.data, redirectTo, is_verified, authRedirection: redirectTo, id:users[authUser].id}
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const createPassword = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ADMIN_CONSOLE_CREATING_NEW_PASSWORD });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.createNewPasswordURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        let redirectTo = path.ADMIN_CONSOLE.OTP;
        dispatch({
          type: ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED,
          payload: { ...payload.data, authRedirection: redirectTo }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const verifyOTP = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ADMIN_CONSOLE_VERIFYING_OTP });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.verifyOtpURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        let redirectTo = path.ADMIN_CONSOLE.SIGN_IN;

        dispatch({
          type: ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED,
          payload: { ...payload.data, authRedirection: redirectTo }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const resendOTP = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ADMIN_CONSOLE_RESENDING_OTP });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.resendOtpURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: ADMIN_CONSOLE_RESENDING_OTP_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: ADMIN_CONSOLE_RESENDING_OTP_COMPLETED,
          payload: {message:payload.message}
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const forgotPassword = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ADMIN_CONSOLE_FORGET_PASSWORD });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.getForgotPasswordURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        let redirectTo = path.ADMIN_CONSOLE.CREATE_PASSWORD;
        dispatch({
          type: ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED,
          payload: { ...payload.data, authRedirection: redirectTo }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const verifyForgotPasswordOTP = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ADMIN_CONSOLE_VERIFYING_OTP });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.verifyOtpForgotPasswordURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        let redirectTo = path.ADMIN_CONSOLE.SIGN_IN;

        dispatch({
          type: ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED,
          payload: { ...payload.data, authRedirection: redirectTo }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const resendForgotPasswordOTP = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ADMIN_CONSOLE_RESENDING_OTP });
      let response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: data,
        url: Auth.resendOtpForgotPasswordURL()
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: ADMIN_CONSOLE_RESENDING_OTP_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: ADMIN_CONSOLE_RESENDING_OTP_COMPLETED,
          payload: {message:payload.message}
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const onAdminAppStart = () => {
  return async dispatch => {
      try {
	  dispatch({ type: ADMIN_CONSOLE_GETTING_INITIAL_DATA });
      const response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Auth.getBasicInfoURL()
      });
      
	const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR,
          payload: {
          }
        });
      }
	if (status === true) {
	    const {data:{authUser} = {}}  = payload;
	    const {data:{users={}} = {}} = payload;
	  const redirectTo = "";
        dispatch({
          type: ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED,
          payload: {
              authenticatedUser: authUser,
              authRedirection: redirectTo,
	      ...payload.data
          }
        });
      }
    } catch (err) {
      dispatch({
        type: ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR
      });
    }
  };
};


export const resetPassword = (data={}) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_RESETTING_NEW_PASSWORD });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		data: data,
		url: Auth.resetNewPasswordURL()
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_RESETTING_NEW_PASSWORD_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
    };
};


export const verifyResetOtp = (data={}) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_VERIFYING_RESET_OTP });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		data: data,
		url: Auth.verifyResetOtpURL()
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_VERIFYING_RESET_OTP_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_VERIFYING_RESET_OTP_COMPLETED,
		    payload: { message:payload.message}
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
    };
};


export const resendResetOtp = (data={}) => {
    return async (dispatch, getState) => {
	try {
	    dispatch({ type: ADMIN_CONSOLE_RESENDING_RESET_OTP });
	    let response = await doRequest({
		method: REQUEST_TYPE.POST,
		data: data,
		url: Auth.resendResetOtpURL()
	    });
	    const { status, payload } = response;
	    if (status === false) {
		dispatch({
		    type: ADMIN_CONSOLE_RESENDING_RESET_OTP_COMPLETED_WITH_ERROR,
		    payload: { error: payload.error }
		});
	    } else if (status === true) {
		dispatch({
		    type: ADMIN_CONSOLE_RESENDING_RESET_OTP_COMPLETED,
		    payload: {message:payload.message}
		});
	    }
	    return response;
	} catch (err) {
	    throw err;
	}
    };
};


export const logOut = (data = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: ADMIN_CONSOLE_SIGNING_OUT });
      let response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Auth.getSignOutURL()
      });

      const { status } = response;
      if (status === true) {
        dispatch({ type: ADMIN_CONSOLE_SIGNING_OUT_COMPLETED });
      } 
      return status;
    } catch (err) {
      throw err;
    }
  };
};


function unsetUnathorizedError(state) {
  const { unauthorizedError } = state;
  if (unauthorizedError) {
    return {
      ...state,
      unauthorizedError: false
    };
  }
  return state;
}


export default (state = intial_state, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED: {
      return {
        authenticated: true,
        authenticated_user: payload.authenticatedUser,
        ...payload
      };
    }
    case ADMIN_CONSOLE_GETTING_INITIAL_DATA_COMPLETED_WITH_ERROR:
      return {
        authenticated: false,
        unauthorizedError: true
      };

    case ADMIN_CONSOLE_SIGNING_COMPLETED_WITH_ERROR:
      return {
        authenticated: false,
        error: payload.error
      };

  case ADMIN_CONSOLE_SIGNING_COMPLETED:
      return {
          ...state,
          authenticated: payload.is_verified,
          is_verified: payload.is_verified,
          user_id: payload.id,
	  ...payload
      };

    case ADMIN_CONSOLE_SIGNING_OUT_COMPLETED:
      return {
        authenticated: false,
        authRedirection: path.ADMIN_CONSOLE.SIGN_IN
      };
      
  case ADMIN_CONSOLE_RESET_ERROR:
      return {
          ...state,
          error: null
      };

  case ADMIN_CONSOLE_RESET_UNAUTHORIZED_ERROR:
      return unsetUnathorizedError(state);

    case ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED:
      return {
        ...state,
        authRedirection: payload.authRedirection
      };

 
      
    case ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED:
      return {
        ...state,
        authRedirection: payload.authRedirection
      };
    case ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED:
      return {
        ...state,
        user_id: payload.id,
        authRedirection: payload.authRedirection
      };

  case ADMIN_CONSOLE_FORGET_PASSWORD_COMPLETED_WITH_ERROR:
      return {
	  ...state,
          authenticated: false,
          error: payload.error
      };

  case ADMIN_CONSOLE_CREATING_NEW_PASSWORD_COMPLETED_WITH_ERROR:
      return {
	  ...state,
          authenticated: false,
          error: payload.error
      };

  case ADMIN_CONSOLE_VERIFYING_OTP_COMPLETED_WITH_ERROR:
      return {
	  ...state,
          authenticated: false,
          error: payload.error
      };

  case ADMIN_CONSOLE_EMPTY_AUTH_REDIRECTION:
      return {
	  ...state,
	  authRedirection:""
      };
      
    default:
      return state;
  }
};
