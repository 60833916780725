import { doRequest } from "../../Helper/network";
import { Reports } from "../../Helper/urls";
import { REQUEST_TYPE} from "../../constant";

const FETCHING_ORDER_REPORT = "FETCHING_ORDER_REPORT";
const FETCHING_ORDER_REPORT_COMPLETED = "FETCHING_ORDER_REPORT_COMPLETED";
const FETCHING_ORDER_REPORT_COMPLETED_WITH_ERROR = "FETCHING_ORDER_REPORT_COMPLETED_WITH_ERROR";

const initial_state = {};


function setOrderReport(state, payload){

    const {report={}, total=0, dataOrder=[]} = payload;
    if(report){
	return {report, totalRecords:total, loading:false, dataOrder};
    }
    else return state;
    
}



export const getOrderData = (data = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FETCHING_ORDER_REPORT });
      let response = await doRequest({
          method: REQUEST_TYPE.GET,
          url: Reports.fetchOrderReportURL(),
	  params:data
      });
      const { status, payload } = response;
      if (status === false) {
        dispatch({
          type: FETCHING_ORDER_REPORT_COMPLETED_WITH_ERROR,
          payload: { error: payload.error }
        });
      } else if (status === true) {
        dispatch({
          type: FETCHING_ORDER_REPORT_COMPLETED,
          payload: { ...payload.data }
        });
      }
      return response;
    } catch (err) {
      throw err;
    }
  };
};



export default (state = initial_state, action = {}) => {
  const { type, payload } = action;

    switch (type) {
    case FETCHING_ORDER_REPORT:
	return {loading:true};
    case FETCHING_ORDER_REPORT_COMPLETED: {
      return setOrderReport(state, payload);
    }
    case FETCHING_ORDER_REPORT_COMPLETED_WITH_ERROR:
	return {loading:false};
      
    default:
      return state;
  }
};
